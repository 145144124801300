import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  container: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    fontFamily: 'Inter',
    padding: '20px',
  },
  accordion: {
    borderRadius: '8px',
    border: '1px solid #E4E7EC',
    marginBottom: '8px',
    overflow: 'hidden',
    '&:before': {
      display: 'none',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  activityAccordionSummary: {
    padding: '0px 10px',
    margin: '0 0',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0px',
    },
  },
  accordionSummaryHeading: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  accordionDetails: {
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // marginTop: '8px',
    marginBottom: '20px',
  },
  activityText: {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
    marginBottom: '8px',
    whiteSpace: 'nowrap',
    color: '#828282',
  },
  activityValue: {
    color: '#1F1F1F',
    fontWeight: 500,
  },
  activityCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  activityTextContainer: {
    flex: '1 1 auto',
    marginRight: '20px',
    minWidth: '200px',
  },
  statusText: {
    color: '#ff9800',
  },
  submitProgressAccordionSummary: {
    backgroundColor: '#eaf1fb',
    padding: '0px 10px',
    margin: '0 0',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    '&.Mui-expanded': {
      minHeight: '0px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0px',
    },
  },
  submitButton: {
    marginTop: '8px',
    padding: '8px 24px',
    fontWeight: 600,
    backgroundColor: '#036FCB',
    color: '#F2F2F2',
    display: 'block',
    textAlign: 'center',
    marginRight: 'auto',
    borderRadius: '40px',
    lineHeight: '1',
    '&:hover': {
      backgroundColor: '#025a9e',
    },
    whiteSpace: 'nowrap',
  },
  nestedAccordion: {
    borderRadius: '8px',
    padding: '10px',
    '&:before': {
      display: 'none',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&.Mui-expanded': {
      margin: '0px 0px',
    },
  },
  nestedAccordionDetails: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
  },
  nestedActivityText: {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    marginBottom: '4px',
    whiteSpace: 'nowrap',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  statusBox: {
    border: '1px solid #c0c0c0',
    background: '#F5F5F5',
    borderRadius: '24px',
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    color: '#1F1F1F',
  },
  selfBox: {
    border: '1px solid #c0c0c0',
    background: '#F5F5F5',
    borderRadius: '24px',
    padding: '2px 8px',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    color: '#1F1F1F',
  },
  deleteButton: {
    color: '#036fcb',
  },
  completeButton: {
    marginLeft: 'auto',
    color: '#FFFFFF',
    background: '#036FCB',
    textTransform: 'none',
    borderRadius: '40px',
    boxShadow: '0px 0px 0px 0px',
    '&:hover': {
      color: '#036FCB',
      backgroundColor: '#FFFFFF',
    },
    fontSize: '14px',
  },
  rightAligned: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  chartContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pieChart: {
    width: '150px',
    height: '115px',
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px',
  },
  legendColor: {
    width: '12px',
    height: '12px',
    marginRight: '4px',
  },
  legendText: {
    fontSize: '12px',
    fontFamily: 'Inter',
  },
});

const CarePlanActivities = ({ activities, title, selfReportingActivities, setSelfReportingActivities }) => {
  const [expanded, setExpanded] = useState(null);
  const [activityDate, setActivityDate] = useState(null);
  const [comments, setComments] = useState('');
  const [file, setFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [nestedExpanded, setNestedExpanded] = useState(false);
  const classes = useStyles();

  const filteredSelfReportingActivities = selfReportingActivities.filter(activity => activity.carePlanTitle === title);

  const legendItems = [
    { name: 'Active', color: '#00a0df' },
    { name: 'Pending', color: '#fd7e14' },
    { name: 'Completed', color: '#a7bdd1' },
  ];

  const getPieChartOptions = (isSelfReported, status) => ({
    chart: {
      type: 'pie',
      width: 150,
      height: 115,
      spacing: [0, 0, 0, 0],
      backgroundColor: null,
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: '100%',
        borderWidth: 2,
        dataLabels: {
          enabled: false,
          format: '{point.percentage:.1f}%',
          distance: -5,
          connectorWidth: 0,
          crop: false,
          overflow: 'allow',
          style: {
            fontWeight: 600,
            fontSize: '12px',
            color: 'black',
            textOutline: '1px contrast',
            fontFamily: 'Inter',
          },
        },
      },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.name}: {point.y}%</b>',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Status",
        colorByPoint: true,
        data:
          status === "Completed"
            ? [
                { name: "Active", y: 0, color: "#00a0df" },
                { name: "Pending", y: 0, color: "#fd7e14" },
                { name: "Completed", y: 100, color: "#a7bdd1" },
              ]
            : isSelfReported
            ? [
                { name: "Active", y: 0, color: "#00a0df" },
                { name: "Pending", y: 100, color: "#fd7e14" },
                { name: "Completed", y: 0, color: "#a7bdd1" },
              ]
            : [
                { name: "Active", y: 60, color: "#00a0df" },
                { name: "Pending", y: 30, color: "#fd7e14" },
                { name: "Completed", y: 10, color: "#a7bdd1" },
              ],
      },
    ],
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
    if (!isExpanded) {
      setNestedExpanded(false);
      resetForm();
    }
  };

  const handleNestedAccordionChange = (event, isExpanded) => {
    setNestedExpanded(isExpanded);
    if (!isExpanded) {
      resetForm();
    }
  };

  const handleSubmit = () => {
    setSnackbarMessage('Submitted successfully');
    setSnackbarOpen(true);
    setNestedExpanded(false);
    resetForm();
  };

  const resetForm = () => {
    setActivityDate(null);
    setComments('');
    setFile(null);
    document.getElementById('file-input').value = null;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'rgb(3, 111, 203)';
      case 'Pending':
        return 'rgb(242, 153, 74)';
      case 'Completed':
        return 'rgb(0, 125, 50)';
      default:
        return '#000000'; // default color if status is unknown
    }
  };

  const handleDelete = (activityId) => {
    const activityToDelete = selfReportingActivities.find(activity => activity.id === activityId);
    const updatedActivities = selfReportingActivities.filter(activity => activity.id !== activityId);
    setSelfReportingActivities(updatedActivities);
    localStorage.setItem('selfReportingActivities', JSON.stringify(updatedActivities));
    setSnackbarMessage(`${activityToDelete.activityName} deleted successfully`);
    setSnackbarOpen(true);
  };

  const handleComplete = (activityId) => {
    const updatedActivities = selfReportingActivities.map(activity => {
      if (activity.id === activityId) {
        return { ...activity, status: 'Completed' };
      }
      return activity;
    });
    setSelfReportingActivities(updatedActivities);
    localStorage.setItem('selfReportingActivities', JSON.stringify(updatedActivities));
  };

  const sortedActivities = [...activities, ...filteredSelfReportingActivities].sort((a, b) => new Date(a.endDate) - new Date(b.endDate));

  return (
    <div className={classes.container}>
      {sortedActivities.map((activity, index) => {
        const isSelfReported = filteredSelfReportingActivities.includes(activity);
        return (
          <Accordion
            key={activity.id}
            expanded={expanded === index}
            onChange={handleAccordionChange(index)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}
              className={classes.activityAccordionSummary}
            >
              <div className={classes.accordionSummaryContent}>
                <Typography className={classes.accordionSummaryHeading}>
                  {activity.activityName}
                </Typography>
                {isSelfReported && (
                  <div className={classes.selfBox}>Self</div>
                )}
                {expanded !== index && (
                  <div className={classes.rightAligned}>
                    {isSelfReported && (
                      <IconButton
                        className={classes.deleteButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(activity.id);
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small"/> {/* Adjust the icon size */}
                      </IconButton>
                    )}
                    <div className={classes.statusBox} style={{ color: getStatusColor(activity.status) }}>
                      {activity.status}
                    </div>
                  </div>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.activityCard}>
                                <div className={classes.activityTextContainer} style={{ flex: '1' }}>
                  <Typography className={classes.activityText}>
                  Status: <span className={classes.activityValue} style={{ color: getStatusColor(activity.status) }}>{activity.status}</span>
                  </Typography>
                  <Typography className={classes.activityText}>
                  Assigned to: <span className={classes.activityValue}>{activity.assignedTo}</span>
                  </Typography>
                  <Typography className={classes.activityText}>
                  Start date: <span className={classes.activityValue}>{activity.startDate}</span>
                  </Typography>
                  <Typography className={classes.activityText}>
                  End date: <span className={classes.activityValue}>{activity.endDate}</span>
                  </Typography>
                  {isSelfReported && activity.description && (
                    <Typography className={classes.activityText} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      Description: <span className={classes.activityValue}>{activity.description}</span>
                    </Typography>
                  )}
                </div>

                {/* Right Section - Chart and Legend */}
                <div className={classes.chartSection} style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {/* Pie Chart */}
                  <div className={classes.chartContainer}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getPieChartOptions(isSelfReported, activity.status)}
                    />
                  </div>

                  {/* Legend */}
                  <div className={classes.legendContainer}>
                    {legendItems.map((item, i) => (
                      <div key={i} className={classes.legendItem}>
                        <div
                          className={classes.legendColor}
                          style={{ backgroundColor: item.color }}
                        />
                        <Typography className={classes.legendText}>{item.name}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </AccordionDetails>

            {expanded === index && (activity.status === 'Active' || activity.status === 'Pending') && (
              <Accordion
                expanded={nestedExpanded}
                onChange={handleNestedAccordionChange}
                className={classes.nestedAccordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`nested-panel-content-${index}`}
                  id={`nested-panel-header-${index}`}
                  className={classes.submitProgressAccordionSummary}
                >
                  <Typography style={{ fontWeight: 600, alignContent: 'center', fontFamily: 'Inter', fontSize: '14px' }}>Submit activity progress</Typography>
                  {isSelfReported && !nestedExpanded && (
                    <Button
                      variant="contained"
                      className={classes.completeButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleComplete(activity.id);
                      }}
                    >
                      Complete
                    </Button>
                  )}
                </AccordionSummary>
                <AccordionDetails className={classes.nestedAccordionDetails}>
                  <div className={classes.formContainer}>
                    <div>
                      <Typography className={classes.nestedActivityText}>
                        Update activity date <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={activityDate}
                          onChange={(date) => setActivityDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                            />
                          )}
                          minDate={dayjs()}
                          inputFormat="YYYY/MM/DD"
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <Typography className={classes.nestedActivityText}>
                        Upload activity <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="file"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          id: 'file-input',
                        }}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <div>
                      <Typography className={classes.nestedActivityText}>
                        Enter comments
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter your comments here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </div>
                    <Button
                      variant="contained"
                      className={classes.submitButton}
                      onClick={handleSubmit}
                      disabled={!activityDate || !file}
                    >
                      Submit
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </Accordion>
        );
      })}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
};

export default CarePlanActivities;