import React, { useState, useRef } from "react"
import { Box, Typography, TextField, Checkbox } from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"

const UploadContainer = styled(Box)(({ theme }) => ({
  border: "1px dashed #D0D5DD",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#F9FAFB",
  },
}))

const FileList = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E0E0",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const FileItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  backgroundColor: "#F9FAFB",
  marginBottom: theme.spacing(1),
}))

const FileNameInput = styled(TextField)(({ theme }) => ({
  width: "70%",
  marginTop: theme.spacing(1),
}))

const UploadFile = ({ handleFileLoad }) => {
  const [uploadedFiles, setUploadedFiles] = useState([])
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      selectedFile: file,
      fileNameWithoutExtension: file.name.split(".").slice(0, -1).join("."),
      extension: file.name.split(".").pop(),
    }))

    setUploadedFiles([...uploadedFiles, ...newFiles])
    handleFileLoad([...uploadedFiles, ...newFiles])
  }

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles]
    updatedFiles.splice(index, 1)
    setUploadedFiles(updatedFiles)
    handleFileLoad(updatedFiles)
  }

  const handleFileNameChange = (index, newName) => {
    const updatedFiles = [...uploadedFiles]
    updatedFiles[index].fileNameWithoutExtension = newName
    setUploadedFiles(updatedFiles)
    handleFileLoad(updatedFiles)
  }

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  return (
    <Box>
      <UploadContainer onClick={handleUploadClick}>
        <input type="file" multiple onChange={handleFileChange} style={{ display: "none" }} ref={fileInputRef} />
        <CloudUploadIcon sx={{ fontSize: 40, color: "#98A2B3", mb: 1 }} />
        <Typography variant="body1" sx={{ fontWeight: 500, mb: 0.5 }}>
          Click to upload or drag and drop
        </Typography>
        <Typography color="textSecondary">
          PDF, DOC, DOCX, JPG, PNG (max 10MB)
        </Typography>
      </UploadContainer>

      {uploadedFiles.length > 0 && (
        <FileList>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
            {uploadedFiles.length} File Selected
          </Typography>
          {uploadedFiles.map((file, index) => (
            <Box key={index}>
              <FileItem>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 1 }}>
                  <Typography  noWrap>
                    {file.selectedFile.name}
                  </Typography>
                  <FileNameInput
                    variant="outlined"
                    size="small"
                    value={file.fileNameWithoutExtension}
                    onChange={(e) => handleFileNameChange(index, e.target.value)}
                    placeholder="Enter file name"
                  />
                </Box>
                <DeleteIcon
                  sx={{ color: "#F04438", cursor: "pointer" }}
                  onClick={() => handleRemoveFile(index)}
                />
              </FileItem>
            </Box>
          ))}
        </FileList>
      )}
    </Box>
  )
}

export default UploadFile
