import MonteNidoLogo from '../../../assets/images/monte-nido-logo-no-bg.png';
import FindCareImage from '../../../assets/images/findCareImage.svg';
import FailedToLoadIcon from '../../../assets/images/failedToLoadIcon.svg';
import AddAdult from '../../../assets/images/addAdultPNG.png';
import AddMinor from '../../../assets/images/minorBaby.svg';
import { COMMUNICATION_PREFERENCE } from '../../../utils/constants';

export const mockTemplates = {
  circles: {
    orgAbortFeedback: {
      showCancel: true,
    },
  },
  documents: {
    title: 'Documents',
    resourceName: 'documents',
    loadingText: 'documents',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/conditions/conditions-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      addDocument: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by document name',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '260',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Document name',
          type: 'string',
          dataCol: 'documentResponse.name',
        },
        {
          headerName: 'Type',
          type: 'string',
          dataCol: 'documentResponse.format',
        },
        {
          headerName: 'Category',
          type: 'string',
          isTitleCase: true,
          dataCol: 'documentResponse.type',
        },
        {
          headerName: 'Uploaded By',
          type: 'string',
          isTitleCase: true,
          dataCol: 'documentResponse.uploadedBy',
        },
        {
          headerName: 'Uploaded date',
          type: 'string',
          dataCol: 'documentResponse.updatedAt',
        },
        {
          headerName: 'Download',
          type: 'downloadButton',
          dataCol: '',
        },
      ],
    },
  },
  conditions: {
    title: 'Conditions',
    resourceName: 'condition',
    loadingText: 'conditions',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/conditions/conditions-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by condition code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '260',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Inactive',
              value: 'inactive',
              iconColor: '#DB0032',
            },
            {
              label: 'Resolved',
              value: 'resolved',
              iconColor: '#007D32',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['recurrence', 'relapse', 'remission', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'string',
          dataCol: 'recordedDate',
        },
        {
          headerName: 'Condition',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'conditionCodeName',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['statusColor', 'clinicalStatus'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Severity',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'severity',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Condition details',
      loadingText: 'conditions',
      categoryNameForMarkAsRestricted: 'condition',
      detailsTable: {
        header: {
          template: '${value}',
          key1: '-',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'conditionName',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['statusColor', 'clinicalStatus'],
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            label: 'Recorded on',
            type: 'string',
            key: 'recordedDate',
            requestKeyForSelfReporting: 'recordedOn',
          },
          {
            label: 'Recorded by',
            type: 'string',
            key: 'recorder.display',
            requestKeyForSelfReporting: 'recordedBy',
          },
          {
            label: 'Facility',
            type: 'string',
            key: 'encounter.display',
            requestKeyForSelfReporting: 'facility',
          },
          {
            label: 'Category',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'category',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'category',
          },
          {
            label: 'Code',
            key: 'code.coding.0.code',
            requestKeyForSelfReporting: 'codeName',
            type: 'string',
          },
          {
            label: 'Severity',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'severity',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'severity',
          },
          {
            label: 'Documents',
            type: 'document',
            key: 'docArn',
            requestKeyForSelfReporting: 'documents',
            width: 'fullwidth',
          },
          {
            label: 'Summary',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'summary',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'summary',
            width: 'fullwidth',
          },
          {
            label: 'Note',
            type: 'string',
            key: 'note.0.text',
            requestKeyForSelfReporting: 'note',
            width: 'fullwidth',
          },
        ],
        lists: [
          {
            header: 'Problems',
            descriptionKey: 'addresses.code.coding.display',
            statusKey: 'addresses.clinicalStatus',
          },
          {
            header: 'Goals',
            descriptionKey: 'goal.description.coding.display',
            statusKey: 'goal.lifecycleStatus',
          },
          {
            header: 'Activities',
            descriptionKey: 'activity.detail.code.coding.display',
            statusKey: '',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'conditions',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          key: 'recordedDate',
          objectKeyForUI: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recorded_on',
          source: 'extension',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          // key: 'data_source',
          objectKeyForUI: 'dataSource',
          filterKey: 'url',
          filterVal: 'dataSource',
          source: 'extension',
          resultKey: 'valueString',
        },
      ],
    },
    selfReportingSection: {
      steps: ['Add data', 'Documents'],
      resourceNameForSelfReportingFileUpload: 'Condition',
      resourceType: 'Conditions',
      convertToLowerCaseList: ['status'],
      formElementList: [
        {
          label: 'Conditions name',
          type: 'text',
          placeholder: 'Enter condition name',
          valueKey: 'conditionName',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Facility',
          type: 'text',
          placeholder: 'Enter facility',
          valueKey: 'facility',
        },
        {
          label: 'Status',
          type: 'dropdown',
          placeholder: 'Select status',
          valueKey: 'status',
          required: true,
          errorMsg: 'This field is a required field!',
          options: [
            {
              value: 'active',
              label: 'Active',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              value: 'recurrence',
              label: 'Recurrence',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'relapse',
              label: 'Relapse',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'inactive',
              label: 'Inactive',
              statusIcon: true,
              color: '#DB0032',
            },
            {
              value: 'remission',
              label: 'Remission',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'resolved',
              label: 'Resolved',
              statusIcon: true,
              color: '#007D32',
            },
          ],
        },
        {
          label: 'Category',
          placeholder: 'Select category',
          type: 'dropdown',
          valueKey: 'category',
          options: [
            {
              value: 'Problem list item',
              label: 'Problem list item',
            },
            {
              value: 'Encounter diagnosis',
              label: 'Encounter diagnosis',
            },
          ],
        },
        {
          label: 'Severity',
          type: 'dropdown',
          placeholder: 'Select severity',
          valueKey: 'severity',
          options: [
            {
              value: 'Severe',
              label: 'Severe',
            },
            {
              value: 'Moderate',
              label: 'Moderate',
            },
            {
              value: 'Mild',
              label: 'Mild',
            },
          ],
        },
        {
          label: 'Recorded by',
          type: 'text',
          placeholder: 'Recorded by e.g. John Robertson',
          valueKey: 'recordedBy',
        },
        {
          label: 'Recorded on',
          type: 'date',
          required: true,
          valueKey: 'recordedOn',
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Code',
          type: 'text',
          placeholder: 'Enter condition code',
          valueKey: 'codeName',
        },
        {
          label: 'Note',
          type: 'multiline',
          placeholder: 'Add note',
          numberOfLines: 5,
          valueKey: 'note',
          width: 'fullWidth',
        },
      ],
    },
  },
  allergies: {
    title: 'Allergies',
    resourceName: 'allergy',
    loadingText: 'allergies',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/allergies/allergies-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by allergy code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Inactive',
              value: 'inactive',
              iconColor: '#DB0032',
            },
            {
              label: 'Resolved',
              value: 'resolved',
              iconColor: '#007D32',
            },
            {
              label: 'Confirmed',
              value: 'confirmed',
              iconColor: '#036FCB',
            },
            {
              label: 'Unconfirmed',
              value: 'unconfirmed',
              iconColor: '#737373',
            },
            {
              label: 'Refuted',
              value: 'refuted',
              iconColor: '#737373',
            },
            {
              label: 'Unknown',
              value: 'others',
              mappingList: ['entered-in-error', 'on-hold', 'draft', 'unknown'],
              iconColor: '#737373',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'string',
          dataCol: 'recordedDate',
        },
        {
          headerName: 'Allergic to',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'allergyName',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['statusColor', 'clinicalStatus'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Criticality',
          type: 'string',
          dataCol: 'criticality',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Allergy details',
      loadingText: 'allergies',
      categoryNameForMarkAsRestricted: 'allergy',
      detailsTable: {
        header: {
          template: '${value}',
          key1: '-',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'allergyCodeName',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['statusColor', 'clinicalStatus'],
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            label: 'Recorded on',
            type: 'string',
            key: 'recordedDate',
            requestKeyForSelfReporting: 'recordedOn',
          },
          {
            label: 'Recorded by',
            type: 'string',
            key: 'recorder.display',
            requestKeyForSelfReporting: 'recordedBy',
          },
          {
            label: 'Facility',
            type: 'string',
            key: 'encounter.display',
            requestKeyForSelfReporting: 'facility',
          },
          {
            label: 'Code',
            type: 'string',
            key: 'code.coding.0.code',
            requestKeyForSelfReporting: 'codeName',
          },
          {
            label: 'Start time',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'startTime',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'startTime',
          },
          {
            label: 'Criticality',
            type: 'string',
            key: 'criticality',
            requestKeyForSelfReporting: 'criticality',
          },
          {
            label: 'Category',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'category',
            requestKeyForSelfReporting: 'category',
            resultKey: 'valueString',
          },
          {
            label: 'Route',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'route',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'route',
            width: 'fullwidth',
          },
          {
            label: 'Reaction',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'reaction',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'reaction',
            width: 'fullwidth',
          },
          {
            label: 'Documents',
            type: 'document',
            key: 'docArn',
            requestKeyForSelfReporting: 'documents',
            width: 'fullWidth',
          },
          {
            label: 'Note',
            type: 'string',
            key: 'note.0.text',
            requestKeyForSelfReporting: 'note',
            width: 'fullWidth',
          },
        ],
        lists: [
          {
            header: 'Problems',
            descriptionKey: 'addresses.code.coding.display',
            statusKey: 'addresses.clinicalStatus',
          },
          {
            header: 'Goals',
            descriptionKey: 'goal.description.coding.display',
            statusKey: 'goal.lifecycleStatus',
          },
          {
            header: 'Activities',
            descriptionKey: 'activity.detail.code.coding.display',
            statusKey: '',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'allergies',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recorded_on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          filterKey: 'url',
          filterVal: 'data_source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
        },
      ],
    },
    selfReportingSection: {
      resourceNameForSelfReportingFileUpload: 'AllergyIntolerance',
      resourceType: 'Allergies',
      steps: ['Add data', 'Documents'],
      convertToLowerCaseList: ['status', 'category', 'criticality'],
      formElementList: [
        {
          label: 'Allergic to',
          type: 'text',
          placeholder: 'Enter allergy',
          required: true,
          valueKey: 'allergicTo',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Facility',
          type: 'text',
          placeholder: 'Enter facility name',
          valueKey: 'facility',
        },
        {
          label: 'Criticality',
          type: 'dropdown',
          isMappingRequired: true,
          conversionFromValueToLabel: ['criticality'],
          placeholder: 'Select criticality',
          valueKey: 'criticality',
          options: [
            {
              label: 'Low risk',
              value: 'low',
            },
            {
              label: 'High risk',
              value: 'high',
            },
            {
              label: 'Unable to assess risk',
              value: 'unable-to-assess',
            },
          ],
        },
        {
          label: 'Category',
          type: 'dropdown',
          placeholder: 'Select category type',
          valueKey: 'category',
          required: true,
          errorMsg: 'This field is a required field!',
          options: [
            {
              label: 'Food',
              value: 'food',
            },
            {
              label: 'Medication',
              value: 'medication',
            },
            {
              label: 'Environment',
              value: 'environment',
            },
            {
              label: 'Biologic',
              value: 'biologic',
            },
          ],
        },
        {
          label: 'Route',
          type: 'text',
          resultKey: 'description',
          placeholder: 'Enter route e.g. Nasal, oral, dental, etc',
          valueKey: 'route',
        },
        {
          label: 'Status',
          type: 'dropdown',
          placeholder: 'Select allergy status',
          required: true,
          valueKey: 'status',
          errorMsg: 'This field is a required field!',
          options: [
            {
              value: 'active',
              label: 'Active',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              value: 'inactive',
              label: 'Inactive',
              statusIcon: true,
              color: '#DB0032',
            },
            {
              value: 'resolved',
              label: 'Resolved',
              statusIcon: true,
              color: '#007D32',
            },
          ],
        },
        {
          label: 'Recorded on',
          type: 'date',
          valueKey: 'recordedOn',
          required: true,
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Start time',
          type: 'date',
          valueKey: 'startTime',
          required: true,
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Recorded by',
          type: 'text',
          placeholder: 'Recorded by e.g. John Robertson',
          valueKey: 'recordedBy',
        },
        {
          label: 'Code',
          type: 'text',
          placeholder: 'Enter allergy code',
          valueKey: 'codeName',
        },
        {
          label: 'Reactions',
          type: 'multiple',
          placeholder: 'Add reactions of the allergy',
          resultKey: 'description',
          numberOfLines: 5,
          valueKey: 'reaction',
          width: 'fullWidth',
        },
        {
          label: 'Note',
          type: 'multiline',
          placeholder: 'Add note',
          numberOfLines: 5,
          valueKey: 'note',
          width: 'fullWidth',
        },
      ],
    },
  },
  labs: {
    title: 'Lab results',
    resourceName: 'lab result',
    loadingText: 'lab results',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/labresults/labresults-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by lab result code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Registered',
              value: 'registered',
              iconColor: '#036FCB',
            },
            {
              label: 'Partial',
              value: 'partial',
              iconColor: '#F2994A',
            },
            {
              label: 'Preliminary',
              value: 'preliminary',
              iconColor: '#F2994A',
            },
            {
              label: 'Final',
              value: 'final',
              iconColor: '#007D32',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: [
                'amended',
                'appended',
                'corrected',
                'cancelled',
                'entered-in-error',
                'unknown',
              ],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Name',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'name',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Category code',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'categoryCode',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Lab result details',
      loadingText: 'lab result',
      categoryNameForMarkAsRestricted: 'lab result',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'code.coding.0.display',
          key2: 'code.coding.0.code',
        },
        customCardForViewDetails: {
          sourceObj: 'extension',
          filterKey: 'url',
          requiredPropsForViewDetailsCustomCards: [
            'value',
            'labResult',
            // 'unit',
            // 'test_results',
            'referenceRange',
          ],
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            label: 'Recorded on',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recordedOn',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'recordedOn',
          },
          {
            label: 'Value',
            type: 'filter',
            hide: true,
            source: 'extension',
            filterKey: 'url',
            filterVal: 'value',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'value',
          },
          {
            label: 'Unit',
            hide: true,
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'unit',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'units',
          },
          {
            label: 'Recorded by',
            key: 'performer.0.display',
            type: 'string',
            requestKeyForSelfReporting: 'recordedBy',
          },
          {
            label: 'Reference range',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'referenceRange',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'referenceRange',
          },
          {
            label: 'Facility',
            type: 'string',
            key: 'encounter.display',
            requestKeyForSelfReporting: 'facility',
          },
          {
            label: 'Lab result',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'test_results',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'testResults',
          },
          {
            label: 'Issued date',
            hide: true,
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'issued_date_time',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'issuedDate',
          },
          {
            label: 'Issued time',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'issued_date_time',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'issuedTime',
          },
          {
            label: 'Issuer of the report',
            type: 'string',
            key: 'resultsInterpreter.0.display',
            requestKeyForSelfReporting: 'issuerOfTheReport',
          },
          {
            label: 'Category code',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'categoryCode',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'categoryCode',
          },
          {
            label: 'LOINC code',
            key: 'code.coding.0.code',
            type: 'string',
            width: 'fullwidth',
            requestKeyForSelfReporting: 'loincCode',
          },
          {
            label: 'Documents',
            type: 'document',
            key: 'docArn',
            width: 'fullWidth',
            requestKeyForSelfReporting: 'documents',
          },
          {
            label: 'Note',
            width: 'fullWidth',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'note',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'note',
          },
        ],
        lists: [
          {
            header: 'Problems',
            descriptionKey: 'addresses.code.coding.display',
            statusKey: 'addresses.clinicalStatus',
          },
          {
            header: 'Goals',
            descriptionKey: 'goal.description.coding.display',
            statusKey: 'goal.lifecycleStatus',
          },
          {
            header: 'Activities',
            descriptionKey: 'activity.detail.code.coding.display',
            statusKey: '',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'Labs',
      cardFields: [
        {
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recordedOn',
          headerName: 'Recorded on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
          collapsedView: true,
          type: 'filter',
        },
        {
          filterKey: 'url',
          filterVal: 'dataSource',
          headerName: 'Data source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
          type: 'filter',
        },
      ],
    },
    selfReportingSection: {
      resourceNameForSelfReportingFileUpload: 'DiagnosticReport',
      convertToLowerCaseList: ['status'],
      resourceType: 'labs',
      steps: ['Add data', 'Documents'],
      formElementList: [
        {
          label: 'Test name',
          type: 'text',
          placeholder: 'Enter test name',
          required: true,
          valueKey: 'testName',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Facility',
          type: 'text',
          placeholder: 'Enter facility name',
          valueKey: 'facility',
        },
        {
          label: 'Value',
          type: 'text',
          required: true,
          placeholder: 'Enter lab-result value e.g. positive, 4.8 etc.',
          valueKey: 'value',
          errorMsg: 'Please enter a valid value',
          regex: '^[a-zA-Z0-9]+(.[0-9]+)?$',
        },
        {
          label: 'Units',
          type: 'text',
          placeholder: 'Enter lab-result value unit e.g. mmHg',
          valueKey: 'units',
        },
        {
          label: 'Reference range',
          type: 'text',
          placeholder: 'Enter reference range',
          valueKey: 'referenceRange',
          regex: '^[0-9]+(.[0-9]+)?-[0-9]+(.[0-9]+)?$',
          errorMsg:
            'Please follow this pattern for reference range (lower-upper). Ex. 80.45-100.34',
        },
        {
          label: 'Lab result',
          type: 'dropdown',
          required: true,
          placeholder: 'Select result',
          valueKey: 'testResults',
          errorMsg: 'This field is a required field!',
          options: [
            {
              label: 'High',
              value: 'High',
            },
            {
              label: 'Normal',
              value: 'Normal',
            },
            {
              label: 'Low',
              value: 'Low',
            },
            {
              label: 'Abnormal',
              value: 'Abnormal',
            },
            {
              label: 'Indeterminate',
              value: 'Indeterminate',
            },
            {
              label: 'Equivocal',
              value: 'Equivocal',
            },
            {
              label: 'Negative',
              value: 'Negative',
            },
            {
              label: 'Not detected',
              value: 'Not detected',
            },
            {
              label: 'Positive',
              value: 'Positive',
            },
            {
              label: 'Detected',
              value: 'Detected',
            },
            {
              label: 'Expected',
              value: 'Expected',
            },
            {
              label: 'Unexpected',
              value: 'Unexpected',
            },
            {
              label: 'Non-reactive',
              value: 'Non-reactive',
            },
            {
              label: 'Reactive',
              value: 'Reactive',
            },
            {
              label: 'Others',
              value: 'Others',
            },
          ],
        },
        {
          label: 'Status',
          type: 'dropdown',
          placeholder: 'Select status',
          required: true,
          valueKey: 'status',
          errorMsg: 'This field is a required field!',
          isMappingRequired: true,
          conversionFromValueToLabel: ['status'],
          options: [
            {
              label: 'Registered',
              value: 'registered',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              label: 'Partial',
              value: 'partial',
              statusIcon: true,
              color: '#F2994A',
            },
            {
              label: 'Preliminary',
              value: 'preliminary',
              statusIcon: true,
              color: '#F2994A',
            },
            {
              label: 'Final',
              value: 'final',
              statusIcon: true,
              color: '#007D32',
            },
            {
              label: 'Amended',
              value: 'amended',
              statusIcon: true,
              color: '#007D32',
            },
            {
              label: 'Corrected',
              value: 'corrected',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              label: 'Appended',
              value: 'appended',
              statusIcon: true,
              color: '#007D32',
            },
            {
              label: 'Cancelled',
              value: 'cancelled',
              statusIcon: true,
              color: '#DB0032',
            },
            {
              label: 'Entered in error',
              value: 'entered-in-error',
              statusIcon: true,
              color: '#737373',
            },
            {
              label: 'Unknown',
              value: 'unknown',
              statusIcon: true,
              color: '#737373',
            },
          ],
        },
        {
          label: 'Category code',
          type: 'dropdown',
          placeholder: 'Select category code',
          valueKey: 'categoryCode',
          isMappingRequired: true,
          conversionFromValueToLabel: ['categoryCode'],
          options: [
            {
              label: 'Audiology',
              value: 'AU',
            },
            {
              label: 'Blood Gases',
              value: 'BG',
            },
            {
              label: 'Blood Bank',
              value: 'BLB',
            },
            {
              label: 'Cytogenetics',
              value: 'CG',
            },
            {
              label: 'Chemistry',
              value: 'CH',
            },
            {
              label: 'Cytopathology',
              value: 'CP',
            },
            {
              label: 'CAT Scan',
              value: 'CT',
            },
            {
              label: 'Cardiac Catheterization',
              value: 'CTH',
            },
            {
              label: 'Cardiac Ultrasound',
              value: 'CUS',
            },
            {
              label: 'Electrocardiac (e.g., EKG, EEC, Holter)',
              value: 'EC',
            },
            {
              label: 'Electroneuro (EEG, EMG, EP, PSG)',
              value: 'EN',
            },
            {
              label: 'Genetics',
              value: 'GE',
            },
            {
              label: 'Hematology',
              value: 'HM',
            },
            {
              label: 'Bedside ICU Monitoring',
              value: 'ICU',
            },
            {
              label: 'Immunology',
              value: 'IMM',
            },
            {
              label: 'Laboratory',
              value: 'LAB',
            },
            {
              label: 'Microbiology',
              value: 'MB',
            },
            {
              label: 'Mycobacteriology',
              value: 'MCB',
            },
            {
              label: 'Mycology',
              value: 'MYC',
            },
            {
              label: 'Nuclear Magnetic Resonance',
              value: 'NMR',
            },
            {
              label: 'Nuclear Medicine Scan',
              value: 'NMS',
            },
            {
              label: 'Nursing Service Measures',
              value: 'NRS',
            },
            {
              label: 'Outside Lab',
              value: 'OSL',
            },
            {
              label: 'Occupational Therapy',
              value: 'OT',
            },
            {
              label: 'Other',
              value: 'OTH',
            },
            {
              label: 'OB Ultrasound',
              value: 'OUS',
            },
            {
              label: 'Pulmonary Function',
              value: 'PF',
            },
            {
              label: 'Pharmacy',
              value: 'PHR',
            },
            {
              label: 'Physician (Hx. Dx, admission note, etc.)',
              value: 'PHY',
            },
            {
              label: 'Physical Therapy',
              value: 'PT',
            },
            {
              label: 'Radiology',
              value: 'RAD',
            },
            {
              label: 'Respiratory Care (therapy)',
              value: 'RC',
            },
            {
              label: 'Radiation Therapy',
              value: 'RT',
            },
            {
              label: 'Radiology Ultrasound',
              value: 'RUS',
            },
            {
              label: 'Radiograph',
              value: 'RX',
            },
            {
              label: 'Surgical Pathology',
              value: 'SP',
            },
            {
              label: 'Serology',
              value: 'SR',
            },
            {
              label: 'Toxicology',
              value: 'TX',
            },
            {
              label: 'Virology',
              value: 'VR',
            },
            {
              label: 'Vascular Ultrasound',
              value: 'VUS',
            },
            {
              label: 'Cineradiograph',
              value: 'XRC',
            },
          ],
        },
        {
          label: 'LOINC code',
          type: 'text',
          placeholder: 'Enter lab-test code',
          valueKey: 'loincCode',
        },
        {
          label: 'Issued date',
          type: 'date',
          valueKey: 'issuedDate',
          required: true,
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Issued time',
          type: 'time',
          valueKey: 'issuedTime',
          placeholder: 'HH : MM',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Recorded on',
          type: 'date',
          valueKey: 'recordedOn',
          required: true,
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Recorded by',
          type: 'text',
          placeholder: 'Recorded by e.g. John Robertson',
          valueKey: 'recordedBy',
        },
        {
          label: 'Issuer of the report',
          type: 'text',
          placeholder: 'Enter issuer name',
          valueKey: 'issuerOfTheReport',
        },
        {
          label: 'Note',
          type: 'multiline',
          placeholder: 'Add note',
          numberOfLines: 5,
          valueKey: 'note',
          width: 'fullWidth',
        },
      ],
    },
  },
  care_team: {
    title: 'Care teams',
    resourceName: 'care team',
    loadingText: 'care teams',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careteam/careteam-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      filters: [
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Entered in error',
              value: 'entered-in-error',
              iconColor: '#737373',
            },
            {
              label: 'Inactive',
              value: 'inactive',
              iconColor: '#DB0032',
            },
            {
              label: 'Proposed',
              value: 'proposed',
              iconColor: '#F2994A',
            },
            {
              label: 'Suspended',
              value: 'suspended',
              iconColor: '#DB0032',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Team name',
          type: 'string',
          dataCol: 'name',
          collapsedView: true,
        },
        {
          headerName: 'Category',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'category',
          resultKey: 'valueString',
          type: 'filter',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Care team details',
      loadingText: 'care team',
      categoryNameForMarkAsRestricted: 'care teams',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'name',
          key2: 'name',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            key: 'period.start',
            type: 'date',
            label: 'Start date',
            requestKeyForSelfReporting: 'start_time',
          },
          {
            label: 'Contact number',
            type: 'string',
            key: 'telecom.0.value',
            requestKeyForSelfReporting: 'contactNumber',
          },
          {
            key: 'period.end',
            type: 'date',
            label: 'End date',
            requestKeyForSelfReporting: 'end_time',
          },
          {
            label: 'Category',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'category',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'category',
          },
          {
            label: 'Reason',
            type: 'string',
            key: 'reasonCode.0.coding.0.display',
            width: 'fullWidth',
            requestKeyForSelfReporting: 'reason',
          },
          {
            label: 'Note',
            width: 'fullWidth',
            type: 'objectList',
            source: 'note',
            resultKey: 'text',
            requestKeyForSelfReporting: 'note',
          },
        ],
        lists: [
          {
            header: 'Problems',
            descriptionKey: 'addresses.code.coding.display',
            statusKey: 'addresses.clinicalStatus',
          },
          {
            header: 'Goals',
            descriptionKey: 'goal.description.coding.display',
            statusKey: 'goal.lifecycleStatus',
          },
          {
            header: 'Activities',
            descriptionKey: 'activity.detail.code.coding.display',
            statusKey: '',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: false,
        },
      },
    },
  },
  care_plan: {
    title: 'Care plans',
    resourceName: 'care plan',
    loadingText: 'care plans',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careplan/careplan-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      search: {
        isEnabled: false,
        placeholder: 'Search by lab result code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: true,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Revoked',
              value: 'revoked',
              iconColor: '#DB0032',
            },
            {
              label: 'Draft',
              value: 'draft',
              iconColor: '#F2994A',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: 'gray',
              mappingList: ['entered-in-error', 'on-hold', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Plan name',
          type: 'string',
          dataCol: 'title',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'criticality',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Assigned to',
          type: 'link',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'careteam',
          resultKey: 'valueString',          
        },
        {
          headerName: 'Start date',
          type: 'string',
          dataCol: 'period.start',
        },
        {
          headerName: 'End date',
          type: 'string',
          dataCol: 'period.end',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'identifier',
          filterKey: 'system',
          filterVal: 'dataSource',
          resultKey: 'value',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Care plan',
      loadingText: 'care plan',
      categoryNameForMarkAsRestricted: 'care plan',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'title',
          key2: 'code.coding.0.code',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
          },
          {
            label: 'Recorded on',
            key: 'created',
            type: 'date',
          },
          {
            label: 'Recorded by',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            key: 'author.display',
            filterVal: 'recorded_by',
            resultKey: 'valueString',
          },
          {
            label: 'Facility',
            type: 'string',
            key: 'contributor.0.display',
          },
          {
            label: 'Category',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'category',
            resultKey: 'valueString',
          },
          {
            label: 'Start date',
            type: 'string',
            key: 'period.start',
          },
          {
            label: 'End date',
            type: 'string',
            key: 'period.end',
          },
          {
            label: 'Intent',
            key: 'intent',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'intent',
            resultKey: 'valueString',
          },
          {
            label: 'Description',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            key: 'description',
            filterVal: 'criticality',
            resultKey: 'valueString',
            width: 'fullWidth',
          },
          {
            label: 'Note',
            type: 'arrayList',
            arrayName: 'note',
            objectPath: 'text',
            width: 'fullWidth',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
      list: [
        {
          title: 'Problems',
          arrayName: 'addresses',
          objectPath: 'display',
          parseAssociatedResponseObject: true,
          desciptionPath: 'code',
          statusPath: 'problem-status',
        },
        {
          title: 'Goals',
          arrayName: 'goal',
          objectPath: 'display',
          parseAssociatedResponseObject: true,
          desciptionPath: 'description',
          statusPath: 'status',
        },
        {
          title: 'Activities',
          arrayName: 'activity',
          parseAssociatedResponseObject: false,
          objectPath1: 'detail.code.coding.0.display',
          objectPath2: 'detail.code.text',
          objectPath3: 'detail.code.coding.0.code',
        },
      ],
      associatedData: {
        title: 'Care team',
        type: 'string',
        source: 'extension',
        filterKey: 'url',
        filterVal: 'associated_data',
        resultKey: 'valueString',
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'Lab results',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          objectKeyForUI: 'recordedOn',
          dataCol: 'recordedOn',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          objectKeyForUI: 'dataSource',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          key: 'data_source',
          resultKey: 'valueString',
        },
      ],
    },
  },
  claims: {
    title: 'Claims',
    resourceName: 'claim',
    loadingText: 'claims',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/claims/claims-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by claim number',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Cancelled',
              value: 'cancelled',
              iconColor: '#DB0032',
            },
            {
              label: 'Draft',
              value: 'draft',
              iconColor: '#F2994A',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['entered-in-error', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'recorded_on',
          resultKey: 'valueString',
        },
        {
          headerName: 'Claim Number',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'claim_number',
          resultKey: 'valueString',
        },
        {
          headerName: 'Claim type',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'claim-type',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Provider',
          type: 'string',
          dataCol: 'provider.display',
        },
        {
          headerName: 'Total claim amount',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'total_claim_amount',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'identifier',
          filterKey: 'system',
          filterVal: 'dataSource',
          resultKey: 'value',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Claims details',
      loadingText: 'claims',
      categoryNameForMarkAsRestricted: 'claims',
      detailsTable: {
        header: {
          template: '${value}',
          source: 'extension',
          key1: '-',
          filterKey: 'url',
          filterVal: 'claim_number',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'identifier',
            filterKey: 'system',
            filterVal: 'dataSource',
            resultKey: 'value',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
          },
          {
            label: 'Provider',
            type: 'string',
            key: 'provider.display',
          },
          {
            label: 'Provider number',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'providerNumber',
            resultKey: 'valueString',
          },
          {
            label: 'Claim type',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'claim_type',
            resultKey: 'valueString',
          },
          {
            headerName: 'Created on',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recorded_on',
            resultKey: 'valueString',
          },
          {
            label: 'Total claim amount',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'total_claim_amount',
            resultKey: 'valueString',
          },
          {
            label: 'Start date',
            type: 'string',
            key: 'billablePeriod.start',
          },
          {
            label: 'End date',
            type: 'string',
            key: 'billablePeriod.end',
          },
          {
            label: 'Paid to provider',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'paid_to_provider',
            resultKey: 'valueString',
          },
          {
            label: 'Diagnosis',
            width: 'fullWidth',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'diagnosis',
            resultKey: 'valueString',
          },
          {
            label: 'Procedure',
            width: 'fullWidth',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'procedure',
            resultKey: 'valueString',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: false,
        },
      },
    },
  },
  procedures: {
    title: 'Procedures',
    resourceName: 'procedure',
    loadingText: 'procedures',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/procedures/procedures-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by procedure code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Preparation',
              value: 'preparation',
              iconColor: '#036FCB',
            },
            {
              label: 'In progress',
              value: 'in-progress',
              iconColor: '#036FCB',
            },
            {
              label: 'Not done',
              value: 'not-done',
              iconColor: '#DB0032',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['on-hold', 'stopped', 'entered-in-error', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Start date',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'startDate',
          resultKey: 'valueString',
        },
        {
          headerName: 'Procedure',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'procedureCodeName',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Outcome',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['outcomeColor', 'outcome'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Procedure details',
      loadingText: 'procedures',
      categoryNameForMarkAsRestricted: 'procedure',
      detailsTable: {
        header: {
          template: '${value}',
          key1: '-',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'procedureName',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'startDate',
            resultKey: 'valueString',
            type: 'date',
            label: 'Start date',
            requestKeyForSelfReporting: 'startDate',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'endDate',
            resultKey: 'valueString',
            type: 'date',
            label: 'End date',
            requestKeyForSelfReporting: 'endDate',
          },
          {
            label: 'Facility',
            key: 'location.display',
            type: 'string',
            requestKeyForSelfReporting: 'facility',
          },
          {
            label: 'Code',
            key: 'code.coding.0.code',
            type: 'string',
            requestKeyForSelfReporting: 'codeName',
          },
          {
            label: 'Outcome',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['outcomeColor', 'outcome'],
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'outcome',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'complications',
            resultKey: 'valueString',
            type: 'string',
            label: 'Complications',
            requestKeyForSelfReporting: 'complications',
          },
          {
            label: 'Category',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'category',
            resultKey: 'valueString',
            type: 'string',
            requestKeyForSelfReporting: 'category',
          },
          {
            label: 'Follow up',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'followUp',
            resultKey: 'valueString',
            type: 'string',
            requestKeyForSelfReporting: 'followUp',
          },
          {
            label: 'Reason code',
            width: 'fullWidth',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'reasonCode',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'reasonCode',
          },
          {
            label: 'Recorded by',
            key: 'recorder.display',
            type: 'string',
            requestKeyForSelfReporting: 'recordedBy',
          },
          {
            label: 'Body site',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'bodySite',
            resultKey: 'valueString',
            type: 'string',
            width: 'fullWidth',
            requestKeyForSelfReporting: 'bodySite',
          },
          {
            label: 'Documents',
            key: 'docArn',
            type: 'document',
            width: 'fullWidth',
          },
          {
            label: 'Note',
            width: 'fullWidth',
            type: 'objectList',
            source: 'note',
            resultKey: 'text',
            requestKeyForSelfReporting: 'note',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'Procedures',
      cardFields: [
        {
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recordedOn',
          headerName: 'Recorded on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
          type: 'filter',
        },
        {
          filterKey: 'url',
          filterVal: 'dataSource',
          headerName: 'Data source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
          type: 'filter',
        },
      ],
    },
    selfReportingSection: {
      resourceType: 'procedures',
      steps: ['Add data', 'Documents'],
      resourceNameForSelfReportingFileUpload: 'Procedure',
      formElementList: [
        {
          label: 'Procedure name',
          placeholder: 'Enter procedure name',
          required: true,
          type: 'text',
          valueKey: 'procedureName',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Facility',
          placeholder: 'Enter facility name',
          type: 'text',
          valueKey: 'facility',
        },
        {
          label: 'Outcome',
          type: 'dropdown',
          required: true,
          placeholder: 'Select outcome',
          valueKey: 'outcome',
          errorMsg: 'This field is a required field!',
          options: [
            {
              label: 'Successful',
              value: 'Successful',
            },
            {
              label: 'Unsuccessful',
              value: 'Unsuccessful',
            },
            {
              label: 'Partially successful',
              value: 'Partially successful',
            },
          ],
        },
        {
          label: 'Reason',
          placeholder: 'Enter reason for procedure',
          type: 'text',
          valueKey: 'reasonCode',
        },
        {
          label: 'Complications',
          placeholder: 'Enter complications after procedure',
          type: 'text',
          valueKey: 'complications',
        },
        {
          label: 'Status',
          type: 'dropdown',
          required: true,
          isMappingRequired: true,
          conversionFromValueToLabel: ['status'],
          placeholder: 'select status',
          valueKey: 'status',
          errorMsg: 'This field is a required field!',
          options: [
            {
              label: 'Preparation',
              value: 'preparation',
              color: '#036FCB',
              statusIcon: true,
              key: 0,
            },
            {
              label: 'In progress',
              value: 'in-progress',
              color: '#036FCB',
              statusIcon: true,
              key: 1,
            },
            {
              label: 'Not done',
              value: 'not-done',
              color: '#DB0032',
              statusIcon: true,
              key: 2,
            },
            {
              label: 'On hold',
              value: 'on-hold',
              color: '#737373',
              statusIcon: true,
              key: 3,
            },
            {
              label: 'Stopped',
              value: 'stopped',
              color: '#737373',
              statusIcon: true,
              key: 4,
            },
            {
              label: 'Completed',
              value: 'completed',
              color: '#007D32',
              statusIcon: true,
              key: 5,
            },
            {
              label: 'Entered in error',
              value: 'entered-in-error',
              color: '#737373',
              statusIcon: true,
              key: 6,
            },
            {
              label: 'Unknown',
              value: 'unknown',
              color: '#737373',
              statusIcon: true,
              key: 7,
            },
          ],
        },
        {
          label: 'Code',
          placeholder: 'Enter procedure code name',
          valueKey: 'codeName',
          type: 'text',
        },
        {
          label: 'Follow up',
          placeholder: 'Enter instructions you need to follow',
          type: 'text',
          valueKey: 'followUp',
        },
        {
          label: 'Start date',
          type: 'date',
          valueKey: 'startDate',
          required: true,
          placeholder: 'MM / DD / YYYY',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'End date',
          type: 'date',
          valueKey: 'endDate',
          placeholder: 'MM / DD / YYYY',
        },
        {
          label: 'Recorded by',
          placeholder: 'Enter name e.g., Kerry, John',
          type: 'text',
          valueKey: 'recordedBy',
        },
        {
          label: 'Category',
          placeholder: 'Choose procedure classification',
          type: 'dropdown',
          valueKey: 'category',
          options: [
            {
              label: 'Psychiatry procedure or service',
              value: 'Psychiatry procedure or service',
            },
            {
              label: 'Counseling',
              value: 'Counselling',
            },
            {
              label: 'Education',
              value: 'Education',
            },
            {
              label: 'Surgical procedure',
              value: 'Surgical procedure',
            },
            {
              label: 'Diagnostic procedure',
              value: 'Diagnostic procedure',
            },
            {
              label: 'Chiropractic manipulation',
              value: 'Chiropractic manipulation',
            },
            {
              label: 'Social service procedure',
              value: 'Social service procedure',
            },
          ],
        },
        {
          label: 'Body site',
          placeholder: 'Enter body site e.g. left arm',
          type: 'multiline',
          numberOfLines: 8,
          valueKey: 'bodySite',
          width: 'fullWidth',
        },
        {
          label: 'Note',
          placeholder: 'Add note about the procedure',
          type: 'multiline',
          numberOfLines: 4,
          valueKey: 'note',
          width: 'fullWidth',
        },
      ],
    },
  },
  visits: {
    title: 'Visits',
    resourceName: 'visits',
    loadingText: 'visits',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/visits/visits-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        datePicker: true,
        placeholder: 'Search by visit date',
        requestKey: 'dateFilter',
        deviceType: 'all',
        addDebounce: true,
        width: '180',
      },
      filters: [
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Planned',
              value: 'planned',
              iconColor: '#F2994A',
            },
            {
              label: 'In progress',
              value: 'in-progress',
              iconColor: '#036FCB',
            },
            {
              label: 'Arrived',
              value: 'arrived',
              iconColor: '#007D32',
            },
            {
              label: 'Finished',
              value: 'finished',
              iconColor: '#007D32',
            },
            {
              label: 'Cancelled',
              value: 'cancelled',
              iconColor: '#DB0032',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['triaged', 'onleave', 'entered-in-error', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Start date',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'startDate',
          resultKey: 'valueString',
        },
        {
          headerName: 'Facility',
          type: 'string',
          dataCol: 'serviceProvider.display',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Type of visit',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'visitClass',
          resultKey: 'valueString',
        },
        {
          headerName: 'Provider',
          type: 'string',
          dataCol: 'participant.0.individual.display',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'visits details',
      loadingText: 'visits',
      categoryNameForMarkAsRestricted: 'visits',
      detailsTable: {
        header: {
          template: '${value}',
          key1: '-',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'CardHeader',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'startDate',
            resultKey: 'valueString',
            type: 'filter',
            label: 'Start date',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'endDate',
            resultKey: 'valueString',
            type: 'filter',
            label: 'End date',
          },
          {
            label: 'Recorded by',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recorded_by',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Visit class',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'visitClass',
            resultKey: 'valueString',
          },
          {
            label: 'Provider',
            type: 'string',
            key: 'participant.0.individual.display',
          },
          {
            label: 'Last visited on',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'lastVisitedOn',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Provider speciality',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'SPECIALITY_DISPLAY',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Service type',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'serviceType',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Encounter type',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'EncounterType',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Description',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'description',
            resultKey: 'valueString',
            type: 'filter',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: false,
        },
      },
    },
  },
  immunizations: {
    title: 'Immunizations',
    resourceName: 'immunization',
    loadingText: 'immunizations',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/immunizations/immunizations-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by immunization code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '280',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Not done',
              value: 'not-done',
              iconColor: '#DB0032',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['entered-in-error', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Administered on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'administeredOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Immunization',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'immunizationCodeName',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Dosage',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dosage',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Immunization details',
      loadingText: 'immunizations',
      categoryNameForMarkAsRestricted: 'immunization',
      detailsTable: {
        header: {
          template: '${value}',
          key1: '-',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'immunizationName',
          resultKey: 'valueString',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            label: 'Administered on',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'administeredOn',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'recordedOn',
          },
          {
            label: 'Administered by',
            key: 'performer.0.actor.display',
            type: 'string',
            requestKeyForSelfReporting: 'recordedBy',
          },
          {
            label: 'Body site',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'bodySite',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'bodySite',
          },
          {
            label: 'Route',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'route',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'route',
          },
          {
            label: 'Dosage',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dosage',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'dose',
          },
          {
            label: 'NDC code',
            key: 'vaccineCode.coding.0.code',
            type: 'string',
            requestKeyForSelfReporting: 'ndcCode',
          },
          {
            label: 'Vaccine lot no',
            key: 'lotNumber',
            type: 'string',
            requestKeyForSelfReporting: 'vaccineLotnumber',
          },
          {
            label: 'Vaccine manufacturer',
            key: 'manufacturer.display',
            type: 'string',
            requestKeyForSelfReporting: 'vaccineManufacturer',
          },
          {
            label: 'Vaccine dose no.',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'vaccineDoseNumber',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'vaccineDosenumber',
          },
          {
            label: 'Reason',
            width: 'fullWidth',
            type: 'arrayFromExtension',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'reasonCode',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'reasons',
          },
          {
            label: 'Reaction',
            arrayName: 'reaction',
            type: 'arrayList',
            objectPath: 'detail.display',
            requestKeyForSelfReporting: 'reaction',
          },
          {
            label: 'Vaccine expiration date',
            type: 'string',
            key: 'expirationDate',
            requestKeyForSelfReporting: 'vaccineExpirationdate',
          },
          {
            label: 'Facility',
            type: 'string',
            key: 'encounter.display',
            requestKeyForSelfReporting: 'facility',
          },
          {
            label: 'Note',
            width: 'fullWidth',
            type: 'objectList',
            source: 'note',
            resultKey: 'text',
            requestKeyForSelfReporting: 'note',
          },
          {
            label: 'Documents',
            key: 'docArn',
            type: 'document',
            width: 'fullwidth',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'immunizations',
      cardFields: [
        {
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recordedOn',
          headerName: 'Recorded on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
          type: 'filter',
        },
        {
          filterKey: 'url',
          filterVal: 'dataSource',
          headerName: 'Data source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
          type: 'filter',
        },
      ],
    },
    selfReportingSection: {
      resourceType: 'immunizations',
      resourceNameForSelfReportingFileUpload: 'Immunization',
      steps: ['Add data', 'Documents'],
      formElementList: [
        {
          label: 'Immunization name',
          placeholder: 'Enter vaccination name',
          required: true,
          type: 'text',
          valueKey: 'immunizationName',
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Dosage',
          placeholder: 'Enter dosage e.g. 2.5 ml,5 ml,10 ml,etc',
          type: 'text',
          valueKey: 'dose',
        },
        {
          label: 'Body site',
          placeholder: 'Enter body site e.g. left arm',
          type: 'text',
          valueKey: 'bodySite',
        },
        {
          label: 'Vaccine dose number',
          placeholder: 'Enter vaccine dose number',
          type: 'text',
          valueKey: 'vaccineDosenumber',
          regex: '^([1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500)$',
          errorMsg: 'Please enter a valid vaccine dose number between 1 - 500',
          information: 'Please enter a valid vaccine dose number between 1 - 500',
        },
        {
          label: 'Vaccine manufacturer',
          placeholder: 'Enter vaccine manufacturer',
          type: 'text',
          valueKey: 'vaccineManufacturer',
        },
        {
          label: 'Vaccine lot number',
          placeholder: 'Enter vaccine lot number',
          type: 'text',
          valueKey: 'vaccineLotnumber',
        },
        {
          label: 'Vaccine expiration date',
          placeholder: 'Enter vaccine expiration date',
          type: 'date',
          valueKey: 'vaccineExpirationdate',
          allowFutureDates: true,
        },
        {
          label: 'Route',
          type: 'dropdown',
          required: true,
          placeholder: 'Select route',
          valueKey: 'route',
          conversionFromValueToLabel: ['route'],
          isMappingRequired: true,
          errorMsg: 'This field is a required field!',
          options: [
            {
              key: 0,
              label: 'Injection, intradermal',
              value: 'IDINJ',
            },
            {
              key: 1,
              label: 'Injection, intramuscular',
              value: 'IM',
            },
            {
              key: 2,
              label: 'Inhalation, nasal',
              value: 'NASINHLC',
            },
            {
              key: 3,
              label: 'Injection, intravenous',
              value: 'IVINJ',
            },
            {
              key: 4,
              label: 'Swallow, oral',
              value: 'PO',
            },
            {
              key: 5,
              label: 'Injection, subcutaneous',
              value: 'SQ',
            },
            {
              key: 6,
              label: 'Transdermal',
              value: 'TRNSDERM',
            },
          ],
        },
        {
          label: 'NDC code',
          placeholder: 'Enter vaccination code',
          type: 'text',
          valueKey: 'ndcCode',
        },
        {
          label: 'Status',
          type: 'dropdown',
          required: true,
          placeholder: 'Select status',
          valueKey: 'status',
          isMappingRequired: true,
          conversionFromValueToLabel: ['status'],
          errorMsg: 'This field is a required field!',
          options: [
            {
              key: 0,
              label: 'Completed',
              value: 'completed',
              statusIcon: true,
              color: '#007D32',
            },
            {
              key: 1,
              label: 'Entered in error',
              value: 'entered-in-error',
              statusIcon: true,
              color: '#737373',
            },
            {
              key: 2,
              label: 'Not done',
              value: 'not-done',
              statusIcon: true,
              color: '#DB0032',
            },
          ],
        },
        {
          label: 'Administered on',
          placeholder: 'MM / DD / YYYY',
          type: 'date',
          valueKey: 'recordedOn',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Administered by',
          placeholder: 'Administered by e.g. John Robertson',
          type: 'text',
          valueKey: 'recordedBy',
        },
        {
          label: 'Facility',
          placeholder: 'Facility name, e.g. Mayo Clinic',
          type: 'text',
          valueKey: 'facility',
        },
        {
          label: 'Reasons',
          placeholder: 'Add reasons',
          type: 'multiline',
          numberOfLines: 5,
          valueKey: 'reasons',
          width: 'fullWidth',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Reactions',
          placeholder: 'Add reactions related to immunization',
          type: 'multiple',
          numberOfLines: 5,
          valueKey: 'reaction',
          isConvertionRequired: true,
          conversionType: 'list',
          width: 'fullWidth',
        },
        {
          label: 'Note',
          placeholder: 'Add note related to immunization',
          type: 'multiline',
          numberOfLines: 4,
          valueKey: 'note',
          width: 'fullWidth',
        },
      ],
    },
  },
  smoking_status: {
    title: 'Social history',
    resourceName: 'social history',
    loadingText: 'social history',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/socialhistory/socialhistory-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by social history code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '280',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Final',
              value: 'final',
              iconColor: '#007D32',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: [
                'entered-in-error',
                'registered',
                'preliminary',
                'amended',
                'corrected',
                'cancelled',
                'unknown',
              ],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Onset time',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'onsetTime',
          resultKey: 'valueString',
        },
        {
          headerName: 'Name',
          type: 'nameWithCode',
          dataColForCode: 'code.coding.0.code',
          dataColForName: 'code.coding.0.display',
        },
        {
          headerName: 'Recorded by',
          type: 'string',
          dataCol: 'issued',
        },
        {
          headerName: 'Frequency',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'frequency',
          resultKey: 'valueString',
          type: 'filter',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Social history details',
      loadingText: 'social history',
      categoryNameForMarkAsRestricted: 'social history',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'code.coding.0.display',
          key2: 'code.coding.0.code',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
          },
          {
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recordedOn',
            resultKey: 'valueString',
            type: 'filter',
            label: 'Recorded on',
          },
          {
            key: 'issued',
            type: 'string',
            label: 'Recorded by',
          },
          {
            label: 'Frequency',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'frequency',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Code',
            key: 'code.coding.0.code',
            type: 'string',
          },
          {
            label: 'Onset time',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'onsetTime',
            resultKey: 'valueString',
            type: 'filter',
          },
          {
            label: 'Note',
            width: 'fullWidth',
            type: 'objectList',
            source: 'note',
            resultKey: 'text',
          },
          {
            label: 'Documents',
            key: 'docArn',
            type: 'document',
            width: 'fullwidth',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: false,
        },
      },
    },
  },
  medications: {
    title: 'Medications',
    resourceName: 'medication',
    loadingText: 'medications',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/medications/medications-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by medication code',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '265',
        addDebounce: true,
      },
      queryResourceTypes: ['medicationRequest', 'medicationStatement'],
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Active',
              value: 'active',
              iconColor: '#036FCB',
            },
            {
              label: 'Inactive',
              value: 'inactive',
              iconColor: '#DB0032',
              mappingList: ['stopped', 'completed', 'cancelled'],
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['on-hold', 'entered-in-error', 'draft', 'unknown'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          dataCol: 'authoredOn',
          type: 'string',
        },
        {
          headerName: 'Medication',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'medicationCodeName',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Frequency',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'frequency',
          resultKey: 'valueString',
        },
        {
          headerName: 'Dosage',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dosage',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Medication details',
      loadingText: 'medications',
      categoryNameForMarkAsRestricted: 'medication',
      medicationRequest: {
        detailsTable: {
          header: {
            template: '${value}',
            key1: 'medicationCodeableConcept.coding.0.display',
            source: 'extension',
            filterVal: 'name',
            filterKey: 'url',
            resultKey: 'valueString',
          },
          cols: [
            {
              label: 'Data source',
              type: 'pill',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'dataSource',
              resultKey: 'valueString',
            },
            {
              label: 'Status',
              type: 'dotWithStatusfromdiffloc',
              source1: 'status',
              source2: 'extension',
              filterKey: 'url',
              filterVal: 'statusColor',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'status',
            },
            {
              label: 'Recorded on',
              key: 'authoredOn',
              type: 'string',
              requestKeyForSelfReporting: 'autheredOn',
            },
            {
              label: 'Type of medication',
              type: 'filter',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'type',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'medicationType',
            },
            {
              label: 'Dosage',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'dosage',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'dosage',
            },
            {
              label: 'Recorded by',
              key: 'performer.display',
              type: 'string',
              requestKeyForSelfReporting: 'recordedBy',
            },
            {
              label: 'Prescriber',
              key: 'recorder.display',
              type: 'string',
              requestKeyForSelfReporting: 'prescriber',
            },
            {
              label: 'Code',
              type: 'string',
              requestKeyForSelfReporting: 'code',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'code',
              resultKey: 'valueString',
            },
            {
              label: 'Start date',
              type: 'filter',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'startDate',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'startDate',
            },
            {
              label: 'End date',
              type: 'filter',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'endDate',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'endDate',
            },
            {
              label: 'Ingredients',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'ingredients',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'ingredients',
            },
            {
              label: 'Frequency',
              type: 'string',
              requestKeyForSelfReporting: 'frequency',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'frequency',
              resultKey: 'valueString',
            },
            {
              label: 'Manufacturer',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'manufacturer',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'manufacturer',
            },
            {
              label: 'Requested by',
              key: 'requestor.display',
              type: 'string',
              requestKeyForSelfReporting: 'requestedBy',
            },
            {
              label: 'Documents',
              type: 'document',
              key: 'docArn',
              requestKeyForSelfReporting: 'documents',
              width: 'fullwidth',
            },
          ],
          options: {
            markRestricted: true,
            isHistoricalCardEnable: true,
          },
        },
        list: {
          mainHeader: 'Dose',
          header1: 'No of pills',
          value1: '',
          objectPath1: 'timing.repeat.frequency',
          header2: 'Time',
          value2: '',
          objectPath2: 'timing.repeat.timeOfDay.0',
          header3: 'Note',
          value3: '',
          objectPath3: 'text',
        },
        buttonDetails: {
          headerName: 'Medication adherence',
          type: 'button',
          dataCol: '',
        },
      },
      medicationStatement: {
        detailsTable: {
          header: {
            template: '${value}',
            key1: 'medicationCodeableConcept.coding.0.display',
            source: 'extension',
            filterVal: 'name',
            filterKey: 'url',
            resultKey: 'valueString',
          },
          cols: [
            {
              label: 'Data source',
              type: 'pill',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'dataSource',
              resultKey: 'valueString',
            },
            {
              label: 'Status',
              type: 'dotWithStatusfromdiffloc',
              source1: 'status',
              source2: 'extension',
              filterKey: 'url',
              filterVal: 'statusColor',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'status',
            },
            {
              label: 'Recorded on',
              type: 'filter',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'recordedOn',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'recordedOn',
            },
            {
              label: 'Type of medication',
              type: 'filter',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'typeOfMedication',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'medicationType',
            },
            {
              label: 'Dosage',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'dosage',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'dosage',
            },
            {
              label: 'Recorded by',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'recordedBy',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'recordedBy',
            },
            {
              label: 'Prescriber',
              type: 'string',
              key: 'informationSource.display',
              requestKeyForSelfReporting: 'prescriber',
            },
            {
              label: 'Code',
              type: 'string',
              requestKeyForSelfReporting: 'code',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'code',
              resultKey: 'valueString',
            },
            {
              label: 'Start date',
              type: 'date',
              key: 'effectivePeriod.start',
              requestKeyForSelfReporting: 'startDate',
            },
            {
              label: 'End date',
              type: 'date',
              key: 'effectivePeriod.end',
              requestKeyForSelfReporting: 'endDate',
            },
            {
              label: 'Ingredients',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'ingredients',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'ingredients',
            },
            {
              label: 'Frequency',
              type: 'string',
              requestKeyForSelfReporting: 'frequency',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'frequency',
              resultKey: 'valueString',
            },
            {
              label: 'Manufacturer',
              type: 'string',
              source: 'extension',
              filterKey: 'url',
              filterVal: 'manufacturer',
              resultKey: 'valueString',
              requestKeyForSelfReporting: 'manufacturer',
            },
            {
              label: 'Requested by',
              type: 'string',
              key: 'informationSource.display',
              requestKeyForSelfReporting: 'requestedBy',
            },
            {
              label: 'Documents',
              type: 'document',
              key: 'docArn',
              requestKeyForSelfReporting: 'documents',
              width: 'fullwidth',
            },
          ],
          options: {
            markRestricted: true,
            isHistoricalCardEnable: true,
          },
        },
        list: {
          mainHeader: 'Dose',
          header1: 'No of pills',
          value1: '',
          objectPath1: 'timing.repeat.frequency',
          header2: 'Time',
          value2: '',
          objectPath2: 'timing.repeat.timeOfDay.0',
          header3: 'Note',
          value3: '',
          objectPath3: 'text',
        },
        buttonDetails: {
          headerName: 'Medication adherence',
          type: 'button',
          dataCol: '',
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'medications',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recorded_on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          filterKey: 'url',
          filterVal: 'data_source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
        },
      ],
    },
    selfReportingSection: {
      resourceType: 'Medications',
      steps: ['Add data', 'Documents'],
      resourceNameForSelfReportingFileUpload: 'MedicationRequest',
      convertToLowerCaseList: ['status', 'medicationName', 'medicationType'],
      formElementList: [
        {
          label: 'Name of medication',
          type: 'text',
          placeholder: 'Name of medication',
          valueKey: 'medicationName',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Type of medication',
          placeholder: 'Enter type of medication e.g. powder, tablets, capsule, etc',
          type: 'text',
          valueKey: 'medicationType',
        },
        {
          label: 'Code',
          placeholder: 'Enter medication code',
          type: 'text',
          valueKey: 'code',
        },
        {
          label: 'Frequency',
          type: 'dropdown',
          placeholder: 'Select frequency',
          valueKey: 'frequency',
          options: [
            {
              label: 'Once a day',
              value: 1,
            },
            {
              label: 'Twice a day',
              value: 2,
            },
            {
              label: 'Thrice a day',
              value: 3,
            },
            {
              label: 'Four times a day',
              value: 4,
            },
          ],
        },
        {
          label: 'Dosage',
          placeholder: 'Enter medication dosage e.g. 10 mg, 5 mg, etc',
          type: 'text',
          valueKey: 'dosage',
        },
        {
          label: 'Start date',
          type: 'date',
          placeholder: 'MM / DD / YYYY',
          valueKey: 'startDate',
          required: true,
          errorMsg: 'This field is a required field!',
          formatting: '(val) => {\n return val && new Date(val).toUTCString();\n }',
        },
        {
          label: 'Recorded by',
          placeholder: 'Recorded by e.g. John Robertson',
          type: 'text',
          valueKey: 'recordedBy',
        },
        {
          label: 'Status',
          placeholder: 'Select status',
          type: 'dropdown',
          valueKey: 'status',
          required: true,
          errorMsg: 'This field is a required field!',
          isMappingRequired: true,
          conversionFromValueToLabel: ['status'],
          options: [
            {
              value: 'active',
              label: 'Active',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              value: 'on-hold',
              label: 'On hold',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'cancelled',
              label: 'Cancelled',
              statusIcon: true,
              color: '#DB0032',
            },
            {
              value: 'completed',
              label: 'Completed',
              statusIcon: true,
              color: '#148E3C',
            },
            {
              value: 'entered-in-error',
              label: 'Entered in error',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'stopped',
              label: 'Stopped',
              statusIcon: true,
              color: '#737373',
            },
            {
              value: 'draft',
              label: 'Draft',
              statusIcon: true,
              color: '#F2994A',
            },
            {
              value: 'unknown',
              label: 'Unknown',
              statusIcon: true,
              color: '#737373',
            },
          ],
        },
        {
          label: 'Prescriber',
          placeholder: 'Enter name of provider',
          type: 'text',
          valueKey: 'prescriber',
        },
        {
          label: 'Manufacturer',
          type: 'text',
          valueKey: 'manufacturer',
          placeholder: 'Enter name of manufacturer',
        },
        {
          label: 'Ingredients',
          type: 'text',
          valueKey: 'ingredients',
          placeholder: 'Enter name of ingredients',
        },
      ],
      lists: [
        {
          header: 'No of pills',
          value: '',
          objectPath: 'dosageInstruction.0.timing.repeat.frequency',
        },
        {
          header: 'Time',
          value: '',
          objectPath: 'dosageInstruction.0.timing.repeat.frequency',
        },
        {
          header: 'Note',
          value: '',
          objectPath: 'note.0.text',
        },
      ],
    },
  },
  appointments: {
    title: 'Appointments',
    resourceName: 'appointments',
    loadingText: 'Appointments',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/careplan/careplan-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by Appointment',
        requestKey: 'search_parameter',
        deviceType: 'all',
        addDebounce: true,
        width: '260',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: true,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 months',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Visit Type :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Virtual Visit',
              value: 'active',
            },
            {
              label: 'In Clinic Visit',
              value: 'completed',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Proposed',
              value: 'active',
              iconColor: '#FB8900',
            },
            {
              label: 'Booked',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Fulfilled',
              value: 'revoked',
              iconColor: '#007D32',
            },
            {
              label: 'No Show',
              value: 'on-hold',
              iconColor: 'gray',
            },
            {
              label: 'Checked In',
              value: 'entered-in-error',
              iconColor: '#036FCB',
            },
            {
              label: 'Pending',
              value: 'draft',
              iconColor: '#FB8900',
            },
            {
              label: 'Arrived',
              value: 'others',
              iconColor: '#007D32',
            },
            {
              label: 'Entered In Error',
              value: 'others',
              iconColor: 'gray',
            },
            {
              label: 'Waitlisted',
              value: 'others',
              iconColor: '#FB8900',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'recorded_on',
          resultKey: 'valueString',
        },
        // {
        //   headerName: 'Time',
        //   type: 'filter',
        //   source: 'extension',
        //   filterKey: 'url',
        //   filterVal: 'time',
        //   resultKey: 'valueString',
        // },
        {
          headerName: 'Provider',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'provider',
          resultKey: 'valueString',
        },
        {
          headerName: 'Type of visit',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'type_of_visit',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['status_color', 'status'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Facility',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'facility',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      loadingText: 'appointments',
    },
  },
  family_member_history: {
    title: 'Family history',
    resourceName: 'family history',
    loadingText: 'family history',
    bgColorForInitial: '#00A0DF',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Partial',
              value: 'partial',
              iconColor: '#F2994A',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Unknown',
              value: 'unknown',
              mappingList: ['entered-in-error', 'health-unknown'],
              iconColor: '#737373',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'recorded_on',
          resultKey: 'valueString',
        },
        {
          headerName: 'Name',
          type: 'stringWithFirstLetter',
          dataColForName: 'name',
          dataCol: 'name',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          dataCol: 'status',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'statusColor',
          resultKey: 'valueString',
        },
        {
          headerName: 'Relationship',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'relationship',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dataSource',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Family member history details',
      loadingText: 'family history',
      categoryNameForMarkAsRestricted: 'family member history',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'name',
          key2: 'name',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'dataSource',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dotWithStatusfromdiffloc',
            source1: 'status',
            source2: 'extension',
            filterKey: 'url',
            filterVal: 'statusColor',
            resultKey: 'valueString',
          },
          {
            label: 'Recorded on',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recorded_on',
            resultKey: 'valueString',
          },
          {
            label: 'Gender',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'gender',
            resultKey: 'valueString',
          },
          {
            label: 'Relationship',
            type: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'relationship',
            resultKey: 'valueString',
          },
        ],
        valueAndDateTable: [
          {
            label: 'Condition',
            labelKey: 'condition',
          },
          {
            label: 'Start date',
            labelKey: 'date',
          },
          {
            label: 'Reason for history',
            labelKey: 'reasonForHistory',
            width: 'fullWidth',
          },
          {
            label: 'Note',
            labelKey: 'note',
            width: 'fullWidth',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: false,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'family history',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recorded_on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          filterKey: 'url',
          filterVal: 'dataSource',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
        },
      ],
    },
  },
  coverage: {
    title: 'Coverage',
    resourceName: 'coverage',
    icon: '',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: true,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by coverage policy number',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '260',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
              iconColor: 'inherit',
            },
            {
              label: 'Partial',
              value: 'partial',
              iconColor: '#F2994A',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'Unknown',
              value: 'unknown',
              mappingList: ['entered-in-error', 'health-unknown'],
              iconColor: '#737373',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Coverage',
          type: 'string',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'coverage',
          resultKey: 'valueString',
        },
        {
          headerName: 'Policy number',
          type: 'string',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'policy_number',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['status_color', 'status'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Start date',
          type: 'date',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'start_date',
          resultKey: 'valueString',
        },
        {
          headerName: 'Expiry date',
          type: 'date',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'expiry_date',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'pillString',
          source: 'identifier',
          filterKey: 'system',
          filterVal: 'data_source',
          resultKey: 'value',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Coverage',
      categoryNameForMarkAsRestricted: 'coverage',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'class.name',
        },
        cols: [
          {
            label: 'Data source',
            type: 'pill',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'data_source',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['status_color', 'status'],
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'status',
          },
          {
            label: 'Recorded on',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'recorded_on',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'recordedOn',
          },
          {
            label: 'Policy number',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'policy_number',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'policyNumber',
          },
          {
            label: 'Subscriber id',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'subscriber_id',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'subscrberId',
          },
          {
            label: 'Subscriber name',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'subscriber_name',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'subscrberName',
          },
          {
            label: 'Relationship',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'relationship',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'relationship',
          },
          {
            label: 'Plan type',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'plan_type',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'planType',
          },
          {
            label: 'Plan name',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'plan_name',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'planName',
          },
          {
            label: 'Plan id',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'plan_id',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'planId',
          },
          {
            label: 'Coverage type',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'coverage_type',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'coverageType',
          },
          {
            label: 'Issuer organization',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'issuer_organization',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'issuerOrganization',
          },
          {
            label: 'Start date',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'start_date',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'startDate',
          },
          {
            label: 'End date',
            type: 'date',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'end_date',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'endDate',
          },
          {
            label: 'Total amount',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'total_amount',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'totalAmount',
          },
          {
            label: 'Contact name',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'contact_name',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'contactName',
          },
          {
            label: 'Contact phone number',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'contact_phone_number',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'contactPhoneNumber',
          },
          {
            label: 'Contact address',
            type: 'string',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'contact_address',
            resultKey: 'valueString',
            requestKeyForSelfReporting: 'contactAddress',
            width: 'fullWidth',
          },
          {
            label: 'Note',
            type: 'string',
            key: 'note.0.text',
            requestKeyForSelfReporting: 'note',
            width: 'fullwidth',
          },
          {
            label: 'Documents',
            type: 'document',
            key: 'docArn',
            requestKeyForSelfReporting: 'documents',
            width: 'fullwidth',
          },
        ],
        options: {
          markRestricted: true,
          isHistoricalCardEnable: true,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: 'code.coding.0.display',
      categoryName: 'coverage',
      cardFields: [
        {
          headerName: 'Recorded on',
          type: 'filter',
          dataCol: 'recordedOn',
          filterKey: 'url',
          filterVal: 'recorded_on',
          source: 'extension',
          objectKeyForUI: 'recordedOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Data source',
          type: 'filter',
          filterKey: 'url',
          filterVal: 'data_source',
          key: 'data_source',
          source: 'extension',
          objectKeyForUI: 'dataSource',
          resultKey: 'valueString',
        },
      ],
    },
    selfReportingSection: {
      resourceNameForSelfReportingFileUpload: 'Coverage',
      resourceType: 'coverage',
      convertToLowerCaseList: ['status'],
      formElementList: [
        {
          label: 'Policy number',
          placeholder: 'Enter policy number',
          type: 'text',
          valueKey: 'policyNumber',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Subscriber id',
          placeholder: 'Enter subscriber ID',
          type: 'text',
          valueKey: 'subscriberId',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Subscriber name',
          placeholder: 'Enter subscriber name',
          type: 'text',
          valueKey: 'subscriber',
        },
        {
          label: 'Relationship',
          type: 'dropdown',
          placeholder: 'Select relationship',
          valueKey: 'relationship',
          options: [
            {
              label: 'Child',
              value: 'Child',
            },
            {
              label: 'Parent',
              value: 'Parent',
            },
            {
              label: 'Spouse',
              value: 'Spouse',
            },
            {
              label: 'Common',
              value: 'Common',
            },
            {
              label: 'Other',
              value: 'Other',
            },
            {
              label: 'Self',
              value: 'Self',
            },
            {
              label: 'Injured',
              value: 'Injured',
            },
          ],
        },
        {
          label: 'Plan type',
          placeholder: 'Enter plan type',
          type: 'text',
          valueKey: 'planType',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Plan name',
          placeholder: 'Enter plan name',
          type: 'text',
          valueKey: 'healthPlanName',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Plan id',
          placeholder: 'Enter plan ID',
          type: 'text',
          valueKey: 'planId',
        },
        {
          label: 'Coverage type',
          placeholder: 'Enter coverage type',
          type: 'text',
          valueKey: 'coverageType',
        },
        {
          label: 'Status',
          placeholder: 'Select status',
          type: 'dropdown',
          valueKey: 'status',
          required: true,
          errorMsg: 'This field is a required field!',
          options: [
            {
              key: 0,
              label: 'Active',
              value: 'active',
              statusIcon: true,
              color: '#036FCB',
            },
            {
              key: 1,
              label: 'Draft',
              value: 'draft',
              statusIcon: true,
              color: '#F2994A',
            },
            {
              key: 2,
              label: 'Cancelled',
              value: 'cancelled',
              statusIcon: true,
              color: '#DB0032',
            },
            {
              key: 3,
              label: 'Entered in error',
              value: 'entered-in-error',
              statusIcon: true,
              color: '#737373',
            },
          ],
        },
        {
          label: 'Start date',
          placeholder: 'MM / DD / YYYY',
          type: 'date',
          valueKey: 'startDate',
          required: true,
          errorMsg: 'This is a required field. Start date cannot be after the end date',
          allowFutureDates: true,
        },
        {
          label: 'End date',
          placeholder: 'MM / DD / YYYY',
          type: 'date',
          valueKey: 'endDate',
          required: true,
          errorMsg: 'This is a required field. End date cannot be before the start date',
          allowFutureDates: true,
        },
        {
          label: 'Total amount (in $)',
          placeholder: 'Enter total cost',
          type: 'text',
          valueKey: 'totalCost',
          regex: '^[0-9]+(.[0-9]+)?$',
          errorMsg: 'Please enter a valid amount',
        },
        {
          label: 'Contact name',
          placeholder: 'Enter contact name',
          type: 'text',
          valueKey: 'contactName',
        },
        {
          label: 'Contact phone number',
          placeholder: 'Enter contact phone number',
          type: 'text',
          valueKey: 'contactPhoneNumber',
          regex: '^[0-9]{10}$',
          errorMsg: 'Please enter a valid 10 digit phone number',
        },
        {
          label: 'Issuer organization',
          placeholder: 'Enter issuer organization',
          type: 'text',
          valueKey: 'issuerOrganization',
          required: true,
          errorMsg: 'This field is a required field!',
        },
        {
          label: 'Contact address',
          placeholder: 'Enter contact address',
          type: 'multiline',
          valueKey: 'contactAddress',
          numberOfLines: 5,
        },
        {
          label: 'Note',
          placeholder: 'Enter note',
          type: 'multiline',
          valueKey: 'note',
          numberOfLines: 5,
        },
      ],
    },
  },
  assessments_dashboard: {
    title: 'Assessments',
    resourceName: 'assessments',
    loadingText: 'Assessments',
    useResponseGrid: true,
    disableButton: {
      type: 'dotValue',
      source: 'extension',
      filterKey: 'url',
      filterVals: ['status_color', 'status'],
      resultKey: 'valueString',
      comparatorValues: ['expired', 'revoked', 'completed'],
      buttonTitleView: 'View',
      buttonTitleFillNow: 'Fill now',
    },
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/assessments/assessment-web.svg',
    dashboard: {
      reloadOnScroll: true,
      selfReporting: {
        enabled: false,
        deviceType: 'all',
      },
      search: {
        isEnabled: true,
        placeholder: 'Search by assessment name',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '300',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Assigned date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on time:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          modalTitle: 'Filter based on status:',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Completed',
              value: 'completed',
              iconColor: '#007D32',
            },
            {
              label: 'In progress',
              value: 'in-progress',
              iconColor: '#036FCB',
            },
            {
              label: 'Pending',
              value: 'pending',
              iconColor: '#F2994A',
              mappingList: ['DRAFT'],
            },
            {
              label: 'Expired',
              value: 'expired',
              iconColor: '#DB0032',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Assigned date',
          type: 'string',
          dataCol: 'assessmentResponse.assessmentDisplay.assignedDate',
        },
        {
          headerName: 'Name',
          type: 'string',
          dataCol: 'assessmentResponse.assessmentMeta.assessmentName',
        },
        {
          headerName: 'Question count',
          type: 'string',
          dataCol: 'assessmentResponse.assessmentMeta.numberOfQuestions',
        },
        {
          headerName: 'Expiry date',
          type: 'string',
          dataCol: 'assessmentResponse.assessmentDisplay.expiredDate',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['status_color', 'status'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Organization name',
          type: 'pillString',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'orgName',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Assessment details',
      loadingText: 'Assessment',
      categoryNameForMarkAsRestricted: '',
      detailsTable: {
        header: {
          template: '',
          key1: '',
          key2: '',
        },
        cols: [],
        options: {
          markRestricted: false,
          isHistoricalCardEnable: false,
        },
      },
    },
    historicalRecordsCardFields: {
      titleSource: '',
      categoryName: '',
      cardFields: [],
    },
    selfReportingSection: {
      resourceType: 'assessments',
      resourceNameForSelfReportingFileUpload: '',
      steps: [],
      formElementList: [],
    },
  },
  idleTime: 3600000,
  checkSessionTime: 7200000,
  documentCategories: [
    { label: 'Medical', value: 'medical' },
    { label: 'Financial', value: 'financial' },
    { label: 'Care', value: 'care' },
    { label: 'Legal', value: 'legal' },
  ],
  support_history: {
    title: 'Support history',
    resourceName: 'support',
    loadingText: 'support history',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcng9IjEwIiBmaWxsPSIjMDBBMERGIi8+DQo8cGF0aCBkPSJNMjkuNjI1IDI0LjM3NUwyNi4yNSAyMy45Mzc1QzI1LjQzNzUgMjMuODc1IDI0LjYyNSAyNC4xMjUgMjQuMDYyNSAyNC43NUwyMS42MjUgMjcuMTg3NUMxNy44MTI1IDI1LjI1IDE0Ljc1IDIyLjE4NzUgMTIuODEyNSAxOC4zNzVMMTUuMzEyNSAxNS45Mzc1QzE1Ljg3NSAxNS4zNzUgMTYuMTI1IDE0LjU2MjUgMTYuMDYyNSAxMy43NUwxNS42ODc1IDEwLjM3NUMxNS41IDkgMTQuMzc1IDggMTMgOEgxMC42ODc1QzkuMTg3NSA4IDcuOTM3NSA5LjI1IDguMDYyNSAxMC43NUM4Ljc1IDIyLjE4NzUgMTcuODc1IDMxLjI1IDI5LjI1IDMxLjkzNzVDMzAuNzUgMzIuMDYyNSAzMiAzMC44MTI1IDMyIDI5LjMxMjVWMjdDMzIgMjUuNjI1IDMxIDI0LjUgMjkuNjI1IDI0LjM3NVoiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==',
    dashboard: {
      reloadOnScroll: false,
      needToFormat: true,
      breadCrumbProperties: [
        {
          label: 'Utilities',
          route: '/utilities',
        },
        {
          label: 'Support',
          route: '/utilities/support',
        },
        {
          label: 'Support history',
        },
      ],
      filters: [
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Pending',
              value: 'pending',
              iconColor: '#F2994A',
            },
            {
              label: 'Resolved',
              value: 'resolved',
              iconColor: '#007D32',
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Request id',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'requestId',
          resultKey: 'valueString',
        },
        {
          headerName: 'Created on',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'createdOn',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['status_color', 'status'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Category',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'category',
          resultKey: 'valueString',
        },
        {
          headerName: 'Resolved by',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'resolvedBy',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Support history',
      loadingText: 'support history',
      categoryNameForMarkAsRestricted: 'support_history',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'code.coding.0.display',
          key2: 'code.coding.0.code',
        },
        cols: [
          {
            label: 'Status',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['status_color', 'status'],
            resultKey: 'valueString',
          },
          {
            label: 'Documents',
            labelType: 'labelWithIcon',
            type: 'string',
            key: 'docArn',
            width: '',
          },
          {
            label: 'Support type',
            key: 'supportType',
            type: 'string',
          },
          {
            label: 'User text',
            key: 'description',
            type: 'string',
          },
          {
            label: 'Resolved by',
            key: 'resolvedBy',
            type: 'string',
          },
          {
            label: 'Comments',
            key: 'comments',
            type: 'string',
          },
        ],
        options: {
          markRestricted: false,
          isHistoricalCardEnable: false,
        },
      },
    },
  },
  prior_authorization: {
    title: 'Prior authorization',
    resourceName: 'prior_authorization',
    loadingText: 'prior authorization',
    icon: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/PriorAuthorization/priorAuthorization.svg',
    dashboard: {
      reloadOnScroll: true,
      needToFormat: true,
      search: {
        isEnabled: true,
        placeholder: 'Search by prior authorization',
        requestKey: 'search_parameter',
        deviceType: 'all',
        width: '280',
        addDebounce: true,
      },
      filters: [
        {
          label: 'Date :',
          defaultActiveIndex: 0,
          datePickerEnabled: false,
          filterType: 'dateFilter',
          requestKey: 'dateFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Past 1 month',
              value: '1M',
            },
            {
              label: 'Past 3 months',
              value: '3M',
            },
            {
              label: 'Past 6 months',
              value: '6M',
            },
          ],
        },
        {
          label: 'Status :',
          defaultActiveIndex: 0,
          filterType: 'statusFilter',
          requestKey: 'statusFilter',
          deviceType: 'all',
          options: [
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'In progress',
              value: 'in progress',
              iconColor: '#036FCB',
            },
            {
              label: 'Pending',
              value: 'draft',
              iconColor: '#FB8900',
            },
            {
              label: 'Approved',
              value: 'approved',
              iconColor: '#007D32',
            },
            {
              label: 'Rejected',
              value: 'rejected',
              iconColor: '#DB0032',
            },
            {
              label: 'Others',
              value: 'others',
              iconColor: '#737373',
              mappingList: ['draft', 'on-hold'],
            },
          ],
        },
      ],
      tableColumns: [
        {
          headerName: 'Name',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'name',
          resultKey: 'valueString',
        },
        {
          headerName: 'Status',
          type: 'dotValue',
          source: 'extension',
          filterKey: 'url',
          filterVals: ['status_color', 'status'],
          resultKey: 'valueString',
        },
        {
          headerName: 'Priority',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'priority',
          resultKey: 'valueString',
        },
        {
          headerName: 'Request date',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'requestDate',
          resultKey: 'valueString',
        },
        {
          headerName: 'Due date',
          type: 'filter',
          source: 'extension',
          filterKey: 'url',
          filterVal: 'dueDate',
          resultKey: 'valueString',
        },
        {
          headerName: '',
          type: 'button',
          dataCol: '',
        },
      ],
    },
    details: {
      show: true,
      header: 'Prior authorization',
      loadingText: 'Prior authorization',
      categoryNameForMarkAsRestricted: 'prior_authorization',
      detailsTable: {
        header: {
          template: '${value}',
          key1: 'code.coding.0.display',
          key2: 'code.coding.0.code',
        },
        cols: [
          {
            label: 'Service (CPT code)',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'cpt_code',
            resultKey: 'valueString',
          },
          {
            label: 'Requesting provider',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'requesting_provider',
            resultKey: 'valueString',
          },
          {
            label: 'Request priority',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'request_priority',
            resultKey: 'valueString',
          },
          {
            label: 'Requested date',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'requested_date',
            resultKey: 'valueString',
          },
          {
            label: 'Due date',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'due_date',
            resultKey: 'valueString',
          },
          {
            label: 'Status',
            type: 'dot',
            source: 'extension',
            filterKey: 'url',
            filterVals: ['status_color', 'status'],
            resultKey: 'valueString',
          },
          {
            label: 'Service location',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'service_location',
            resultKey: 'valueString',
          },
          {
            label: 'Service start date',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'service_start_date',
            resultKey: 'valueString',
          },
          {
            label: 'Service end date',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'service_end_date',
            resultKey: 'valueString',
          },
          {
            label: 'Diagnosis',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'diagnosis',
            resultKey: 'valueString',
          },
          {
            label: 'Units',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'units',
            resultKey: 'valueString',
          },
          {
            label: 'Prior auth ID',
            type: 'string',
            subType: 'filter',
            source: 'extension',
            filterKey: 'url',
            filterVal: 'prior_auth_id',
            resultKey: 'valueString',
          },
          {
            label: 'Documents',
            type: 'document',
            key: 'docArn',
            width: 'fullwidth',
          },
          {
            label: 'Summary',
            type: 'string',
            key: 'stage.0.summary.coding.0.display',
            width: 'fullwidth',
          },
          {
            label: 'Note',
            type: 'string',
            key: 'note.0.text',
            width: 'fullwidth',
          },
        ],
      },
    },
  },

  support: {
    required: true,
    filters: [
      {
        label: 'Request type:',
        defaultActiveIndex: 0,
        filterType: 'categoryFilter',
        requestKey: 'categoryFilter',
        deviceType: 'all',
        options: [
          {
            label: 'Select request type',
            value: 'Select request type',
          },
          {
            label: 'Benefits Enquiry',
            value: 'Benefits enquiry',
          },
          {
            label: 'Notification of Life Event',
            value: 'Notification of life event',
          },
          {
            label: 'Information Requests',
            value: 'Information Requests',
          },
          {
            label: 'Claims Assistance',
            value: 'Claims Assistance',
          },
          {
            label: 'Policy Changes',
            value: 'Policy Changes',
          },
          {
            label: 'Coverage Questions',
            value: 'Coverage Questions',
          },
          {
            label: 'Billing and Payments',
            value: 'Billing and Payments',
          },
          {
            label: 'Provider Network',
            value: 'Provider Network',
          },
          {
            label: 'Appeals and Reviews',
            value: 'Appeals and Reviews',
          },
          {
            label: 'Others',
            value: 'Others',
          },
        ],
      },
    ],
  },
  "HOME_SCREEN_LOGO": "https://mpowered-assets.s3.us-east-2.amazonaws.com/Patient_Advocates.png",
  "LOGIN_SCREEN_LOGO": "https://mpowered-assets.s3.us-east-2.amazonaws.com/Patient_Advocates.png",
  texts: {
    Language: ['en', 'es_US'],
    en_US: {
      COVERAGE_SCREEN: {
        itemList: [
          {
            toolTipText: 'Change plan',
            content: 'Change plan',
            subContent: 'Switch to a best plan',
          },
        ],
      },
      SIGNUP: {
        CAROUSEL: {
          CAROUSEL_TEXT1: {
            heading: 'All your',
            title: 'healthcare',
            text: ' in',
            text1: 'one app',
          },
          CAROUSEL_TEXT2: {
            heading: 'Get your', 
            title: 'health',
            text: ' records',
            text1: 'from your',
            text2: 'plans & doctors',
          },
          CAROUSEL_TEXT3: {   
            heading: 'Share',
            title: 'your health records ',
            text: 'with',
            text1: 'family, caregivers',
            text2: '& doctors',
          },
          CAROUSEL_TEXT4: {
            heading: 'Shop ', 
            title: ' for ',
            text: 'healthcare ',
            text1: 'products ',
            text2: '& services',
          },
        },
        SIGNUP_SCREEN: {
          EMAIL_OR_PHONE: 'Mobile number/email',
          CREATE_PASSWORD: ' Create password',
          CREATE_ACCOUNT: 'Create your account',
          CONTINUE: 'Continue',
          ONE_APP:
            ' One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
          WELCOME: 'Welcome!',
          CREATE_MY_ACCOUNT: 'Create my account',
          EMAIL: 'Email',
          EMAIL_OR_PHONE_PLACEHOLDER: 'Enter your mobile number or email',
          PASSWORD: 'Create password',
          PASSWORD_PLACEHOLDER: 'Password (8+ characters)',
          CONFIRM_PASSWORD: 'Confirm password',
          CHANGE_PASSWORD: 'Change password',
          REFERRAL: 'Have a referral/promo code? (Optional)',

          HAVE_ACCOUNT: 'Have an account?',
          LOGIN: 'Log in',
          OR: ' or ',
          DOWNLOAD: 'Download our app from the store today on ',
          DEMO_DOWNLOAD: "Download Mpowered Health for free on ",
          IOS: 'iOS',
          AND: ' and ',
          ANDROID: 'Android',
          LABEL: `Don't have an account?`,
          HEADING: 'Get Mpowered with us.',
          SIGN_UP_NOW: 'Sign up now.',
          BODY: 'Sign up',
          BTN_TEXT_APPLY: 'Apply',
          BTN_TEXT_SIGNUP: 'Sign up',
          EXISTING_USER_TEXT: 'Existing user?',
          LOGIN_TEXT: ' Log in',
          PASSWORD_STRENGTH_TEXT: 'Password strength - ',
          ERRORLOG_USER_EXIST_MSG: 'User exists with same username',
          LOOKS_GOOD: 'Looks good',
          PASSWORD_INFO: 'Must include an upper case, lower case , number and symbol.',
          LEN_ERR_8: 'At least have 8 characters',
          OR_CONTINUE_WITH: 'Or continue with',
          EMAIL_EXIST_MESSAGE: 'Email already exists... Try log in',
          PHONE_EXIST_MESSAGE: 'Mobile number already exists... Try log in',
          EMAIL_OR_PHONE_ERROR_MESSAGE: 'Enter a valid mobile number or email',
          MOBILE_OR_EMAIL_REQUIRED: 'Mobile number or email required',
          BY_CREATING: 'By creating an account you are accepting the ',
          TS: 'terms of service ',
          PP: ' privacy policy',
          OTP_SENT: 'OTP sent successfully ',
          EMAIL_EXIST_MEG: 'Email already exists',
          MOBILE_EXIST_MES: 'Mobile number already exists',
        },
        SOCIAL_SIGN_IN: {
          APPLE: 'consumer-apple',
          GOOGLE: 'consumer-google',
          FACEBOOK: 'consumer-facebook',
          LINKEDIN: 'consumer-linkedin',
          APPLE_BTN: 'Apple',
          GOOGLE_BTN: 'Google',
          FACEBOOK_BTN: 'Facebook',
          LINKEDIN_BTN: 'Linkedin',
        },
        ONE_TIME_ACCESS_STATES: {
          ACTIVE: 'ACTIVE',
          EXPIRED: 'EXPIRED',
          INVALID: 'INVALID',
        },
        DEVICE_TYPE: {
          ANDROID: 'WEB_BROWSER_IN_ANDROID',
          IOS: 'WEB_BROWSER_IN_IOS',
          WEB: 'WEB_BROWSER',
        },
        EDIT_MOBILE_EMAIL_MODAL: {
          HEADING: 'Edit mobile number/email',
          MOBILE_EMAIL_LABEL: 'Mobile number/email',
          MOBILE_EMAIL_PLACEHOLDER: 'Enter your mobile number or email',
          SAVE_BUTTON: 'Save',
          REQUIRED_ERROR: 'Mobile number or email required',
          ERROR: 'Enter a valid mobile number or email',
          EMAIL_EXIST_MEG: 'Email already exists',
          MOBILE_EXIST_MES: 'Mobile number already exists',
        },
      },
      LOGIN_SCREEN: {
        LOG_IN: {
          PREFERRED_LANGUAGE: 'Preferred language',
          ONE_APP:
            'One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
          WELCOME_BACK: 'Welcome back!',
          LOGIN: 'Log in',
          EMAIL_PHONE: 'Email/mobile number',
          EMAIL_PHONE_REQUIRED_ERROR: 'Email/mobile number is required',
          PASSWORD_REQUIRED_ERROR: 'Password is required',
          OR_CONTINUE_WITH: 'Or continue with',
          EMAIL_PHONE_PLACEHOLDER: 'Please enter your email or mobile number',
          PASSWORD: 'Password',
          PASSWORD_PLACEHOLDER: 'Password (8+ characters)',
          FORGOT_PASS: 'Forgot password?',
          NOT_HAVE_ACCOUNT: 'Don’t have an account?',
          SIGN_UP: 'Sign up',
          OR: 'or',
          DOWNLOAD: 'Download our app from the store today on ',
          DEMO_DOWNLOAD: "Download Mpowered Health for free on ",
          IOS: 'iOS',
          AND: ' and ',
          ANDROID: 'Android',
          LOADING_TEXT: 'Logging in...',
          INVALID_CREDS_ERROR_MESSAGE: 'Invalid user credentials',
          ACCOUNT_DOES_NOT_EXISTS: 'This account does not exists. Create a new account.',
          ACCOUNT_DISABLED:
            'Account is disabled. Please contact support@mpoweredhealth.com to enable the account.',
          ALL_YOUR: 'All your',
          HEALTHCARE: 'healthcare',
          IN_ONE_APP: 'in one app',
          ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully! Please login to continue.',
          MPOWERED_TEXT : 'powered by Mpowered health'
        },
        CAPTCH_VALIDATION: {
          ERROR_TEXT: "Captcha didn't match",
          VERIFIED_TEXT: 'Captcha verified',
          PLACEHOLDER: 'Please enter captcha value',
          HELPER_TEXT: 'Please verify captcha to proceed with log in',
          TEXTFIELD_LABEL: 'Type the code you see above',
        },
      },

      SYSTEMS: {
        MPOWERED_SYSTEM: 'https://mpoweredhealth.com/',
        CMS_SYSTEM: 'https://www.cms.gov/',
      },
      MPOWERED_INFERRED: {
        INFERRED_DATA: 'This data was inferred by Mpowered Health from <RESOURCE>',
        PARENT_RESOURCES: {
          CLAIMS: 'claims',
        },
      },
      HEADER: {
        ACCOUNT_DETAILS_LABELS: {
          COMPLETE_PROFILE_MESSAGE: 'Complete your profile and earn reward points',
          CANCEL_CHANGES: 'Cancel changes',
          CANCEL_PROFILE_MESSAGE:
            'Are you sure you want to cancel changes? You will not be able to avail rewards points.',
          COMPLETE_PROFILE: 'Complete profile',
          PERSONAL_DETAIL: 'Personal details',
          CONTACT_DETAILS: 'Contact details',
          CHANGES_APPLIED: 'Your changes have been successfully applied',
          SAVE_CHANGES: 'Save changes',
          LOADING: 'Loading account details, please wait...',
          ACCOUNT: 'Account details',
          PROFILE_UPDATED: 'PROFILE_UPDATED',
          EMAIL_ADDRESS: 'Email address',
          PRIMARY_EMAIL: 'Primary email',
          PRIMARY_EMAILS: 'Primary email',
          ADDING_PRIMARY_EMAIL: 'Adding primary email...',
          PHONE_NUMBER: 'Phone number',
          EDIT: 'Edit',
          ADD: 'Add',
          ADD_PRIMARY: 'Add primary',
          PHONE_NUMBER_NOT_VERIFIED: 'Phone number is not verified, please try again...',
          PHONE_NUMBER_EDIT: 'Phone number edited for the user',
          ADD_PRIMARY_MOBILE_NUMBER: 'Adding primary mobile number...',
          PHONE_NUMBER_ADDED: 'Phone number added for the user',
          ALREADY_EXIST: (name) => `${name} already exists`,
          SAME_ADDRESS: 'Same address is stored already',
          ADDRESS: 'Address',
          ADDING_PRIMARY_ADDRESS: 'primary address',
          ADDING_SECONDARY_ADDRESS: 'secondary address',
          VERIFICATION_RECORDS: 'Verification records not found',
          OTP_UNSUCCESS: 'OTP verification unsuccessful...',
          OTP_INCORRECT: 'OTP verification unsuccessful, please enter correct OTP.',
          OTP_SUCCESS: 'OTP verified successfully',
          ACCOUNT_SUCCESS: 'Account verified successfully',
          OTP_VERIFYING: 'Verifying OTP, please wait...',
          MAX_ATTEMPT: 'Maximum attempts reached',
          OTP_GENERATING: 'Generating OTP, please wait...',
          PROFILE_IMAGE: 'profile image',
          MAKE_PRIMARY_TO_SECONDARY: 'Make your primary to secondary',
          EDIT: 'Edit',
          ENTER_CODE: 'Enter code',
          ADD_SECONDARY: 'Add secondary',
          ADD_PRIMARY: 'Add primary',
          PRIMARY_EMAIL_LABEL: 'Primary email address',
          PRIMARY_EMAIL_PLACEHOLDER: 'nandini@gmail.com',
          SECONDARY_EMAIL_LABEL: 'Secondary email address',
          SECONDARY_EMAIL_PLACEHOLDER: 'nandini@gmail.com',
          PRIMARY_PHONE_NUMBER_LABEL: 'Primary phone number',
          PRIMARY_PHONE_NUMBER_PLACEHOLDER: 'Enter primary phone number',
          SECONDARY_PHONE_NUMBER_LABEL: 'Secondary phone number',
          SECONDARY_PHONE_NUMBER_PLACEHOLDER: 'Enter secondary phone number',
          ADDRESS_TYPE_LABEL: 'Address type',
          ADDRESS_TYPE_PLACEHOLDER: 'Enter address type e.g. home, office etc.',
          LINE1_LABEL: 'Address line 1',
          LINE1_PLACEHOLDER: '261 North university drive',
          LINE2_LABEL: 'Address line 2',
          LINE2_PLACEHOLDER: '262 South university drive',
          ZIP_CODE_LABEL: 'Zip code',
          ZIP_CODE_PLACEHOLDER: '33324',
          CITY_LABEL: 'City',
          CITY_PLACEHOLDER: 'Plantation',
          STATE_LABEL: 'State',
          STATE_PLACEHOLDER: 'Select state',
          MEMBER_IDCARD_HEADING: 'ID Card',
          MEMBER_BENEFITS_HEADING: 'Benefits',
        },

        HEADER_MENU: {
          ACCOUNT_DETAILS: 'Account details',
          FEEDBACK: 'Feedback',
          SUPPORT: 'Support',
          PRIVACY_POLICY: 'Privacy policy',
          TERM_AND_CONDITION: 'Terms & conditions',
          LOGOUT: 'Log out',
        },
        CONSENT_TYPES: {
          TERMS_CONDITION: 'TERMS_AND_CONDITIONS',
          PRIVACY_POLICY: 'PRIVACY_POLICY',
        },
        TERMS_AND_CONDITIONS: {
          TITLE: 'Terms & conditions',
          OUTLINE:
            'The following outlines the terms & conditions of use of the Scan Health application. Before using any of the Scan Health services, you are requested to read, understand and agree to these terms.',
          BODY: '',
          ACCEPT: 'I agree',
          OK: 'OK',
        },
        PRIVACY_POLICY: {
          TITLE: 'Privacy policy',
          OUTLINE:
            'The following outlines the privacy policy of Scan Health application. Before using any of the Scan Health services, you are requested to read, understand and agree to the privacy policy.',
          BODY: '',
          ACCEPT: 'I agree',
          OK: 'Ok',
        },
        ACCEPT_CONSENT: {
          TERMS_CONDITION: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our terms & conditions were updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue.',
          },
          PRIVACY_POLICY: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our privacy policy was updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue.',
          },
          BOTH: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our terms & conditions and privacy policy were updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue using the platform',
          },
          ACCEPT: 'Accept',
          CANCEL: 'Cancel',
          TERMS_CONDITION_TEXT: 'Terms & conditions',
          PRIVACY_POLICY_TEXT: 'Privacy policy',
          AND: ' and ',
        },
        NAVIGATION: {
          FOOTER: [
            {
              icon: '/src/assets/images/home.svg',
              lightIcon: '/src/assets/images/home_light.svg',
              label: 'Home',
              link: '/home',
              alt: 'Home',
            },

            {
              icon: '/src/assets/images/shopping_cart2.svg',
              lightIcon: '/src/assets/images/shopping_cart.svg',
              label: 'Shop',
              link: '/shop',
              alt: 'Shop',
            },
            {
              icon: '/src/assets/images/circles.svg',
              lightIcon: '/src/assets/images/circles_light.svg',
              label: 'Circles',
              link: '/circles',
              alt: 'Circles',
            },
            {
              icon: '/src/assets/images/services.svg',
              lightIcon: '/src/assets/images/services_lightPayment Success.svg',
              label: 'Records',
              link: '/records',
              alt: 'Records',
            },
            {
              icon: '/src/assets/images/utilities.svg',
              lightIcon: '/src/assets/images/utilities_light.svg',
              label: 'Utilities',
              link: '/utilities',
              alt: 'Utilities',
            },
          ],
        },
      },
      HOMEDASHBOARD_SCREEN: {
        WELCOME_TEXT: 'Welcome!',
      },
      SHOP_SCREEN: {
        SHOP: {
          HEADING: 'Shop',
          BILL_CARD_TITLE: ['Find care', 'Buy coverage'],
          EXPENDITURES: 'Expenditure',
          CARD: [
            {
              COLOR: '#E2F1FC',
              DESCRIPTION: 'Find doctors and view price list',
              HEADING: 'Find care',
              URL: '/shop/findCare',
              IMAGE: FindCareImage,
              code: 'findCare',
            },
          ],
        },
      },
      CIRCLES_SCREEN: {
        CIRCLES_PAGE: {
          TAB_HEADERS: [
            {
              header: 'Organizations',
              index: 0,
            },
            {
              header: 'Consents',
              index: 1,
            },
          ],
        },
        SUB_HEADING_IN_ADD_INDIVIDUAL_MODAL: 'What do you want to add to your circle?',
        HEADING_IN_ADD_INDIVIDUAL_MODAL: `Add to circle`,
        INDIVIDUAL_TAB_HEADING: ['Financial', 'Care','Medical'],
        PARTNER_CARD_NO_PARTNAR_ADDED: {
          HEADING_TEXT: 'No organizations added',
          // IMG_URL: AddPartnerImage,
          PARA_TEXT:
            'Please click on the below button to add an organization to my circle',
          BTN_TEXT: 'Add to organization',
        },
        PARTNER_CARD_NO_PARTNAR_FOUND: {
          HEADING_TEXT: 'No organizations found! ',
          PARA_TEXT: 'No matching organizations found!',
          // IMG_URL: AddPartnerImage,
          BTN_TEXT: 'Add to circle ',
        },
        ADD_PARTNER: {
          HEADING: 'You are not part of any circles yet',
          SUBHEADING: 'Start by adding an organization',
          ADD_TO_CIRCLE_BTN_TEXT: 'Add organization',
          TITLE: 'Add an organization',
          BUTTON: 'Proceed',
          ADD_PARTNER: 'Add an organization',
          SELECT_PARTNER: 'Select organization',
          SEARCH_PARTNER: 'Search organization',
          PARTNER_PICKER: 'PartnerPicker',
          POPULAR_PARTNERS: 'Popular organizations',
          MORE_PARTNER: 'Loading more organizations...',
          SEEN_ALL: 'Yay! You have seen it all',
          PARTNER_PICKER_ENTERIES: [
            {
              value: 'medicare',
              label: 'My Medicare',
            },
            {
              value: 'ahk',
              label: 'Apple Health',
            },
            {
              value: 'bcbsm',
              label: 'BCBS of Minnesota',
            },
          ],
          REDIRECTING: 'Redirecting to organization website...',
          CMS_LOGIN: {
            HEADING: '"Demo Mpowered Health" Wants to Use "cms.gov" to sign in',
            SUBHEADING: 'This allows the app and website to share information about you.',
            CONTINUE: 'Continue',
            CANCEL: 'Cancel',
          },
          MEDICARE: 'My Medicare',
          ADDING_PARTNER: 'Adding organization...',
          ADD_PARTNER_SUCCESS: (partner = 'Partner') =>
            `${partner} has been added as your organization`,
          ADD_PARTNER_ERROR: 'Failed to add organization, please try again!',
          CIRCLES_FETCH_ERROR: 'Unable to fetch circles data!',
          ADD_PARTNER_CONNECTION_PROBLEM:
            'Something went wrong! Please delete and add the organization again',
          ADD_DEMOGRAPHICS_ERROR: 'Something went wrong when trying to update profile details',
          ABORT: 'Adding partner aborted',
        },
        NO_INDIVIDUAL_IN_CIRCLE: {
          HEADING_TEXT: 'No individual added ',
          // IMG_URL: NoIndividual,
          PARA_TEXT: 'Please click on the below button to add an individual to my circle',
          BTN_TEXT: 'Add to individual ',
        },
        itemList: [
          {
            // icon: PartnerIcon,
            content: 'Organization',
            subContent: 'Select an organization and add to your circle',
            category: 'partner',
            toolTipText:
              'Organizations are health plans, hospitals or other healthcare organizations that you currently have or have had a relationship with in the past. You will need your user ID & password for these organizations to establish the connection.',
          },
          {
            // icon: IndividualIcon,
            content: 'Individual',
            subContent: 'Add an individual to your circle',
            category: 'individual',
            toolTipText:
              'Individual is someone you know who is either involved in your care or for whom you provide care. Do not establish connections with people you do not know. ',
          },
        ],
        POPUP_MODAL_FAILED_TO_LOAD: {
          HEADING_TEXT: 'Failed to load partners!',
          // IMG_URL: FailedToLoadIcon,
          PARA_TEXT:
            'Sorry, we are not able to load partners at this moment. Please reload the page or come back in some time.',
          BTN_TEXT: 'Reload ',
        },
        DID_NOT_FIND_PARTNER: {
          TITLE: 'Select organization',
          HEADING_TEXT: 'Did not find the organization looking for?',
          // IMG_URL: partnerNotFound,
          PARA_TEXT: '',
          BTN_TEXT: '',
          LINK_TEXT: 'Report here',
          PARA2_TEXT: 'Your feedback is valuable and helps us to provide better solutions to you.',
          LINK: 'https://mpoweredhealth.ideas.aha.io/ideas/new',
        },
        INDIVIDUAL_FLYOUT_OBJ: {
          HEADING: 'Add an individual',
          SUB_HEADING: 'Choose an individual you wish to add to your circle ',
          listItems: [
            {
              heading: 'Minor',
              content: `A person who is under the age of 18 years`,
              img: AddMinor,
              isDependent: true,
            },
            {
              heading: 'Major',
              content: `A person who is 18 years or older`,
              img: AddAdult,
              isDependent: false,
            },
          ],
        },
        LEGAL_GUARDIAN_MODAL: {
          HEADING: 'Are you a legal guardian?',
          SUB_HEADING: 'Legal authority to make medical decisions for a minor.',
          BUTTON_TEXT: 'Proceed',
          YES: 'Yes',
          YES_TEXT: 'Will link minor’s profile to your account',
          NO: 'No',
          NO_TEXT: 'Need consent from the legal guardian.',
          TYPE: 'Minor',
          CHANGE: 'Change',
        },
        HEALTHCARE_ATTORNEY_MODAL: {
          HEADING: 'Do you have a healthcare power of attorney?',
          SUB_HEADING: 'Legal document that allows you to make medical decisions on their behalf.',
          BUTTON_TEXT: 'Proceed',
          YES: 'Yes',
          YES_TEXT: "Will link adult's profile to your account",
          NO: 'No',
          NO_TEXT: 'Need consent to access their records.',
          TYPE: 'Adult',
          CHANGE: 'Change',
        },
        ADD_MINOR_CONFIRMATION_OBJECT: {
          HEADING: 'Linked profiles found',
          SUB_HEADING: `We found few profiles linked to your organization. Would you like to add them to your circle?`,
          IS_LEGAL_GUARDIAN_TEXT: 'I confirm that  I am the legal guardian.',
        },
        MINOR_PROFILE_ALREADY_EXISTS_CONFIRMATION_OBJECT: {
          HEADING: 'Linked profiles found',
          SUB_HEADING: `We found few profiles linked to your organization. Would you like to add them to your circle?`,
          YES_BTN_TEXT: 'Send consent',
          NO_BTN_TEXT: 'Skip',
          IS_LEGAL_GUARDIAN_TEXT: 'You need consent to access their records.',
        },
        ACTIVE_CIRCLE_LOADER_KEY: 'ACTIVE_CIRCLE_LOADER_KEY',
        EAM_CALLBACK_ERR_MSGS: [
          {
            message: 'Something went wrong, please try again later.',
            code: 'EAM101',
          },
          {
            message: 'Something went wrong, please try again later.',
            code: 'EAM102',
          },
          {
            message: 'Unable to process the request, please try again later.',
            code: 'EAM103',
          },
          {
            message: 'Services are down, please try again later.',
            code: 'EAM104',
          },
          {
            message: 'Something went wrong from the partner, please try again later.',
            code: 'EAM105',
          },
          {
            message: 'Session expired, please login again.',
            code: 'EAM106',
          },
          {
            message: 'Unable to establish the connection, please try again.',
            code: 'EAM107',
          },
        ],
        EAM_FETCH_ERR_MSGS: [
          {
            message: 'Something went wrong, please try again later.',
            code: 'EAM001',
          },
          {
            message: 'Services are down, please try again later.',
            code: 'EAM002',
          },
          {
            message: 'Something went wrong from the partner, please try again later.',
            code: 'EAM003',
          },
          {
            message: 'Unable to establish the connection, please try again.',
            code: 'EAM004',
          },
        ],
        ERROR_DATA: {
          TITLE: 'Select organization',
          HEADING_TEXT: 'We’re Sorry...',
          // IMG_URL: ErrorAtAddingPartner,
          PARA_TEXT:
            'An error occured while adding your partner. Please reload the page or come back in some time.',
          BTN_TEXT: 'Reload',
        },
        MINOR_PROFILE_ALREADY_EXISTS_CONFIRMATION_OBJECT: {
          HEADING: 'Linked profiles found',
          SUB_HEADING: `We found few profiles linked to your organization. Would you like to add them to your circle?`,
          YES_BTN_TEXT: 'Send consent',
          NO_BTN_TEXT: 'Skip',
          IS_LEGAL_GUARDIAN_TEXT: 'You need consent to access their records.',
        },
        NO_PARTNER_LEFT: {
          TITLE: 'Select organization',
          HEADING_TEXT: 'No partner left to add!',
          // IMG_URL: NoPartnerLeft,
          PARA_TEXT: 'You have already added all the available partners in your circle.',
          BTN_TEXT: '',
        },
        PARTNERS: {
          TITLE: 'Partners in your care',
          ADD: 'Add to my circles',
          VIEW_MORE: 'View more',
          OPTIONS: ['Delete'],
          CANCEL: 'Cancel',
          REMOVE: 'Remove',
          REMOVE_PARTNER: (name) => `Remove ${name}`,
          REMOVE_PARTNER_MESSAGE: (name) =>
            `Are you sure you want to remove ${name} from My partner?`,
          REMOVE_PARTNER_LOADING: (name) => `Removing ${name} from My Partner`,
          REMOVE_PARTNER_SUCCESS: (name) => `${name} has been removed from My Partner`,
          REMOVE_PARTNER_ERROR: (name) => `$Unable to remove ${name}!`,
          FETCH_CIRCLES: 'Fetching circles',
        },
        PARTNER_CARD_NO_INTERNET_CONNECTION: {
          HEADING_TEXT: 'No internet connection.',
          // IMG_URL: NoInternetConnectionImage,
          PARA_TEXT:
            'Sorry, we are not able to load organizations at this moment. Please check your network connection.',
          BTN_TEXT: 'Reload',
        },
        PROFILE_CONFIRMATION_OBJECT: {
          YES_BUTTON_TEXT: "Yes it's me",
          NO_BUTTON_TEXT: 'No, it’s not me',
          HEADING: 'We found your profile',
          SUB_HEADING: 'Please confirm that the displayed profile belongs to you.',
          CHECKBOX_CONTENT:
            'Update the profile details shown above in your Mpowered Health account.',
        },
        CIRCLES_SERVICE_TYPE: {
          MEDICATIONS: 'MEDICATIONS',
          CONDITIONS: 'CONDITIONS',
          PROCEDURES: 'PROCEDURES',
          ALLERGIES: 'ALLERGIES',
          CLINICAL_VITALS: 'VITALS',
          LAB_RESULTS: 'LAB_RESULTS',
          CLAIMS: 'CLAIMS',
          IMMUNIZATIONS: 'IMMUNIZATIONS',
          VISITS: 'VISITS',
          COVERAGE: 'COVERAGE',
          SOCIAL_HISTORY: 'SMOKING_HISTORY',
          CARE_PLAN: 'CARE_PLAN',
        },
        CIRCLES: [
          {
            caption: 'My Health Plan',
            image: '/src/assets/images/healthPlan.svg',
            pwaImage: '/src/assets/images/pwaMyHealthPlan.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My ObGyn',
            image: '/src/assets/images/obGyn.svg',
            pwaImage: '/src/assets/images/pwaMyObgyn.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My PCP',
            image: '/src/assets/images/pcp.png',
            pwaImage: '/src/assets/images/pwaMyPCP.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My Pediatrician',
            image: '/src/assets/images/paediatrician.png',
            pwaImage: '/src/assets/images/pwaMyPaeditrician.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My Dental',
            image: '/src/assets/images/dental.png',
            pwaImage: '/src/assets/images/pwaMyDental.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My Care Team',
            image: '/src/assets/images/care.svg',
            pwaImage: '/src/assets/images/pwaMyCareTeam.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'My Friends',
            image: '/src/assets/images/friends.svg',
            pwaImage: '/src/assets/images/pwaMyFriends.svg',
            borderColor: '#DFD9D9',
          },
        ],
        CIRCLES_GROUPS: [
          {
            caption: 'Son',
            image: '/src/assets/images/son.svg',
            pwaImage: 'src/assets/images/pwaSon.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Daughter',
            image: '/src/assets/images/daughter.svg',
            pwaImage: 'src/assets/images/pwaDaughter.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Parent',
            image: '/src/assets/images/parent.svg',
            pwaImage: 'src/assets/images/pwaFamily.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Others',
            image: '/src/assets/images/others.svg',
            pwaImage: 'src/assets/images/pwaOthers.svg',
            borderColor: '#dfd9d9',
          },
        ],
      },
      RECORDS_SCREEN: {
        SERVICE_TAB_HEADING_BETA: ['Financial', 'Care','Medical', 'Documents'],
        SERVICE_TAB_HEADING: [ 'Financial', 'Care','Medical'],
        LOADING_TEXT_FOR_REUSABLE_COMPONENTS: (name) => `Loading ${name}`,
        ADD_DATA: 'Add data',
        NUMBER_OF_RECORDS_PER_PAGE: 10,
        SELF_REPORTING_DATA_SOURCE_LABELS: ['self-reporting', 'self reported'],
        SERVICES_TYPE: {
          BILL_PAY: 'bill_pay',
          SECOND_OPINION: 'second_opinion',
          AWARD_POINT: 'award_point',
          WALLET: 'wallet',
          DIAGNOSTICS: 'diagnostics',
          MEDICATIONS: 'medications',
          LABS: 'labs',
          CLINICAL_VITALS: 'clinical_vitals',
          dental: 'dental',
          VISION: 'vision',
          CONSULTS: 'consults',
          CLAIMS: 'claims',
          APPOINTMENTS: 'appointments',
          CONSENTS: 'consents',
          ID_CARD: 'id_card',
          SETTINGS: 'settings',
          UPLOAD: 'upload',
          CONDITIONS: 'conditions',
          PROCEDURES: 'procedures',
          ALLERGIES: 'allergies',
          IMMUNIZATIONS: 'immunizations',
          NONE: 'none',
          COVERAGE: 'coverage',
          SOCIAL_HISTORY: 'smoking_status',
          FAMILY_MEMBER_HISTORY: 'family_member_history',
          VITAL: 'clinical_vitals',
          VISITS: 'visits',
          CARE_PLAN: 'care_plan',
          CARE_TEAM: 'care_team',
          ASSESSMENTS: 'assessments_dashboard',
          CONSENTS: 'consents',
        },
      },
      UTITLITIES_SCREEN: {
        UTILITY_TAB_HEADING: ['Wallet', 'Account', 'Contact'],
        UTILITIES_DASHBOARD_TEXTS: {
          HEADING: 'Utilities',
          LOADING_UTILITIES: 'Loading utilities',
          WALLET: 'Wallet',
        },
        COMMUNICATION_PREFERENCE : {
          HEADING: 'Notification Preferences',
          SUB_HEADING: 'Set your notification preferences',
          HEADING1: 'Print preferences',
          SUB_HEADING1: 'Set your print preferences',
          ALERTS : 'Alerts',
          CARE_TEAM_MESSAGES : 'Care team Messages',
          BENEFITS : 'Benefits',
          DOCUMENTS : 'Documents',
          BRAILLE : 'Braille',
          LARGE_PRINT : 'Large Print',
          EMAIL : 'Email',
          SMS : 'SMS',
          PAPER : 'Paper',
          COMMUNICATION_PREFERENCE_HEADING : 'Communication preferences',
        },
        SUPPORT_OBJ : {
          HEADING: 'Support',
          SUB_HEADING: 'Need help?',
          FILE_SELECTOR_SUB_HEADING: 'Please attach a file that illustrates the issue you are facing',
          CONTENT: "We'll do our best to assist you and resolve any problems as quickly as possible.",
          PLACEHOLDER_FOR_SUPPORT: 'How can we help you?',
          PLACEHOLDER_FOR_CIRCLE: 'Can’t find the partner I am looking for.',
          ERROR_MSG: 'Something went wrong please try again.',
          SUPPORT_BUTTON : 'View support history'
        },
        HELP_PAGE: {
          HEADING: 'Frequently asked questions',
        },
          // {
          //   TITLE: 'Second Opinions',
          //   ICON_SOURCE: 'second-opinion-help.svg',
          //   BODY: [
          //     {
          //       QUERY: 'What is a second opinion?',
          //       SUGGESTION:
          //         'When you request a second opinion, you are asking another doctor or specialist to take a look at your medical records and give their opinion on the diagnosis, recommended treatment or other questions that you may have on your situation. ',
          //     },
          //     {
          //       QUERY: 'What types of second opinion are available?',
          //       SUGGESTION:
          //         'There are several types of second opinions. Currently, Mpowered Health offers second opinions only for Radiology Reports. We work with partners to provide you with access to licensed radiologists who specialize in reading X-rays, CT-scans, MRI and other diagnostic imaging in specific conditions and areas such as neurological, musculoskeletal etc.' +
          //         '\n\nIn our endeavor to bring more choice and flexibility to you, we plan to expand this service to include other medical second opinions in the future ',
          //     },
          //     {
          //       QUERY: 'How much does a second opinion cost?',
          //       SUGGESTION:
          //         'The cost is specific to the partner and the type of second opinion being requested. You can see the cost of your specific second opinion request before you submit your order.',
          //     },
          //     {
          //       QUERY: 'How do I pay for my second opinion?',
          //       SUGGESTION:
          //         'You can pay using a credit card or Mpowered Award points if you have accumulated points using our integrated bill payment solution which is secure and PCI compliant.',
          //     },
          //     {
          //       QUERY: 'How do I order a second opinion?',
          //       SUGGESTION:
          //         'Click on the Second Opinions from your Home dashboard. Click on "+" link in the Second Opinion page and the follow the steps',
          //     },
          //     {
          //       QUERY: 'Why does my second opinion order show up in the Pending tab?',
          //       SUGGESTION:
          //         'This means that you did not complete all the steps required to submit your second opinion order. Please click on the link and complete the ordering process.',
          //     },
          //     {
          //       QUERY: 'How will I know my order for a second opinion was submitted?',
          //       SUGGESTION: 'Your successful order will be visible in the Requested tab in Second Opinions',
          //     },
          //     {
          //       QUERY: 'How do I receive the report from the Radiologist?',
          //       SUGGESTION:
          //         'When the second opinion report from the radiologist becomes available, you will receive an alert that the report is now available. You can view the report by either clicking on the alert or going to the Reviewed tab in Second Opinions.',
          //     },
          //     {
          //       QUERY: 'Can I consult the Radiologist about the report?',
          //       SUGGESTION:
          //         'You may consult the radiologist on his report for a period of time after the report is delivered. This time period varies depending on the SLA of the company you picked for your second opinion. This information if available from the partner will be shown along with their reviewed report',
          //     },
          //   ],
          // },
          HELP_PAGE_ITEMS: [
          {
            TITLE: 'Award points',
            ICON_SOURCE: 'award-point-help.svg',
            BODY: [
              {
                QUERY: 'How do I earn award points?',
                SUGGESTION:
                  'Mpowered Health may give you points for different activities at different times based on programs running in the system. You will be alerted to when you can earn points. ',
              },
              {
                QUERY: 'What are the different ways I can use my award points?',
                SUGGESTION:
                  'You can use your award points to pay for a service you order through the mpowered app (e.g paying for a second opinion). You can also redeem points via a gift card or pre-paid card, or donate to a charity.',
              },
            ],
          },
          {
            TITLE: 'Referral',
            ICON_SOURCE: 'referral-help.svg',
            BODY: [
              {
                QUERY: 'How do I refer a friend?',
                SUGGESTION:
                  "Click on Share link. Share your unique code with your friend using email, phone or the other options available. You can also send your unique code to your friend manually. Your friend should use the code while signing up for his/her separate account. The referral will be considered successful once your friend's account setup and verification is complete.",
              },
            ],
          },
          {
            TITLE: 'Profile',
            ICON_SOURCE: 'profile-help.svg',
            BODY: [
              {
                QUERY: 'Why do I always need to authenticate every time I edit my profile?',
                SUGGESTION:
                  'Your profile information is a very sensitive section of your account that we use to determine not only your identity but also use it to match your health records. In order to prevent hacking and misuse, we have added additional security. While this may be inconvenient for you, please understand we are doing this to protect you and other consumers like you.',
              },
              {
                QUERY: 'How can I edit my profile ?',
                SUGGESTION:
                  'The edit icon needs to be clicked in the profile section. Upon clicking the edit icon, the user is prompted for a OTP validation. Once the OTP is entered, the user can add/edit the information within the session.',
              },
              {
                QUERY: 'Can I change my primary phone number?',
                SUGGESTION:
                  'At this time, we do not allow edits to primary phone number as your account is attached to that number and used for authentication and login. We are sorry for the inconvenience. We intend to add this capability in the future. ',
              },
            ],
          },
          {
            TITLE: 'Payments',
            ICON_SOURCE: 'payment-help.svg',
            BODY: [
              {
                QUERY: 'How can I store my payments in Mpowered?',
                SUGGESTION:
                  'Click on Utilities menu at the bottom of the app. In the Utilities dashboard, click on Wallet. Follow instructions to add, edit or delete payment options. Our payment solutions are secure and PCI compliant.',
              },
            ],
          },
          {
            TITLE: 'Add coverage',
            ICON_SOURCE: 'coverage.svg',
            BODY: [
              {
                QUERY: 'What are the different relationships to the subscriber?',
                SUGGESTION: [
                  'These values represent your relationship to the policy holder of a particular coverage/plan. Child - You are a child of the Policy Holder, Spouse - You are a spouse of the policy holder, Parent - You are a parent of the policy holder, Common - You are a common law spouse of th policy holder, Self - You are the policy holder, Injured - You are an injured party covered by the plan of the policy holder, Other -  You have some other relationship to the policy holder',
                ],
              },
            ],
          },
          {
            TITLE: 'My Medicare',
            ICON_SOURCE: 'my-medicare.jpg',
            BODY: [
              {
                QUERY: 'Who can download health records from My Medicare?',
                SUGGESTION: [
                  'If you are a Medicare beneficiary, then you can download your Medicare claims using your Medicare Beneficiary ID and password. If you are a Medicare fee-for-service beneficiary i.e you are not enrolled in a Medicare Advantage plan, you will see all your claims. If you have a Medicare Advantage plan, you may see only Part D (drug ) claims. You will need to add your Medicare Advantage Plan as a partner in your circles to see all other claims.  We plan to add support for Medicare Advantage Plan partners shortly.',
                ],
              },
              ,
              {
                QUERY: 'How do I make sense of the data?',
                SUGGESTION: [
                  'My Medicare makes claims data available to you. The application organizes the data based on what we receive on your behalf and present it in a way that is understandable for you.',
                ],
              },
              {
                QUERY: 'What are related records?',
                SUGGESTION: [
                  'We may infer some information based on the claims, such as the diagnosis in a claim or a procedure that was performed for which the claim was raised by your provider.  Where we infer such information, we will note it as such on the screen. Such information is presented for your helpful viewing only and will not become part of your record.',
                ],
              },
              {
                QUERY: 'How can I download my data from My Medicare?',
                SUGGESTION: [
                  'Please click on "Add to circle" in the Circles dashboard, and select My Medicare from the drop down. Enter your Medicare credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with My Medicare and download your health information. You can view this information when you click on "My Medicare" icon under the section "Circles".',
                ],
              },
            ],
          },
          {
            TITLE: 'Apple Health',
            ICON_SOURCE: 'Icon-Apple-Health-Small.png',
            BODY: [
              {
                QUERY: 'How can I store my payments in Mpowered?',
                SUGGESTION: [
                  'Please click on "Add to circle" in the Circles dashboard, and select Apple Health from the drop down. Enter your Apple credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with Apple Health and download your health information. You can view this information when you click on "Apple Health" icon under the section "Circles".',
                ],
              },
              {
                QUERY: 'Can I download Apple Health data if I do NOT have an Apple device?',
                SUGGESTION: [
                  'Apple does not support this integration on non Apple devices. So if you are on an Android, you will not be able to fetch any data from Apple Health. However, if you have already downloaded data from Apple Health onto your Mpowered health record, that information is available to your for viewing at anytime in the future irrespective of the device you have.',
                ],
              },
            ],
          },
        ],
        EARN_AWARD_POINTS_BAR_GRAPH_TEXT :{
          BAR_GRAPH_TEXT : ['Total Points Earned', 'You Can Earn'],
        },
        DEACTIVATE : {
        LOADING: 'Deactivate is loading...',
            HEADING: 'Deactivate account',
            SUB_HEADING: "We're sorry to see you go!",
            DEACTIVATE_CONFIRM_HEADING: 'Account deactivated',

            DEACTIVATE_CONFIRM_SUB_HEADING: [
              'If you did NOT initiate the deactivation, contact support@mpoweredhealth.com immediately with title “URGENT: ACCOUNT DEACTIVATION”',
            ],

            CONFIRMATION_HEADING: 'Deactivate account',

            CONFIRMATION_SUBHEADING: ['Are you sure you want to deactivate your account?'],

            REASON_HEADING: 'Reason for leaving?',

            REASON_BEFORE_GO: 'Before you go...',

            INFO_AWARDS:
              '• You have 700 award points. You will not be able to redeem them after the account deactivation.',
            INFO_AWARD_FIRST: 'You have',
            INFO_AWARD_MIDDLE: ' award points. ',
            INFO_AWARD_LAST: 'You will not be able to redeem them after the account deactivation. ',

            INFO_CONNECTIONS: '• You have 10 connections in your circle.',
            INFO_CONNECTION_FIRST: 'You have',
            INFO_CONNECTION_MIDDLE: ' connection(s)',
            INFO_CONNECTION_LAST: ' in your circle.',

            CANCEL_DEACTIVATE: 'Cancel deactivation',

            GO_DEACTIVATE: 'Continue',

            REASON_1: 'Not satisfied with services',

            REASON_2: 'Did not find what I was looking for',

            REASON_3: 'Not user friendly',

            REASON_4: 'App too slow',

            REASON_5: 'Others',

            DOWNLOAD: 'Download health records',

            DOWNLOAD_HEADING: 'Download my health records',

            FEATURE_NOT_AVAILABLE: 'This feature is not available, coming soon!',

            CONTACT_EMAIL_MESSAGE: 'Please contact support@mpoweredhealth.com to get a copy of your records.',

            DOWNLOAD_SUB_HEADING:
              'Data will not be available for download after account deactivation. We recommend  you to download all the data before deactivating the account.',

            DOWNLOAD_SELECTION_1: 'I will no longer be able to earn or redeem award points.',

            DOWNLOAD_SELECTION_2: 'I have read and understood the implications of deactivating the account.',

            DOWNLOAD_2: 'I will have to go through additional verification steps to re-activate my account.',

            DOWNLOAD_SELECTION_3:
              'I will no longer receive alerts or notifications to my email or phone about my account.',

            TERMS_CHECKBOX: 'I have read and understood the implications of deactivating the account.',

            DOWNLOAD_CANCEL: 'Cancel',

            DOWNLOAD_CONFIRM: 'Confirm',

            DOWNLOAD_CONTINUE: 'Continue',

            TERMS_HEADING: 'Terms and conditions',

            TERMS_1:
              'Mpowered Health will not request or share any information about myself even if I have consents set up in my account.',

            TERMS_2:
              'My partners will immediately stop seeing all my information, except information for which I have given them consent to download.',

            TERMS_3: 'I will no longer be able to earn or redeem award points.',

            TERMS_4: 'I will no longer receive alerts or notifications on my email or phone.',

            TERMS_5:
              'My personal data will be archived for 10 years and during this period it will be used only for legal, audit & compliance purpose. (For example, we may need to respond to audits by regulatory authorities).',

            TERMS_6:
              'My data will not be available for download after I deactivate. We recommend that you download all your data before deactivating your account.',

            TERMS_7:
              'I may re-activate my account, but I will have to go through additional verification steps & incur a fee to re-activate my account.',

            TERM_CONSENT: 'I have read and understood the implications of deactivating the account.',
          },
        AWARDPOINTS: {
          HEADING: 'Award points',
          TOTAL_AWARD_POINTS: 'Total award points',
          TRANSACTION_HISTORY: 'Transaction history',
          EARN_AWARD_POINTS_HEADING: 'Do you want to earn more award points?',
          EARN_AWARD_POINTS_SUB_HEADING: 'Complete the tasks and earn points',
          EARN_AWARD_POINTS_BUTTON_TEXT: 'Earn award points',
          CARD_DETAILS1: {
            HEADING: 'Redeem a gift card',
            TEXT: ' Redeem points for a gift card',
            COLOR: '#F0E4FC',
          },
          CARD_DETAILS2: {
            HEADING: 'Gift to someone',
            TEXT: 'Gift points to someone you know',
            COLOR: '#E6F5E9',
          },
          CARD_DETAILS3: {
            HEADING: 'Pay bills',
            TEXT: 'Pay bills with points',
            TEXT1: (numberOfDueBills) => `No of bills due : ${numberOfDueBills}`,
            COLOR: '#E2F1FC',
          },
          AWARD_POINTS_SHARED_SUCCESSFULLY: 'Award points successfully shared',
          AWARD_POINTS_SHARED_FAILED: 'Failed to share award points',
          INVALID_POINTS: 'Invalid points',
          MIN_POINTS_TO_SHARE: 'Minimum points to share is 100',
          POINTS_NOT_AVAILABLE: 'Selected points not available',
          SEND_AWARD_POINTS: 'Send award points',
        },
      
        EARNPOINTS: {
          TITLE : 'You can earn',
          HEADING: 'Earn more points',
          SUB_HEADING: 'Complete the activities and earn award points',
          TOTAL_AWARD_POINTS: 'Total award points',
          cards: [
            // The user first time log in then we are forcing to update the profile. So this card currently hiding.
            // {
            //   heading: 'Complete your profile',
            //   subHeading: 'Complete your profile and earn points',
            //   points: 100,
            //   buttonText: 'Complete',
            //   icon: EarnPointCard1,
            //   link: '/utilities/accountDetails',
            // },
            {
              heading: 'Refer a friend',
              subHeading: 'Invite your friend and earn points',
              points: 100,
              buttonText: 'Refer now',
              // icon: EarnPointCard2,
              link: '/utilities/refer',
            },
            // {
            //   heading: 'Add an organization',
            //   subHeading: 'Add an organization to your circle and get rewarded',
            //   points: 100,
            //   buttonText: 'Add',
            //   link: '/circles',
            //   icon: EarnPointCard3,
            // },
            {
              heading: 'Shop for coverage',
              subHeading: 'Compare and shop for health, vision and dental plans',
              points: 2000,
              buttonText: 'Shop now',
              link: '/records/coveragesummary/shopNow',
              // icon: EarnPointCard4,
            },
            {
              heading: 'Verify email',
              subHeading: 'Verify email and earn award points',
              points: 100,
              buttonText: 'Verify now',
              link: '/verify/email',
              // icon: EarnPointCard4,
            },
            {
              heading: 'Verify phone',
              subHeading: 'Verify phone number and earn award points',
              points: 100,
              buttonText: 'Verify now',
              link: '/verify/phone',
              // icon: EarnPointCard4,
            },
            {
              heading: 'Verify address',
              subHeading: 'Verify address and earn award points',
              points: 100,
              buttonText: 'Verify now',
              link: '/verify/address',
              // icon: EarnPointCard4,
            },
            {
              heading: 'Verify PCP',
              subHeading: 'Verify PCP and earn award points',
              points: 100,
              buttonText: 'Verify now',
              link: '/verify/PCP',
              // icon: EarnPointCard4,
            },
          ],
          REDEEM_GIFT_CARD_OBJ : {
            SERVER_ERROR: 'Internal server error, please try after sometime',
            CHANGE_BUTTON_TEXT: 'Change',
            REDEEM_AMOUNT_IN_DOLLAR_PREFIX_TEXT: 'Number of award points: ',
            EMAIL_ADDRESS_PREFIX_TEXT: '*We will send the gift card to your email ID: ',
            GIFT_CARD_SELECTION_SUB_HEADING: 'Select a gift card you would like to redeem',
            INFO_CARD: (minAwardPoint) =>
              `You would need minimum ${minAwardPoint} points to redeem a gift card`,
            REDEEM_BUTTON_TEXT: 'Redeem award points',
            PLACEHOLDER_FOR_INPUT_REWARD_POINT_BOX: 'Enter a gift card amount',
            PLACEHOLDER_FOR_FIXED_TYPE_SELECTION_BOX: 'Select a gift card',
            PAGE_HEADING: 'Redeem a gift card',
            SUCCESS_MODAL_VIEW_TRANSACTION_BUTTON_TEXT: 'View transaction history',
            AWARD_POINT_NOT_ENOUGH_BUTTON_TEXT: 'Earn award points',
            AWARD_POINT_NOT_ENOUGH_HEADING: 'Gift card can not be redeemed',
            SUCCESS_MODAL_SUB_HEADING: (point) => `You have redeemed ${point} award points!`,
            SUCCESS_MODAL_DESCRIPTION: (email) => `Your gift card had been sent to your email id : ${email}`,
            LOADING_GIFT_CARD: 'Loading gift cards',
            PLEASE_WAIT: 'Please wait',
            BREADCRUMB_PROPS_FOR_REDEEM_GIFT_CARD: (breadCrumbTitle) => [
              { label: 'Utilities', route: '/utilities' },
              { label: 'Award points', route: '/utilities/awardpoints' },
              { label: breadCrumbTitle },
            ],
           },
           BONUS_TYPE : {
            organizationAddedBonus: 'Organization bonus',
            coverageBonus: 'Coverage bonus',
            giftedTo: (name) => `Gifted to ${name || ''}`,
            giftedBy: (name) => `Gifted by ${name || ' '}`,
            profileUpdateBonus: 'Profile update bonus',
            referralBonus: 'Referral bonus',
            redeemAwardPoints: 'Award points redeemed',
            promoCodeBonus: 'Promo code bonus',
          },
          AWARDPOINTS_OBJECT : {
            LOADER_TEXT_FOR_GIFT_AWARDPOINTS: 'Loading users...',
            SPINNER_TEXT_FOR_GIFT_AWARDPOINTS: 'Fetching user...',
            TITLE_FOR_GIFT_AWARDPOINTS: 'Gift award points',
            PLACEHOLDER_FOR_GIFT_AWARDPOINTS_SEARCH: 'Enter name or email address',
          },
          BILL_SUMMARY : {
            HEADING: 'Bills',
            BILL_CARD_TITLE: ['Total bills due', 'Your expenses'],
            LOADING: 'Loading bills',
            LOADING_DETAILS: 'Loading bill details',
            SUMMARY: 'Bill summary',
            DUE: 'Total bills due',
            EXPENDITURE: 'Your expenditure',
            EXPENDITURES: 'Expenditure',
          },
        },

        FEED_BACK_OBJECT: {
          OPTIONS_LIST_FOR_FEEDBACK: [
            'Speed and efficiency ',
            'Costumer support',
            'User friendly',
            'Trust and security',
            'Other',
          ],
          HEADING: 'We would love to hear from you',
          SUB_HEADING: 'Are you satisfied with the service?',
          TEXT_FIELD_PLACEHOLDER: 'Leave your comment here...',
          HINT_TEXT_FOR_LESSTHAN_FOUR_STAR: 'Tell us what can be improved',
          HINT_TEXT_FOR_FIVE_STAR: 'What did you like the most?',
          PLACEHOLDER_FOR_LESSTHAN_FOUR_STAR: 'Tell us how can we improve',
          PLACEHOLDER_FOR_FIVE_STAR: 'What did you like the most',
          ERROR_MSG: 'Something went wrong please try again.',
          FEEDBACK_HEADING: 'Feedback',
          SUBMIT_BUTTON_TEXT: 'Submit feedback',
          APP: 'What did you like about the app?',
        },
        THANK_YOU_DEFAULT_OBJ: {
          HEADING: 'Thank you',
          SUB_HEADING: 'Your feedback was successfully submitted.',
          LINK_TEXT: 'Add another feedback',
        },
        REFER_AND_EARN: {
          HEADING: 'Refer & earn',
          AWARDPOINT_BUTTON_HEADING: 'View award points',
          INSTRUCTION_HEADING: 'How it works',
          INVITE_HEADING: ' Invite friends and earn award points',
          REFERRAL_CODE_TEXT: 'Referral code',
          SHARE_INVITE_CODE: 'Share referral code',
        },
        REFERRAL_STEPS: [
          'Share your referral code with your friends',
          'When your friend uses the app for the first time , they need to enter your referral code during the signup',
          "Once your friend's account is created, you will receive 100 award points",
        ],
        MANAGE_PAYMENT_TEXTS: {
          HEADING: 'Manage payments',
          SUBHEADING: 'Manage, add, delete your payment methods here',
          EMPTY_PAYMENT_METHOD: 'You do not have any payment options set.',
          MAKE_DEFAULT: 'Make primary',
          DEFAULT_PAYMENT: 'Primary',
          ADD_CARD: 'Add a credit / debit card',
          ADD_BANK_ACCOUNT: 'Add a bank account',
          EXISTING_CARDS: 'Cards',
          EXISTING_BANK_ACCOUNTS: 'Bank accounts',
          EDIT: 'Edit',
          DELETE: 'Delete',
          VERIFY: 'Verify',
          MAKE_DEFAULT_CONFIRMATION_DIALOG: {
            HEADING: 'Default payment updated!',
            SUBHEADING: 'Your default payment has been updated.',
          },
          CARD_ACTION_TEXTS : {
            LOADER_TEXT: 'Saving data',
            ADD_CARD: {
              TITLE: 'Add card',
              CONFIRMATION_DIALOG: {
                HEADING: 'Card details saved!',
                SUBHEADING: 'Your card details have been added.',
              },
            },
            EDIT_CARD: {
              TITLE: 'Edit card',
              CONFIRMATION_DIALOG: {
                HEADING: 'Card details saved!',
                SUBHEADING: 'Your card details have been updated.',
              },
            },
            CARD_SAVE_FAILED_MODAL: {
              HEADING: 'Failed to save card details!',
              SUBHEADING: 'Something went wrong while adding card details. Please try again.',
            },
            BUTTON_TEXT: 'Save',
            CARD_FORM: {
              FIRST_NAME: {
                label: 'Name on card',
                name: 'name',
              },
              ZIP_CODE: {
                label: 'Zip code',
                name: 'addressZip',
              },
              PHONE_NO: {
                label: 'Phone number',
                name: 'phoneNo',
              },
              CARD_NO: {
                label: 'Card number',
              },
              EXPIRY_DATE: {
                label: 'Expiry date',
              },
              CVC: {
                label: 'CVC',
              },
              ADDRESS_LINE_1: {
                label: 'Address line 1',
                name: 'addressLine1',
              },
              ADDRESS_LINE_2: {
                label: 'Address line 2',
                name: 'addressLine2',
              },
              COUNTRY: {
                label: 'Country',
                name: 'addressCountry',
              },
              STATE: {
                label: 'State',
                name: 'addressState',
              },
              CITY: {
                label: 'City',
                name: 'addressCity',
              },
              SAVEAS: {
                label: 'Save as',
                name: 'saveAs',
              },
            },
          },
          BANK_ACCOUNT_ACTION_TEXTS :{
            LOADER_TEXT: 'Saving data',
            ADD_ACCOUNT: {
              TITLE: 'Add bank account',
              CONFIRMATION_DIALOG: {
                HEADING: 'Bank account details saved!',
                SUBHEADING: 'Your bank account details have been added.',
              },
              FAIL_DIALOG: {
                HEADING: 'Failed to save bank account details!',
                SUBHEADING: 'Your bank account details have not been added.',
              },
            },
            EDIT_ACCOUNT: {
              TITLE: 'Edit bank account',
              CONFIRMATION_DIALOG: {
                HEADING: 'Bank account details saved!',
                SUBHEADING: 'Your bank account details have been updated.',
              },
              FAIL_DIALOG: {
                HEADING: 'Failed to save bank account details!',
                SUBHEADING: 'Your bank account details have not been updated.',
              },
              FAIL_FETCH_DETAILS: {
                HEADING: 'Failed to get bank account details!',
                SUBHEADING: 'Bank account with provided account id does not exist.',
              },
            },
            BUTTON_TEXT: 'Save',
            BANK_ACCOUNT_FORM: {
              NICK_NAME: {
                label: 'Save as',
                name: 'nickName',
              },
              ACCOUNT_HOLDER_NAME: {
                label: 'Account holder name',
                name: 'accountHolderName',
              },
              ROUTING_NUMBER: {
                label: 'Routing number',
                name: 'routingNumber',
              },
              ACCOUNT_HOLDER_TYPE: {
                label: 'Account holder type',
                name: 'accountHolderType',
              },
              ACCOUNT_NUMBER: {
                label: 'Account number',
                name: 'accountNumber',
              },
              ADDRESS_LINE_1: {
                label: 'Account holder address line 1',
                name: 'addressLine1',
              },
              ADDRESS_LINE_2: {
                label: 'Account holder address line 2',
                name: 'addressLine2',
              },
              STATE: {
                label: 'State',
                name: 'state',
              },
              CITY: {
                label: 'City',
                name: 'city',
              },
              ZIP_CODE: {
                label: 'Zip code',
                name: 'zip',
              },
            },
            ACCOUNT_HOLDER_OPTIONS: [
              {
                value: '',
                label: 'Select account type',
              },
              {
                value: 'individual',
                label: 'Individual',
              },
              {
                value: 'company',
                label: 'Company',
              },
            ],
            AUTHORIZATION_TEXT:
              'I authorize Mpowered Health to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.',
          },
           
          BANK_MAKE_DEFAULT_CONFIRMATION_DIALOG: {
            HEADING: 'Default bank account updated!',
            SUBHEADING: 'Your default bank account has been updated.',
          },
          DELETE_CARD_CONFIRMATION_DIALOG: {
            HEADING: 'Delete card ending ',
            SUBHEADING: 'Are you sure you want to delete this payment method?',
            CONFIRM_BUTTON_TEXT: 'Yes',
            CANCEL_BUTTON_TEXT: 'No',
          },
          DELETE_ACCOUNT_CONFIRMATION_DIALOG: {
            HEADING: 'Delete bank account ending with ',
            SUBHEADING: 'Are you sure you want to delete this payment method?',
            CONFIRM_BUTTON_TEXT: 'Yes',
            CANCEL_BUTTON_TEXT: 'No',
          },
          DELETE_CARD_ACKNOWLEDGE_DIALOG: {
            HEADING: 'Card ending (last4) deleted!',
            SUBHEADING: 'Your card has been deleted.',
          },
          DELETE_ACCOUNT_ACKNOWLEDGE_DIALOG: {
            HEADING: 'Bank account ending (last4) deleted!',
            SUBHEADING: 'Your bank account has been deleted.',
          },
          VERIFY_ACCOUNT_DIALOG: {
            HEADING: 'Verify account',
            VERIFY_BUTTON_TEXT: 'Verify',
            CANCEL_BUTTON_TEXT: 'Cancel',
            TEXT_FIELD_AMOUNT_1: 'First amount (in cents)',
            TEXT_FIELD_AMOUNT_2: 'Second amount (in cents)',
          },
          ACCOUNT_VERIFIED_ACK_DIALOG: {
            HEADING: 'Account verified!',
            SUBHEADING: 'Your bank account was verified successfully.',
          },
          ACCOUNT_VERIFICATION_FAILED: {
            HEADING: 'Account verification failed. Please try again.',
            SUBHEADING:
              'The amounts provided do not match the amounts that were sent to the bank account.',
            RE_VERIFY: 'Verify Again',
            CANCEL: 'Cancel',
          },
          BANK_ACCOUNT_STATUS: {
            VERIFIED: 'verified',
            UNVERIFIED: 'unverified',
          },
        },
       BANK_ACCOUNT_ACTION_TEXTS : {
          LOADER_TEXT: 'Saving data',
          ADD_ACCOUNT: {
            TITLE: 'Add bank account',
            CONFIRMATION_DIALOG: {
              HEADING: 'Bank account details saved!',
              SUBHEADING: 'Your bank account details have been added.',
            },
            FAIL_DIALOG: {
              HEADING: 'Failed to save bank account details!',
              SUBHEADING: 'Your bank account details have not been added.',
            },
          },
          EDIT_ACCOUNT: {
            TITLE: 'Edit bank account',
            CONFIRMATION_DIALOG: {
              HEADING: 'Bank account details saved!',
              SUBHEADING: 'Your bank account details have been updated.',
            },
            FAIL_DIALOG: {
              HEADING: 'Failed to save bank account details!',
              SUBHEADING: 'Your bank account details have not been updated.',
            },
            FAIL_FETCH_DETAILS: {
              HEADING: 'Failed to get bank account details!',
              SUBHEADING: 'Bank account with provided account id does not exist.',
            },
          },
          BUTTON_TEXT: 'Save',
          BANK_ACCOUNT_FORM: {
            NICK_NAME: {
              label: 'Save as',
              name: 'nickName',
            },
            ACCOUNT_HOLDER_NAME: {
              label: 'Account holder name',
              name: 'accountHolderName',
            },
            ROUTING_NUMBER: {
              label: 'Routing number',
              name: 'routingNumber',
            },
            ACCOUNT_HOLDER_TYPE: {
              label: 'Account holder type',
              name: 'accountHolderType',
            },
            ACCOUNT_NUMBER: {
              label: 'Account number',
              name: 'accountNumber',
            },
            ADDRESS_LINE_1: {
              label: 'Account holder address line 1',
              name: 'addressLine1',
            },
            ADDRESS_LINE_2: {
              label: 'Account holder address line 2',
              name: 'addressLine2',
            },
            STATE: {
              label: 'State',
              name: 'state',
            },
            CITY: {
              label: 'City',
              name: 'city',
            },
            ZIP_CODE: {
              label: 'Zip code',
              name: 'zip',
            },
          },
          ACCOUNT_HOLDER_OPTIONS: [
            {
              value: '',
              label: 'Select account type',
            },
            {
              value: 'individual',
              label: 'Individual',
            },
            {
              value: 'company',
              label: 'Company',
            },
          ],
          AUTHORIZATION_TEXT:
            'I authorize Mpowered Health to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.'
        },
        
        LABEL_LIST: ['Date of birth', 'Gender', 'Marital status', 'Address'],
        NAVIGATION_CONTENT: {
          MAIN_BODY: [
            {
              name: 'Your profile',
              link: '/utilities/accountDetails',
            },
            {
              name: 'Your ratings',
              link: '/ratingsdashboard',
            },
            {
              name: 'Your alerts',
              link: '/alerts',
            },
            {
              name: 'Feedback',
              link: '/utilities/feedback',
            },
          ],
          SECONDARY_BODY: [
            {
              name: 'FAQ',
              link: '/help',
            },
            {
              name: 'Support',
              link: '/utilities/support',
            },
            {
              name: 'Privacy policy',
              consentType: 'PRIVACY_POLICY',
            },
            {
              name: 'Terms & conditions',
              consentType: 'TERMS_AND_CONDITIONS',
            },
          ],
          LOGOUT_TXT: 'Logout',
        },
      },
      COMMONLY_USED: {
        OK: 'OK',
        UPDATE_USERNAME_CONSTANTS: {
          TITLE: 'Please enter your name',
          SUB_TITLE: 'This will help us provide the best experience!',
          FIRST_NAME: 'First name',
          FIRST_NAME_PLACEHOLDER: 'John',
          LAST_NAME: 'Last name',
          LAST_NAME_PLACEHOLDER: 'doe',
          SAVE: 'Save',
          FIRSTNAME: 'firstName',
          LASTNAME: 'lastName',
        },
        UPDATE_EMAIL_CONSTANTS: {
          TITLE: 'Please enter your email',
          SUB_TITLE: 'This will help us provide the best experience!',
          EMAIL: 'Email',
          EMAIL_PLACEHOLDER: 'Enter email ID',
          SAVE: 'Save',
        },
        CONFIRMATION_MODAL: {
          BUTTON_NO: 'No',
          BUTTON_YES: 'Yes',
          HEADING: 'Are you sure you want to exit ?',
          HEADING_REQUEST_CONSENT: 'Are you sure you want to request the consent ?',
        },
        SNACKBAR_TYPE: {
          SUCCESS: 'success',
          ERROR: 'error',
          WARNING: 'warning',
          INFO: 'info',
        },
        STAY_TUNED_POPUP: {
          HEADING: 'Stay tuned!',
          SUBHEADING: 'More features coming soon.',
        },
        INITIALS_BG_COLOR: ['#00A0DF', '#08ebb3', '#eb8508', '#8c42e8', '#FBFFB1', '#BFACE2'],
        REFERRAL_PROMO_CODE_COMPONENT: {
          REFERRAL_CODE_APPLIED: 'Referral code successfully applied',
          REFERRAL_TEXTFIELD_PLACEHOLDER: 'Enter referral/promo code',
          INVALID: 'Invalid code',
          INVALID_CODE: 'Invalid referral/promo code',
          PROMO_CODE_APPLIED: 'Promo code successfully applied',
          AWARD_POINTS: (awardPoints) => `${awardPoints} award points`,
          SUCCESSFULLY_APPLIED: 'Successfully applied',
          HAS_REFERRAL_PROMO_CODE: 'Have a referral/promo code?',
          REFERRAL_LABEL: 'Referral code',
          PROMO_LABEL: 'Promo code',
          ENTER_CODE: 'Enter code',
          INVALID_REFFERAL_PROMO_CODE: 'Invalid or incorrect referral or promo code',
          ERROR: 'Some error occured while verifying referral or promo code',
          APPLY: 'Apply',
        },
        FIREBASE_EVENT_NAMES: {
          PROFILE_UPDATE: 'user_profile',
          ONE_TIME_LOGIN_PASSED: 'one_time_login',
          LOGIN_PASSED: 'login',
          LOGIN_FAILED: 'login_failed',
          USER_ADDRESS: 'user_address',
          CONN: 'circle_connections_orgs',
          CONN_ADD_APPLE_HEALTH_KIT: 'conn_apple_health_kit',
          INITITATE_SECOND_OPINION: 'second_opinion_initiated',
          VALIDATING_EMAIL: 'email_verified',
          VALIDATING_PHONE_NUM: 'phone_num_verified',
          REGISTRATION_ATTEMPT: 'registration_attempted',
          REGISTRATION: 'registration_completed',
          REGISTRATION_WITH_REFERRAL: 'registration_with_referral',
          REGISTRATION_WITH_PROMO: 'registration_with_promo_code',
          PHONE_VERIFIED: 'phone_verified',
          RATING: 'rating_completed',
          REFER_A_FRIEND: 'referred_a_friend',
          PAYING_A_BILL: 'paying_a_bill',
          VIEW_COMPLETED_SECOND_OP: 'second_opinion_viewed',
          SECOND_OPINION_CREATED: 'second_opinion_created',
          PROFILE_UPDATED: 'profile_updated',
        },
        OTP_SCREEN: {
          ENTER_OTP: 'Enter OTP',
          EDIT: 'Enter a 4 digit code sent to ',
          DID_NOT_RECEIVE: 'Didn’t receive it? ',
          RESEND_OTP: 'Resend OTP',
          CREATE_ACCOUNT: 'Create account',
          OTP_SENT: 'OTP sent successfully ',
          OTP_RESENT: 'OTP resent successfully ',
          NEW_CODE: (time) => ` Request new code in 00:${time}`,
          OTP_VERIFIED: 'OTP verified',
          ERROR_MESSAGE: 'Invalid or incorrect OTP',
          PHONE_VERIFICATION_STATUS: {
            APPROVED: 'approved',
            PENDING: 'pending',
            EXPIRED: 'expired',
            DENIED: 'denied',
          },
          SOME_ERROR: 'Something went wrong!',
          OTP_RESEND_MAX_TIMES: 3,
          OTP_RESEND_MAX_REACHED: 'OTP maximum time resend reached.',
          ACCOUNT_CREATED: 'Account created',
        },
        ERROR_MESSAGES: {
          NOT_FOUND: 'NOT_FOUND',
          UNAUTHORIZED: 'UNAUTHORIZED',
          CODE_500: 'Something went wrong',
        },
        DEFAULT_NULL_REPLACEMENT: '-',
      },
    },
    es_US: {
      COVERAGE_SCREEN: {
        itemList: [
          {
            toolTipText: 'Change plan',
            content: 'Change plan',
            subContent: 'This is the dummy sub content',
          },
        ],
      },
      SIGNUP: {
        CAROUSEL: {
          CAROUSEL_TEXT1: {
            heading: 'Todo tu',
            title: 'cuidado de la salud',
            text: ' en',
            text1: 'una aplicación',
          },
          CAROUSEL_TEXT2: {
            heading: 'Obtén tus',
            title: 'registros',
            text: ' de',
            text1: 'todos tus planes de salud',
            text2: 'y médicos',
          },
          CAROUSEL_TEXT3: {
            heading: 'Comparte',
            title: 'registros',
            text: ' con',
            text1: 'familiares, médicos',
            text2: 'y cuidadores',
          },
          CAROUSEL_TEXT4: {
            heading: 'Compra ',
            title: 'para',
            text: 'visión, dental ',
            text1: 'y ',
            text2: 'otros servicios',
          },
        },
        SIGNUP_SCREEN: {
          EMAIL_OR_PHONE: 'Número de móvil/correo electrónico',
          CREATE_PASSWORD: 'Crear contraseña',
          CREATE_ACCOUNT: 'Crear cuenta',
          CONTINUE: 'Continuar',
          ONE_APP:
            'Una aplicación para ayudarte a consolidar todos tus registros de salud, realizar un seguimiento de tus medicamentos, gestionar tus gastos de atención médica, comprar planes y más...',
          WELCOME: '¡Bienvenido!',
          CREATE_MY_ACCOUNT: 'Crear mi cuenta',
          EMAIL: 'Correo electrónico',
          EMAIL_OR_PHONE_PLACEHOLDER: 'Ingresa tu número de móvil o correo electrónico',
          PASSWORD: 'Crear contraseña',
          PASSWORD_PLACEHOLDER: 'Contraseña (8+ caracteres)',
          CONFIRM_PASSWORD: 'Confirmar contraseña',
          CHANGE_PASSWORD: 'Cambiar contraseña',
          REFERRAL: '¿Tienes un código de referencia/promoción? (Opcional)',
          HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
          LOGIN: 'Iniciar sesión',
          OR: ' o ',
          DOWNLOAD: 'Descarga el portal de miembros de Monte Nido Plan en ',
          DEMO_DOWNLOAD: "Descarga Mpowered Health de forma gratuita en ",
          IOS: 'iOS',
          AND: ' y ',
          ANDROID: 'Android',
          LABEL: '¿No tienes una cuenta?',
          HEADING: 'Obtén potencia con nosotros.',
          SIGN_UP_NOW: 'Regístrate ahora.',
          BODY: 'Registrarse',
          BTN_TEXT_APPLY: 'Aplicar',
          BTN_TEXT_SIGNUP: 'Registrarse',
          EXISTING_USER_TEXT: '¿Usuario existente?',
          LOGIN_TEXT: ' Iniciar sesión',
          PASSWORD_STRENGTH_TEXT: 'Fortaleza de la contraseña - ',
          ERRORLOG_USER_EXIST_MSG: 'Ya existe un usuario con el mismo nombre de usuario',
          LOOKS_GOOD: 'Se ve bien',
          PASSWORD_INFO: 'Debe incluir mayúscula, minúscula, número y símbolo.',
          LEN_ERR_8: 'Debe tener al menos 8 caracteres',
          OR_CONTINUE_WITH: 'O continuar con',
          EMAIL_EXIST_MESSAGE: 'El correo electrónico ya existe... Intenta iniciar sesión',
          PHONE_EXIST_MESSAGE: 'El número de móvil ya existe... Intenta iniciar sesión',
          EMAIL_OR_PHONE_ERROR_MESSAGE: 'Ingresa un número de móvil o correo electrónico válido',
          MOBILE_OR_EMAIL_REQUIRED: 'Número de móvil o correo electrónico requerido',
          BY_CREATING: 'Al crear una cuenta, aceptas los ',
          TS: 'términos de servicio ',
          PP: 'política de privacidad',
          OTP_SENT: 'OTP enviado con éxito ',
          EMAIL_EXIST_MEG: 'El correo electrónico ya existe',
          MOBILE_EXIST_MES: 'El número de móvil ya existe',
        },
        SOCIAL_SIGN_IN: {
          APPLE: 'consumer-apple',
          GOOGLE: 'consumer-google',
          FACEBOOK: 'consumer-facebook',
          LINKEDIN: 'consumer-linkedin',
          APPLE_BTN: 'Apple',
          GOOGLE_BTN: 'Google',
          FACEBOOK_BTN: 'Facebook',
          LINKEDIN_BTN: 'Linkedin',
        },
        ONE_TIME_ACCESS_STATES: {
          ACTIVE: 'ACTIVE',
          EXPIRED: 'EXPIRED',
          INVALID: 'INVALID',
        },
        DEVICE_TYPE: {
          ANDROID: 'WEB_BROWSER_IN_ANDROID',
          IOS: 'WEB_BROWSER_IN_IOS',
          WEB: 'WEB_BROWSER',
        },
        EDIT_MOBILE_EMAIL_MODAL: {
          HEADING: 'Edit mobile number/email',
          MOBILE_EMAIL_LABEL: 'Mobile number/email',
          MOBILE_EMAIL_PLACEHOLDER: 'Enter your mobile number or email',
          SAVE_BUTTON: 'Save',
          REQUIRED_ERROR: 'Mobile number or email required',
          ERROR: 'Enter a valid mobile number or email',
          EMAIL_EXIST_MEG: 'Email already exists',
          MOBILE_EXIST_MES: 'Mobile number already exists',
        },
      },
      LOGIN_SCREEN: {
        LOG_IN: {
          PREFERRED_LANGUAGE: 'Idioma preferido',
          ONE_APP:
            'Una aplicación para ayudarte a consolidar todos tus registros de salud, hacer un seguimiento de tus medicamentos, gestionar tus gastos de atención médica, buscar planes y más...',
          WELCOME_BACK: '¡Bienvenido de nuevo!',
          LOGIN: 'Iniciar sesión',
          EMAIL_PHONE: 'Correo electrónico/número de teléfono',
          EMAIL_PHONE_REQUIRED_ERROR: 'Se requiere correo electrónico/número de teléfono',
          PASSWORD_REQUIRED_ERROR: 'Se requiere contraseña',
          EMAIL_PHONE_PLACEHOLDER: 'Por favor, ingresa tu correo electrónico o número de teléfono',
          PASSWORD: 'Contraseña',
          PASSWORD_PLACEHOLDER: 'Contraseña (8+ caracteres)',
          FORGOT_PASS: '¿Olvidaste tu contraseña?',
          NOT_HAVE_ACCOUNT: '¿No tienes una cuenta?',
          SIGN_UP: 'Regístrate',
          OR: 'o',
          DOWNLOAD: 'Descarga el portal de miembros de Monte Nido Plan en ',
          DEMO_DOWNLOAD: "Descarga Mpowered Health de forma gratuita en ",
          IOS: 'iOS',
          AND: ' y ',
          ANDROID: 'Android',
          LOADING_TEXT: 'Iniciando sesión...',
          INVALID_CREDS_ERROR_MESSAGE: 'Credenciales de usuario no válidas',
          ACCOUNT_DOES_NOT_EXISTS: 'Esta cuenta no existe. Crea una nueva cuenta.',
          ACCOUNT_DISABLED:
            'La cuenta está deshabilitada. Por favor, contacta a support@mpowerhealth.com para habilitar la cuenta.',
          ALL_YOUR: 'Todo tu',
          HEALTHCARE: 'cuidado de la salud',
          IN_ONE_APP: 'en una aplicación',
          OR_CONTINUE_WITH: 'O continuar con',
          ACCOUNT_CREATED_SUCCESSFULLY:
            '¡Cuenta creada exitosamente! Por favor, inicia sesión para continuar.',
          MPOWERED_TEXT : 'impulsado por Mpowered salud'  
        },
        CAPTCH_VALIDATION: {
          ERROR_TEXT: 'Captcha no coincide',
          VERIFIED_TEXT: 'Captcha verificado',
          PLACEHOLDER: 'Por favor, ingresa el valor del Captcha',
          HELPER_TEXT: 'Por favor, verifica el Captcha para proceder con el inicio de sesión',
          TEXTFIELD_LABEL: 'Escribe el código que ves arriba',
        },
      },

      SYSTEMS: {
        MPOWERED_SYSTEM: 'https://mpoweredhealth.com/',
        CMS_SYSTEM: 'https://www.cms.gov/',
      },
      MPOWERED_INFERRED: {
        INFERRED_DATA: 'This data was inferred by Mpowered Health from <RESOURCE>',
        PARENT_RESOURCES: {
          CLAIMS: 'claims',
        },
      },
      HEADER: {
        ACCOUNT_DETAILS_LABELS: {
          COMPLETE_PROFILE_MESSAGE: 'Completa tu perfil y gana puntos de recompensa',
          CANCEL_CHANGES: 'Cancelar cambios',
          CANCEL_PROFILE_MESSAGE:
            '¿Estás seguro de que quieres cancelar los cambios? No podrás obtener puntos de recompensa.',
          COMPLETE_PROFILE: 'Completar perfil',
          PERSONAL_DETAIL: 'Detalles personales',
          CONTACT_DETAILS: 'Detalles de contacto',
          CHANGES_APPLIED: 'Tus cambios se han aplicado correctamente',
          SAVE_CHANGES: 'Guardar cambios',
          LOADING: 'Cargando detalles de la cuenta, por favor espera...',
          ACCOUNT: 'Detalles de la cuenta',
          PROFILE_UPDATED: 'PERFIL_ACTUALIZADO',
          EMAIL_ADDRESS: 'Dirección de correo electrónico',
          PRIMARY_EMAIL: 'Correo electrónico principal',
          PRIMARY_EMAILS: 'Correo electrónico principal',
          ADDING_PRIMARY_EMAIL: 'Añadiendo correo electrónico principal...',
          PHONE_NUMBER: 'Número de teléfono',
          EDIT: 'Editar',
          ADD: 'Agregar',
          ADD_PRIMARY: 'Agregar principal',
          PHONE_NUMBER_NOT_VERIFIED:
            'El número de teléfono no está verificado, por favor inténtalo de nuevo...',
          PHONE_NUMBER_EDIT: 'Número de teléfono editado para el usuario',
          ADD_PRIMARY_MOBILE_NUMBER: 'Añadiendo número de móvil principal...',
          PHONE_NUMBER_ADDED: 'Número de teléfono añadido para el usuario',
          ALREADY_EXIST: (name) => `${name} ya existe`,
          SAME_ADDRESS: 'Ya se ha almacenado la misma dirección',
          ADDRESS: 'Dirección',
          ADDING_PRIMARY_ADDRESS: 'dirección principal',
          ADDING_SECONDARY_ADDRESS: 'dirección secundaria',
          VERIFICATION_RECORDS: 'No se encontraron registros de verificación',
          OTP_UNSUCCESS: 'Verificación de OTP no exitosa...',
          OTP_INCORRECT: 'Verificación de OTP no exitosa, por favor ingresa el OTP correcto.',
          OTP_SUCCESS: 'OTP verificado exitosamente',
          ACCOUNT_SUCCESS: 'Cuenta verificada exitosamente',
          OTP_VERIFYING: 'Verificando OTP, por favor espera...',
          MAX_ATTEMPT: 'Se alcanzó el número máximo de intentos',
          OTP_GENERATING: 'Generando OTP, por favor espera...',
          PROFILE_IMAGE: 'imagen de perfil',
          MAKE_PRIMARY_TO_SECONDARY: 'Haz tu principal secundaria',
          EDIT: 'Editar',
          ENTER_CODE: 'Ingresa el código',
          ADD_SECONDARY: 'Agregar secundaria',
          ADD_PRIMARY: 'Agregar principal',
          PRIMARY_EMAIL_LABEL: 'Dirección de correo electrónico principal',
          PRIMARY_EMAIL_PLACEHOLDER: 'nandini@gmail.com',
          SECONDARY_EMAIL_LABEL: 'Dirección de correo electrónico secundaria',
          SECONDARY_EMAIL_PLACEHOLDER: 'nandini@gmail.com',
          PRIMARY_PHONE_NUMBER_LABEL: 'Número de teléfono principal',
          PRIMARY_PHONE_NUMBER_PLACEHOLDER: 'Ingresa el número de teléfono principal',
          SECONDARY_PHONE_NUMBER_LABEL: 'Número de teléfono secundario',
          SECONDARY_PHONE_NUMBER_PLACEHOLDER: 'Ingresa el número de teléfono secundario',
          ADDRESS_TYPE_LABEL: 'Tipo de dirección',
          ADDRESS_TYPE_PLACEHOLDER:
            'Ingresa el tipo de dirección, por ejemplo, hogar, oficina, etc.',
          LINE1_LABEL: 'Línea de dirección 1',
          LINE1_PLACEHOLDER: '261 North university drive',
          LINE2_LABEL: 'Línea de dirección 2',
          LINE2_PLACEHOLDER: '262 South university drive',
          ZIP_CODE_LABEL: 'Código postal',
          ZIP_CODE_PLACEHOLDER: '33324',
          CITY_LABEL: 'Ciudad',
          CITY_PLACEHOLDER: 'Plantation',
          STATE_LABEL: 'Estado',
          STATE_PLACEHOLDER: 'Selecciona el estado',
          MEMBER_IDCARD_HEADING: 'tarjeta de identificación',
          MEMBER_BENEFITS_HEADING: 'beneficios',
        },
        HEADER_MENU: {
          ACCOUNT_DETAILS: 'Detalles de la cuenta',
          FEEDBACK: 'Comentarios',
          SUPPORT: 'Soporte',
          PRIVACY_POLICY: 'Política de privacidad',
          TERM_AND_CONDITION: 'Términos y condiciones',
          LOGOUT: 'Cerrar sesión',
        },
        CONSENT_TYPES: {
          TERMS_CONDITION: 'TÉRMINOS_Y_CONDICIONES',
          PRIVACY_POLICY: 'POLÍTICA_DE_PRIVACIDAD',
        },
        TERMS_AND_CONDITIONS: {
          TITLE: 'Términos y condiciones',
          OUTLINE:
            'Lo siguiente describe los términos y condiciones de uso de la aplicación Scan Health. Antes de utilizar cualquiera de los servicios de Scan Health, se te solicita que leas, comprendas y aceptes estos términos.',
          BODY: '',
          ACCEPT: 'Estoy de acuerdo',
          OK: 'OK',
        },
        PRIVACY_POLICY: {
          TITLE: 'Política de privacidad',
          OUTLINE:
            'Lo siguiente describe la política de privacidad de la aplicación Scan Health. Antes de utilizar cualquiera de los servicios de Scan Health, se te solicita que leas, comprendas y aceptes la política de privacidad.',
          BODY: '',
          ACCEPT: 'Estoy de acuerdo',
          OK: 'Ok',
        },
        ACCEPT_CONSENT: {
          TERMS_CONDITION: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our terms & conditions were updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue.',
          },
          PRIVACY_POLICY: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our privacy policy was updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue.',
          },
          BOTH: {
            HEADER: 'Scan Health',
            SUB_HEADER_PREFIX:
              'Our terms & conditions and privacy policy were updated. Please read and accept the updated ',
            SUBHEADER_POSTFIX: ' in order to continue using the platform',
          },
          ACCEPT: 'Accept',
          CANCEL: 'Cancel',
          TERMS_CONDITION_TEXT: 'Terms & conditions',
          PRIVACY_POLICY_TEXT: 'Privacy policy',
          AND: ' and ',
        },
        NAVIGATION: {
          FOOTER: [
            {
              icon: '/src/assets/images/home.svg',
              lightIcon: '/src/assets/images/home_light.svg',
              label: 'Inicio',
              link: '/home',
              alt: 'Inicio',
            },

            {
              icon: '/src/assets/images/shopping_cart2.svg',
              lightIcon: '/src/assets/images/shopping_cart.svg',
              label: 'Tienda',
              link: '/shop',
              alt: 'Tienda',
            },
            {
              icon: '/src/assets/images/circles.svg',
              lightIcon: '/src/assets/images/circles_light.svg',
              label: 'Círculos',
              link: '/circles',
              alt: 'Círculos',
            },
            {
              icon: '/src/assets/images/services.svg',
              lightIcon: '/src/assets/images/services_lightPayment Success.svg',
              label: 'Registros',
              link: '/records',
              alt: 'Registros',
            },
            {
              icon: '/src/assets/images/utilities.svg',
              lightIcon: '/src/assets/images/utilities_light.svg',
              label: 'Servicios Públicos',
              link: '/utilities',
              alt: 'Servicios Públicos',
            },
          ],
        },
      },
      HOMEDASHBOARD_SCREEN: {
        WELCOME_TEXT: '¡Bienvenido!',
      },
      SHOP_SCREEN: {
        SHOP: {
          HEADING: 'comercio',
          BILL_CARD_TITLE: ['encontrar atención', 'Comprar cobertura'],
          EXPENDITURES: 'Gasto',
          CARD: [
            {
              COLOR: '#E2F1FC',
              DESCRIPTION: 'Encuentre médicos y vea la lista de precios',
              HEADING: 'encontrar atención',
              URL: '/shop/findCare',
              IMAGE: FindCareImage,
              code: 'encontrar atención',
            },
          ],
        },
      },
      CIRCLES_SCREEN: {
        CIRCLES_PAGE: {
          TAB_HEADERS: [
            {
              header: 'Organizaciones        ',
              index: 0,
            },
            {
              header: 'Consents',
              index: 1,
            },
          ],
        },
        SUB_HEADING_IN_ADD_INDIVIDUAL_MODAL: `¿Qué quieres agregar a tu círculo?`,
        HEADING_IN_ADD_INDIVIDUAL_MODAL: `Agregar al círculo`,
        INDIVIDUAL_TAB_HEADING: ['Médico', 'Financiero', 'Cuidado'],
        PARTNER_CARD_NO_PARTNAR_ADDED: {
          HEADING_TEXT: 'No se han agregado organizaciones',
          // IMG_URL: AddPartnerImage,
          PARA_TEXT:
            'Por favor, haz clic en el botón "Agregar al círculo" a continuación para agregar una organización a mi círculo',
          BTN_TEXT: 'Agregar al círculo',
        },
        PARTNER_CARD_NO_PARTNAR_FOUND: {
          HEADING_TEXT: '¡No se encontraron organizaciones!',
          PARA_TEXT: '¡No se encontraron organizaciones coincidentes!',
          // IMG_URL: AddPartnerImage,
          BTN_TEXT: 'Agregar al círculo',
        },
        ADD_PARTNER: {
          HEADING: 'Todavía no eres parte de ningún círculo',
          SUBHEADING: 'Comienza por agregar una organización',
          ADD_TO_CIRCLE_BTN_TEXT: 'Agregar al círculo',
          TITLE: 'Agregar una organización',
          BUTTON: 'Continuar',
          ADD_PARTNER: 'Agregar una organización',
          SELECT_PARTNER: 'Seleccionar organización',
          SEARCH_PARTNER: 'Buscar organización',
          PARTNER_PICKER: 'Selector de socios',
          POPULAR_PARTNERS: 'Organizaciones populares',
          MORE_PARTNER: 'Cargando más organizaciones...',
          SEEN_ALL: '¡Bien! Has visto todo',
          PARTNER_PICKER_ENTERIES: [
            {
              value: 'medicare',
              label: 'Mi Medicare',
            },
            {
              value: 'ahk',
              label: 'Apple Health',
            },
            {
              value: 'bcbsm',
              label: 'BCBS de Minnesota',
            },
          ],
          REDIRECTING: 'Redireccionando al sitio web de la organización...',
          CMS_LOGIN: {
            HEADING: '"Demo Mpowered Health" Quiere Usar "cms.gov" para Iniciar Sesión',
            SUBHEADING:
              'Esto permite que la aplicación y el sitio web compartan información sobre ti.',
            CONTINUE: 'Continuar',
            CANCEL: 'Cancelar',
          },
          MEDICARE: 'Mi Medicare',
          ADDING_PARTNER: 'Agregando organización...',
          ADD_PARTNER_SUCCESS: (partner = 'Socio') =>
            `${partner} ha sido agregado como tu organización`,
          ADD_PARTNER_ERROR: 'Error al agregar la organización, ¡por favor, inténtalo de nuevo!',
          CIRCLES_FETCH_ERROR: 'No se puede obtener datos de los círculos!',
          ADD_PARTNER_CONNECTION_PROBLEM:
            '¡Algo salió mal! Por favor, elimina y agrega la organización nuevamente',
          ADD_DEMOGRAPHICS_ERROR: 'Algo salió mal al intentar actualizar los detalles del perfil',
          ABORT: 'Adición de socio abortada',
        },
        NO_INDIVIDUAL_IN_CIRCLE: {
          HEADING_TEXT: 'No se ha agregado ningún individuo',
          // IMG_URL: NoIndividual,
          PARA_TEXT:
            'Por favor, haz clic en el botón de abajo para agregar un individuo a mi círculo',
          BTN_TEXT: 'Agregar al círculo',
        },
        itemList: [
          {
            // icon: PartnerIcon,
            content: 'Organización',
            subContent: 'Selecciona una organización y agrégala a tu círculo',
            category: 'socio',
            toolTipText:
              'Las organizaciones son planes de salud, hospitales u otras organizaciones de atención médica con las que actualmente tienes o has tenido una relación en el pasado. Necesitarás tu ID de usuario y contraseña para estas organizaciones para establecer la conexión.',
          },
          {
            // icon: IndividualIcon,
            content: 'Individuos',
            subContent: 'Agrega un individuo a tu círculo',
            category: 'individuos',
            toolTipText:
              'Un individuo es alguien que conoces y que está involucrado en tu atención o para quien proporcionas atención. No establezcas conexiones con personas que no conoces.',
          },
        ],
        POPUP_MODAL_FAILED_TO_LOAD: {
          HEADING_TEXT: '¡Error al cargar socios!',
          IMG_URL: FailedToLoadIcon,
          PARA_TEXT:
            'Lo siento, no podemos cargar socios en este momento. Por favor, recarga la página o vuelve en algún momento.',
          BTN_TEXT: 'Recargar',
        },
        DID_NOT_FIND_PARTNER: {
          TITLE: 'Seleccionar organización',
          HEADING_TEXT: '¿No encontraste la organización que buscabas?',
          // IMG_URL: partnerNotFound,
          PARA_TEXT: '',
          BTN_TEXT: '',
          LINK_TEXT: 'Informe aquí',
          PARA2_TEXT: 'Tu opinión es valiosa y nos ayuda a ofrecerte mejores soluciones.',
          LINK: 'https://mpoweredhealth.ideas.aha.io/ideas/new',
        },
        INDIVIDUAL_FLYOUT_OBJ: {
          HEADING: 'Agregar un individuo',
          SUB_HEADING: 'Elige un individuo que desees agregar a tu círculo ',
          listItems: [
            {
              heading: 'Menor de edad',
              content: `Una persona menor de 18 años`,
              img: AddMinor,
              isDependent: true,
            },
            {
              heading: 'Mayor de edad',
              content: `Una persona de 18 años o más`,
              img: AddAdult,
              isDependent: false,
            },
          ],
        },
        LEGAL_GUARDIAN_MODAL: {
          HEADING: '¿Eres un tutor legal?',
          SUB_HEADING: 'Autoridad legal para tomar decisiones médicas para un menor.',
          BUTTON_TEXT: 'Continuar',
          YES: 'Sí',
          YES_TEXT: 'Vincular el perfil del menor a tu cuenta',
          NO: 'No',
          NO_TEXT: 'Necesitas consentimiento del tutor legal.',
          TYPE: 'Menor',
          CHANGE: 'Cambiar',
        },
        HEALTHCARE_ATTORNEY_MODAL: {
          HEADING: '¿Tienes un apoderado de atención médica?',
          SUB_HEADING: 'Documento legal que te permite tomar decisiones médicas en su nombre.',
          BUTTON_TEXT: 'Continuar',
          YES: 'Sí',
          YES_TEXT: 'Vincular el perfil del adulto a tu cuenta',
          NO: 'No',
          NO_TEXT: 'Necesitas consentimiento para acceder a sus registros.',
          TYPE: 'Adulto',
          CHANGE: 'Cambiar',
        },

        ADD_MINOR_CONFIRMATION_OBJECT: {
          HEADING: 'Perfiles vinculados encontrados',
          SUB_HEADING: `Encontramos algunos perfiles vinculados a tu organización. ¿Te gustaría agregarlos a tu círculo?`,
          IS_LEGAL_GUARDIAN_TEXT: 'Confirmo que soy el tutor legal.',
        },
        MINOR_PROFILE_ALREADY_EXISTS_CONFIRMATION_OBJECT: {
          HEADING: 'Perfiles vinculados encontrados',
          SUB_HEADING: `Encontramos algunos perfiles vinculados a tu organización. ¿Te gustaría agregarlos a tu círculo?`,
          YES_BTN_TEXT: 'Enviar consentimiento',
          NO_BTN_TEXT: 'Omitir',
          IS_LEGAL_GUARDIAN_TEXT: 'Necesitas consentimiento para acceder a sus registros.',
        },
        ACTIVE_CIRCLE_LOADER_KEY: 'ACTIVE_CIRCLE_LOADER_KEY',
        EAM_CALLBACK_ERR_MSGS: [
          {
            message: 'Algo salió mal, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM101',
          },
          {
            message: 'Algo salió mal, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM102',
          },
          {
            message: 'No se puede procesar la solicitud, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM103',
          },
          {
            message:
              'Los servicios están fuera de servicio, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM104',
          },
          {
            message: 'Algo salió mal con el socio, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM105',
          },
          {
            message: 'La sesión ha caducado, por favor, vuelve a iniciar sesión.',
            code: 'EAM106',
          },
          {
            message: 'No se puede establecer la conexión, por favor, inténtalo de nuevo.',
            code: 'EAM107',
          },
        ],
        EAM_FETCH_ERR_MSGS: [
          {
            message: 'Algo salió mal, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM001',
          },
          {
            message:
              'Los servicios están fuera de servicio, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM002',
          },
          {
            message: 'Algo salió mal con el socio, por favor, inténtalo de nuevo más tarde.',
            code: 'EAM003',
          },
          {
            message: 'No se puede establecer la conexión, por favor, inténtalo de nuevo.',
            code: 'EAM004',
          },
        ],
        ERROR_DATA: {
          TITLE: 'Seleccionar organización',
          HEADING_TEXT: 'Lo sentimos...',
          // IMG_URL: ErrorAtAddingPartner,
          PARA_TEXT:
            'Ocurrió un error al agregar a tu socio. Por favor, recarga la página o vuelve en algún momento.',
          BTN_TEXT: 'Recargar',
        },
        MINOR_PROFILE_ALREADY_EXISTS_CONFIRMATION_OBJECT: {
          HEADING: 'Perfiles vinculados encontrados',
          SUB_HEADING: `Encontramos algunos perfiles vinculados a tu organización. ¿Te gustaría agregarlos a tu círculo?`,
          YES_BTN_TEXT: 'Enviar consentimiento',
          NO_BTN_TEXT: 'Omitir',
          IS_LEGAL_GUARDIAN_TEXT: 'Necesitas consentimiento para acceder a sus registros.',
        },
        NO_PARTNER_LEFT: {
          TITLE: 'Seleccionar organización',
          HEADING_TEXT: '¡No quedan socios por agregar!',
          // IMG_URL: NoPartnerLeft,
          PARA_TEXT: 'Ya has agregado todos los socios disponibles en tu círculo.',
          BTN_TEXT: '',
        },
        PARTNERS: {
          TITLE: 'Socios en tu cuidado',
          ADD: 'Agregar a mis círculos',
          VIEW_MORE: 'Ver más',
          OPTIONS: ['Eliminar'],
          CANCEL: 'Cancelar',
          REMOVE: 'Eliminar',
          REMOVE_PARTNER: (name) => `Eliminar ${name}`,
          REMOVE_PARTNER_MESSAGE: (name) =>
            `¿Estás seguro de que quieres eliminar a ${name} de Mis socios?`,
          REMOVE_PARTNER_LOADING: (name) => `Eliminando a ${name} de Mi socio`,
          REMOVE_PARTNER_SUCCESS: (name) => `${name} ha sido eliminado de Mi Socio`,
          REMOVE_PARTNER_ERROR: (name) => `$No se puede eliminar a ${name}!`,
          FETCH_CIRCLES: 'Obteniendo círculos',
        },
        PARTNER_CARD_NO_INTERNET_CONNECTION: {
          HEADING_TEXT: 'Sin conexión a internet.',
          // IMG_URL: NoInternetConnectionImage,
          PARA_TEXT:
            'Lo siento, no podemos cargar organizaciones en este momento. Por favor, verifica tu conexión de red.',
          BTN_TEXT: 'Recargar',
        },
        PROFILE_CONFIRMATION_OBJECT: {
          YES_BUTTON_TEXT: 'Sí, soy yo',
          NO_BUTTON_TEXT: 'No, no soy yo',
          HEADING: 'Encontramos tu perfil',
          SUB_HEADING: 'Por favor, confirma que el perfil mostrado te pertenece.',
          CHECKBOX_CONTENT:
            'Actualiza los detalles del perfil que se muestran arriba en tu cuenta de Mpowered Health.',
        },
        CIRCLES_SERVICE_TYPE: {
          MEDICATIONS: 'MEDICAMENTOS',
          CONDITIONS: 'CONDICIONES',
          PROCEDURES: 'PROCEDIMIENTOS',
          ALLERGIES: 'ALERGIAS',
          CLINICAL_VITALS: 'SIGNOS VITALES',
          LAB_RESULTS: 'RESULTADOS DE LABORATORIO',
          CLAIMS: 'RECLAMOS',
          IMMUNIZATIONS: 'INMUNIZACIONES',
          VISITS: 'VISITAS',
          COVERAGE: 'COBERTURA',
          SOCIAL_HISTORY: 'HISTORIA SOCIAL',
          CARE_PLAN: 'PLAN DE CUIDADO',
        },
        CIRCLES: [
          {
            caption: 'Mi Plan de Salud',
            image: '/src/assets/images/healthPlan.svg',
            pwaImage: '/src/assets/images/pwaMyHealthPlan.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mi ObGyn',
            image: '/src/assets/images/obGyn.svg',
            pwaImage: '/src/assets/images/pwaMyObgyn.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mi Médico de Cabecera',
            image: '/src/assets/images/pcp.png',
            pwaImage: '/src/assets/images/pwaMyPCP.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mi Pediatra',
            image: '/src/assets/images/paediatrician.png',
            pwaImage: '/src/assets/images/pwaMyPaeditrician.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mi Dental',
            image: '/src/assets/images/dental.png',
            pwaImage: '/src/assets/images/pwaMyDental.jpg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mi Equipo de Cuidado',
            image: '/src/assets/images/care.svg',
            pwaImage: '/src/assets/images/pwaMyCareTeam.svg',
            borderColor: '#DFD9D9',
          },
          {
            caption: 'Mis Amigos',
            image: '/src/assets/images/friends.svg',
            pwaImage: '/src/assets/images/pwaMyFriends.svg',
            borderColor: '#DFD9D9',
          },
        ],
        CIRCLES_GROUPS: [
          {
            caption: 'Son',
            image: '/src/assets/images/son.svg',
            pwaImage: 'src/assets/images/pwaSon.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Daughter',
            image: '/src/assets/images/daughter.svg',
            pwaImage: 'src/assets/images/pwaDaughter.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Parent',
            image: '/src/assets/images/parent.svg',
            pwaImage: 'src/assets/images/pwaFamily.svg',
            borderColor: '#dfd9d9',
          },
          {
            caption: 'Others',
            image: '/src/assets/images/others.svg',
            pwaImage: 'src/assets/images/pwaOthers.svg',
            borderColor: '#dfd9d9',
          },
        ],
      },
      RECORDS_SCREEN: {
        SERVICE_TAB_HEADING_BETA: ['Financiera', 'Cuidado','Médica','Documentos'],
        SERVICE_TAB_HEADING: ['Financiera', 'Cuidado','Médica'],
        LOADING_TEXT_FOR_REUSABLE_COMPONENTS: (name) => `Cargando ${name}`,
        ADD_DATA: 'Agregar datos',
        NUMBER_OF_RECORDS_PER_PAGE: 10,
        SELF_REPORTING_DATA_SOURCE_LABELS: ['autoinforme', 'autoinformado'],
        SERVICES_TYPE: {
          BILL_PAY: 'pago_factura',
          SECOND_OPINION: 'segunda_opinion',
          AWARD_POINT: 'punto_premio',
          WALLET: 'billetera',
          DIAGNOSTICS: 'diagnósticos',
          MEDICATIONS: 'medicamentos',
          LABS: 'laboratorios',
          CLINICAL_VITALS: 'signos_clínicos',
          dental: 'dental',
          VISION: 'visión',
          CONSULTS: 'consultas',
          CLAIMS: 'reclamaciones',
          APPOINTMENTS: 'citas',
          CONSENTS: 'consentimientos',
          ID_CARD: 'tarjeta_identificación',
          SETTINGS: 'configuraciones',
          UPLOAD: 'cargar',
          CONDITIONS: 'condiciones',
          PROCEDURES: 'procedimientos',
          ALLERGIES: 'alergias',
          IMMUNIZATIONS: 'inmunizaciones',
          NONE: 'ninguno',
          COVERAGE: 'cobertura',
          SOCIAL_HISTORY: 'historial_social',
          FAMILY_MEMBER_HISTORY: 'historial_familiar',
          VITAL: 'signo_vital',
          VISITS: 'visitas',
          CARE_PLAN: 'plan_cuidado',
          CARE_TEAM: 'equipo_cuidado',
          ASSESSMENTS: 'evaluaciones_tablero',
          CONSENTS: 'consentimientos',
        },
      },
      UTITLITIES_SCREEN: {
        UTILITY_TAB_HEADING: ['Billetera', 'Cuenta', 'Contacto'],
        UTILITIES_DASHBOARD_TEXTS: {
          HEADING: 'Utilidades',
          LOADING_UTILITIES: 'Cargando utilidades',
          WALLET: 'Billetera',
        },
        HELP_PAGE: {
          HEADING: 'Preguntas frecuentes',
        },
        COMMUNICATION_PREFERENCE : {
         
          HEADING: 'Preferencias de Notificación',
          SUB_HEADING: 'Configura tus preferencias de notificación',
          HEADING1: 'Preferencias de Impresión',
          SUB_HEADING1: 'Configura tus preferencias de impresión',
          ALERTS: 'Alertas',
          CARE_TEAM_MESSAGES: 'Mensajes del \n equipodeatención',
          BENEFITS: 'Beneficios',
          DOCUMENTS: 'Documentos',
          BRAILLE: 'Braille',
          LARGE_PRINT: 'Impresión en grande',
          EMAIL: 'Correo electrónico',
          SMS: 'SMS',
          PAPER: 'Papel',
          COMMUNICATION_PREFERENCE_HEADING : 'Preferencias de comunicación',

        },
        SUPPORT_OBJ : {
          HEADING: 'Apoyo',
          SUB_HEADING: '¿Necesitar ayuda?',
          FILE_SELECTOR_SUB_HEADING: 'Adjunte un archivo que ilustre el problema al que se enfrenta',
          CONTENT: "Haremos todo lo posible para ayudarle y resolver cualquier problema lo más rápido posible",
          PLACEHOLDER_FOR_SUPPORT: '¿Cómo podemos ayudarle?',
          PLACEHOLDER_FOR_CIRCLE: "No puedo encontrar el socio que estoy buscando.",
          ERROR_MSG: "Algo salió mal, por favor inténtalo de nuevo",
          SUPPORT_BUTTON : "Ver historial de soporte"
        },
        //HELP_PAGE_ITEMS: [
          // {
          //   TITLE: 'Second Opinions',
          //   ICON_SOURCE: 'second-opinion-help.svg',
          //   BODY: [
          //     {
          //       QUERY: 'What is a second opinion?',
          //       SUGGESTION:
          //         'When you request a second opinion, you are asking another doctor or specialist to take a look at your medical records and give their opinion on the diagnosis, recommended treatment or other questions that you may have on your situation. ',
          //     },
          //     {
          //       QUERY: 'What types of second opinion are available?',
          //       SUGGESTION:
          //         'There are several types of second opinions. Currently, Mpowered Health offers second opinions only for Radiology Reports. We work with partners to provide you with access to licensed radiologists who specialize in reading X-rays, CT-scans, MRI and other diagnostic imaging in specific conditions and areas such as neurological, musculoskeletal etc.' +
          //         '\n\nIn our endeavor to bring more choice and flexibility to you, we plan to expand this service to include other medical second opinions in the future ',
          //     },
          //     {
          //       QUERY: 'How much does a second opinion cost?',
          //       SUGGESTION:
          //         'The cost is specific to the partner and the type of second opinion being requested. You can see the cost of your specific second opinion request before you submit your order.',
          //     },
          //     {
          //       QUERY: 'How do I pay for my second opinion?',
          //       SUGGESTION:
          //         'You can pay using a credit card or Mpowered Award points if you have accumulated points using our integrated bill payment solution which is secure and PCI compliant.',
          //     },
          //     {
          //       QUERY: 'How do I order a second opinion?',
          //       SUGGESTION:
          //         'Click on the Second Opinions from your Home dashboard. Click on "+" link in the Second Opinion page and the follow the steps',
          //     },
          //     {
          //       QUERY: 'Why does my second opinion order show up in the Pending tab?',
          //       SUGGESTION:
          //         'This means that you did not complete all the steps required to submit your second opinion order. Please click on the link and complete the ordering process.',
          //     },
          //     {
          //       QUERY: 'How will I know my order for a second opinion was submitted?',
          //       SUGGESTION: 'Your successful order will be visible in the Requested tab in Second Opinions',
          //     },
          //     {
          //       QUERY: 'How do I receive the report from the Radiologist?',
          //       SUGGESTION:
          //         'When the second opinion report from the radiologist becomes available, you will receive an alert that the report is now available. You can view the report by either clicking on the alert or going to the Reviewed tab in Second Opinions.',
          //     },
          //     {
          //       QUERY: 'Can I consult the Radiologist about the report?',
          //       SUGGESTION:
          //         'You may consult the radiologist on his report for a period of time after the report is delivered. This time period varies depending on the SLA of the company you picked for your second opinion. This information if available from the partner will be shown along with their reviewed report',
          //     },
          //   ],
          // },
        //   {
        //     TITLE: 'Award points',
        //     ICON_SOURCE: 'award-point-help.svg',
        //     BODY: [
        //       {
        //         QUERY: 'How do I earn award points?',
        //         SUGGESTION:
        //           'Mpowered Health may give you points for different activities at different times based on programs running in the system. You will be alerted to when you can earn points. ',
        //       },
        //       {
        //         QUERY: 'What are the different ways I can use my award points?',
        //         SUGGESTION:
        //           'You can use your award points to pay for a service you order through the mpowered app (e.g paying for a second opinion). You can also redeem points via a gift card or pre-paid card, or donate to a charity.',
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'Referral',
        //     ICON_SOURCE: 'referral-help.svg',
        //     BODY: [
        //       {
        //         QUERY: 'How do I refer a friend?',
        //         SUGGESTION:
        //           "Click on Share link. Share your unique code with your friend using email, phone or the other options available. You can also send your unique code to your friend manually. Your friend should use the code while signing up for his/her separate account. The referral will be considered successful once your friend's account setup and verification is complete.",
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'Profile',
        //     ICON_SOURCE: 'profile-help.svg',
        //     BODY: [
        //       {
        //         QUERY: 'Why do I always need to authenticate every time I edit my profile?',
        //         SUGGESTION:
        //           'Your profile information is a very sensitive section of your account that we use to determine not only your identity but also use it to match your health records. In order to prevent hacking and misuse, we have added additional security. While this may be inconvenient for you, please understand we are doing this to protect you and other consumers like you.',
        //       },
        //       {
        //         QUERY: 'How can I edit my profile ?',
        //         SUGGESTION:
        //           'The edit icon needs to be clicked in the profile section. Upon clicking the edit icon, the user is prompted for a OTP validation. Once the OTP is entered, the user can add/edit the information within the session.',
        //       },
        //       {
        //         QUERY: 'Can I change my primary phone number?',
        //         SUGGESTION:
        //           'At this time, we do not allow edits to primary phone number as your account is attached to that number and used for authentication and login. We are sorry for the inconvenience. We intend to add this capability in the future. ',
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'Payments',
        //     ICON_SOURCE: 'payment-help.svg',
        //     BODY: [
        //       {
        //         QUERY: 'How can I store my payments in Mpowered?',
        //         SUGGESTION:
        //           'Click on Utilities menu at the bottom of the app. In the Utilities dashboard, click on Wallet. Follow instructions to add, edit or delete payment options. Our payment solutions are secure and PCI compliant.',
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'Add coverage',
        //     ICON_SOURCE: 'coverage.svg',
        //     BODY: [
        //       {
        //         QUERY: 'What are the different relationships to the subscriber?',
        //         SUGGESTION: [
        //           'These values represent your relationship to the policy holder of a particular coverage/plan. Child - You are a child of the Policy Holder, Spouse - You are a spouse of the policy holder, Parent - You are a parent of the policy holder, Common - You are a common law spouse of th policy holder, Self - You are the policy holder, Injured - You are an injured party covered by the plan of the policy holder, Other -  You have some other relationship to the policy holder',
        //         ],
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'My Medicare',
        //     ICON_SOURCE: 'my-medicare.jpg',
        //     BODY: [
        //       {
        //         QUERY: 'Who can download health records from My Medicare?',
        //         SUGGESTION: [
        //           'If you are a Medicare beneficiary, then you can download your Medicare claims using your Medicare Beneficiary ID and password. If you are a Medicare fee-for-service beneficiary i.e you are not enrolled in a Medicare Advantage plan, you will see all your claims. If you have a Medicare Advantage plan, you may see only Part D (drug ) claims. You will need to add your Medicare Advantage Plan as a partner in your circles to see all other claims.  We plan to add support for Medicare Advantage Plan partners shortly.',
        //         ],
        //       },
        //       ,
        //       {
        //         QUERY: 'How do I make sense of the data?',
        //         SUGGESTION: [
        //           'My Medicare makes claims data available to you. The application organizes the data based on what we receive on your behalf and present it in a way that is understandable for you.',
        //         ],
        //       },
        //       {
        //         QUERY: 'What are related records?',
        //         SUGGESTION: [
        //           'We may infer some information based on the claims, such as the diagnosis in a claim or a procedure that was performed for which the claim was raised by your provider.  Where we infer such information, we will note it as such on the screen. Such information is presented for your helpful viewing only and will not become part of your record.',
        //         ],
        //       },
        //       {
        //         QUERY: 'How can I download my data from My Medicare?',
        //         SUGGESTION: [
        //           'Please click on "Add to circle" in the Circles dashboard, and select My Medicare from the drop down. Enter your Medicare credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with My Medicare and download your health information. You can view this information when you click on "My Medicare" icon under the section "Circles".',
        //         ],
        //       },
        //     ],
        //   },
        //   {
        //     TITLE: 'Apple Health',
        //     ICON_SOURCE: 'Icon-Apple-Health-Small.png',
        //     BODY: [
        //       {
        //         QUERY: 'How can I store my payments in Mpowered?',
        //         SUGGESTION: [
        //           'Please click on "Add to circle" in the Circles dashboard, and select Apple Health from the drop down. Enter your Apple credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with Apple Health and download your health information. You can view this information when you click on "Apple Health" icon under the section "Circles".',
        //         ],
        //       },
        //       {
        //         QUERY: 'Can I download Apple Health data if I do NOT have an Apple device?',
        //         SUGGESTION: [
        //           'Apple does not support this integration on non Apple devices. So if you are on an Android, you will not be able to fetch any data from Apple Health. However, if you have already downloaded data from Apple Health onto your Mpowered health record, that information is available to your for viewing at anytime in the future irrespective of the device you have.',
        //         ],
        //       },
        //     ],
        //   },
        // ],
        HELP_PAGE_ITEMS: [
          {
            TITLE: 'Puntos de recompensa',
            ICON_SOURCE: 'award-point-help.svg',
            BODY: [
              {
                QUERY: '¿Cómo puedo ganar puntos de recompensa?',
                SUGGESTION:
                  'Mpowered Health puede otorgarte puntos por diferentes actividades en diferentes momentos, según los programas que estén activos en el sistema. Se te notificará cuándo puedes ganar puntos.',
              },
              {
                QUERY: '¿De qué maneras puedo usar mis puntos de recompensa?',
                SUGGESTION:
                  'Puedes usar tus puntos de recompensa para pagar un servicio que solicites a través de la aplicación de Mpowered (por ejemplo, pagar por una segunda opinión). También puedes canjear puntos por una tarjeta de regalo o una tarjeta prepaga, o donarlos a una organización benéfica.',
              },
            ],
          },
          {
            TITLE: 'Referidos',
            ICON_SOURCE: 'referral-help.svg',
            BODY: [
              {
                QUERY: '¿Cómo puedo referir a un amigo?',
                SUGGESTION:
                  'Haz clic en "Compartir enlace". Comparte tu código único con tu amigo por correo electrónico, teléfono u otras opciones disponibles. También puedes enviar tu código único manualmente. Tu amigo debe usar el código al registrarse para su cuenta separada. La referencia se considerará exitosa una vez que se complete la configuración y verificación de la cuenta de tu amigo.',
              },
            ],
          },
          {
            TITLE: 'Perfil',
            ICON_SOURCE: 'profile-help.svg',
            BODY: [
              {
                QUERY: '¿Por qué siempre necesito autenticarme cada vez que edito mi perfil?',
                SUGGESTION:
                  'La información de tu perfil es una sección muy sensible de tu cuenta que utilizamos para determinar no solo tu identidad, sino también para vincular tus registros de salud. Para prevenir hackeos y malos usos, hemos añadido seguridad adicional. Si bien esto puede ser inconveniente para ti, entiende que lo hacemos para protegerte a ti y a otros usuarios como tú.',
              },
              {
                QUERY: '¿Cómo puedo editar mi perfil?',
                SUGGESTION:
                  'Debes hacer clic en el ícono de edición en la sección de perfil. Al hacer clic en el ícono de edición, se te pedirá una validación de OTP. Una vez que ingreses el OTP, podrás agregar o editar la información durante la sesión.',
              },
              {
                QUERY: '¿Puedo cambiar mi número de teléfono principal?',
                SUGGESTION:
                  'En este momento, no permitimos editar el número de teléfono principal, ya que tu cuenta está vinculada a ese número y se utiliza para la autenticación y el inicio de sesión. Lamentamos las molestias. Tenemos la intención de agregar esta capacidad en el futuro.',
              },
            ],
          },
          {
            TITLE: 'Pagos',
            ICON_SOURCE: 'payment-help.svg',
            BODY: [
              {
                QUERY: '¿Cómo puedo almacenar mis pagos en Mpowered?',
                SUGGESTION:
                  'Haz clic en el menú de Utilidades en la parte inferior de la aplicación. En el panel de Utilidades, haz clic en Billetera. Sigue las instrucciones para agregar, editar o eliminar opciones de pago. Nuestras soluciones de pago son seguras y cumplen con PCI.',
              },
            ],
          },
          {
            TITLE: 'Agregar cobertura',
            ICON_SOURCE: 'coverage.svg',
            BODY: [
              {
                QUERY: '¿Cuáles son las diferentes relaciones con el suscriptor?',
                SUGGESTION: [
                  'Estos valores representan tu relación con el titular de la póliza de un plan de cobertura. Hijo - Eres hijo del titular de la póliza, Cónyuge - Eres el cónyuge del titular de la póliza, Padre - Eres el padre del titular de la póliza, Unión libre - Eres pareja de hecho del titular de la póliza, Yo mismo - Eres el titular de la póliza, Lesionado - Eres una parte lesionada cubierta por el plan del titular de la póliza, Otro - Tienes alguna otra relación con el titular de la póliza.',
                ],
              },
            ],
          },
          {
            TITLE: 'Mi Medicare',
            ICON_SOURCE: 'my-medicare.jpg',
            BODY: [
              {
                QUERY: '¿Quién puede descargar registros de salud de Mi Medicare?',
                SUGGESTION: [
                  'Si eres un beneficiario de Medicare, puedes descargar tus reclamos de Medicare utilizando tu ID de beneficiario de Medicare y tu contraseña. Si eres un beneficiario de Medicare de fee-for-service, es decir, no estás inscrito en un plan de Medicare Advantage, verás todos tus reclamos. Si tienes un plan de Medicare Advantage, es posible que solo veas los reclamos de la Parte D (medicamentos). Deberás agregar tu plan de Medicare Advantage como un socio en tus círculos para ver todos los demás reclamos. Planeamos agregar soporte para socios de planes de Medicare Advantage en breve.',
                ],
              },
              {
                QUERY: '¿Cómo puedo entender los datos?',
                SUGGESTION: [
                  'Mi Medicare pone a tu disposición los datos de los reclamos. La aplicación organiza los datos según lo que recibimos en tu nombre y los presenta de una manera que sea comprensible para ti.',
                ],
              },
              {
                QUERY: '¿Qué son los registros relacionados?',
                SUGGESTION: [
                  'Podemos inferir alguna información basada en los reclamos, como el diagnóstico en un reclamo o un procedimiento que se realizó y por el cual tu proveedor presentó el reclamo. Cuando inferimos dicha información, la notaremos como tal en la pantalla. Esta información se presenta únicamente para tu visualización y no formará parte de tu registro.',
                ],
              },
              {
                QUERY: '¿Cómo puedo descargar mis datos de Mi Medicare?',
                SUGGESTION: [
                  'Haz clic en "Agregar al círculo" en el panel de Círculos y selecciona Mi Medicare en el menú desplegable. Ingresa tus credenciales de Medicare y autoriza a Mpowered Health para recibir información en tu nombre. Una vez que envíes correctamente, la aplicación intentará autenticarse con Mi Medicare y descargar tu información de salud. Puedes ver esta información cuando hagas clic en el ícono de "Mi Medicare" en la sección "Círculos".',
                ],
              },
            ],
          },
          {
            TITLE: 'Apple Health',
            ICON_SOURCE: 'Icon-Apple-Health-Small.png',
            BODY: [
              {
                QUERY: '¿Cómo puedo almacenar mis pagos en Mpowered?',
                SUGGESTION: [
                  'Haz clic en "Agregar al círculo" en el panel de Círculos y selecciona Apple Health en el menú desplegable. Ingresa tus credenciales de Apple y autoriza a Mpowered Health para recibir información en tu nombre. Una vez que envíes correctamente, la aplicación intentará autenticarse con Apple Health y descargar tu información de salud. Puedes ver esta información cuando hagas clic en el ícono de "Apple Health" en la sección "Círculos".',
                ],
              },
              {
                QUERY: '¿Puedo descargar datos de Apple Health si NO tengo un dispositivo Apple?',
                SUGGESTION: [
                  'Apple no admite esta integración en dispositivos que no sean de Apple. Por lo tanto, si estás en un dispositivo Android, no podrás obtener datos de Apple Health. Sin embargo, si ya has descargado datos de Apple Health en tu registro de salud de Mpowered, esa información estará disponible para que la veas en cualquier momento en el futuro, independientemente del dispositivo que tengas.',
                ],
              },
            ],
          },
        ],
        EARN_AWARD_POINTS_BAR_GRAPH_TEXT :{
          BAR_GRAPH_TEXT : ['Puntos totales obtenidos', 'Puedes ganar'],
        },
        DEACTIVATE: {
          LOADING: 'Desactivación en proceso...',
          HEADING: 'Desactivar cuenta',
          SUB_HEADING: '¡Lamentamos verte partir!',
          DEACTIVATE_CONFIRM_HEADING: 'Cuenta desactivada',
  
          DEACTIVATE_CONFIRM_SUB_HEADING: [
            'Si NO iniciaste la desactivación, contacta inmediatamente a support@mpoweredhealth.com con el asunto “URGENTE: DESACTIVACIÓN DE CUENTA”.',
          ],
  
          CONFIRMATION_HEADING: 'Desactivar cuenta',
  
          CONFIRMATION_SUBHEADING: ['¿Estás seguro de que deseas desactivar tu cuenta?'],
  
          REASON_HEADING: '¿Razón para irte?',
  
          REASON_BEFORE_GO: 'Antes de que te vayas...',
  
          INFO_AWARDS:
            '• Tienes 700 puntos de recompensa. No podrás canjearlos después de la desactivación de la cuenta.',
          INFO_AWARD_FIRST: 'Tienes',
          INFO_AWARD_MIDDLE: ' puntos de recompensa. ',
          INFO_AWARD_LAST: 'No podrás canjearlos después de la desactivación de la cuenta.',
  
          INFO_CONNECTIONS: '• Tienes 10 conexiones en tu círculo.',
          INFO_CONNECTION_FIRST: 'Tienes',
          INFO_CONNECTION_MIDDLE: ' conexión(es)',
          INFO_CONNECTION_LAST: ' en tu círculo.',
  
          CANCEL_DEACTIVATE: 'Cancelar desactivación',
  
          GO_DEACTIVATE: 'Continuar',
  
          REASON_1: 'No estoy satisfecho con los servicios',
          REASON_2: 'No encontré lo que buscaba',
          REASON_3: 'No es fácil de usar',
          REASON_4: 'La aplicación es demasiado lenta',
          REASON_5: 'Otros',
  
          DOWNLOAD: 'Descargar registros de salud',
  
          DOWNLOAD_HEADING: 'Descargar mis registros de salud',
  
          FEATURE_NOT_AVAILABLE: 'Esta función no está disponible, ¡próximamente!',
  
          CONTACT_EMAIL_MESSAGE: 'Por favor, contacta a support@mpoweredhealth.com para obtener una copia de tus registros.',
  
          DOWNLOAD_SUB_HEADING:
            'Los datos no estarán disponibles para su descarga después de la desactivación de la cuenta. Te recomendamos descargar todos los datos antes de desactivar la cuenta.',
  
          DOWNLOAD_SELECTION_1: 'Ya no podré ganar ni canjear puntos de recompensa.',
  
          DOWNLOAD_SELECTION_2: 'He leído y entendido las implicaciones de desactivar la cuenta.',
  
          DOWNLOAD_2: 'Tendré que pasar por pasos adicionales de verificación para reactivar mi cuenta.',
  
          DOWNLOAD_SELECTION_3:
            'Ya no recibiré alertas ni notificaciones en mi correo electrónico o teléfono sobre mi cuenta.',
  
          TERMS_CHECKBOX: 'He leído y entendido las implicaciones de desactivar la cuenta.',
  
          DOWNLOAD_CANCEL: 'Cancelar',
  
          DOWNLOAD_CONFIRM: 'Confirmar',
  
          DOWNLOAD_CONTINUE: 'Continuar',
  
          TERMS_HEADING: 'Términos y condiciones',
  
          TERMS_1:
            'Mpowered Health no solicitará ni compartirá ninguna información sobre mí, incluso si tengo consentimientos configurados en mi cuenta.',
  
          TERMS_2:
            'Mis socios dejarán de ver inmediatamente toda mi información, excepto aquella para la cual les haya dado consentimiento para descargar.',
  
          TERMS_3: 'Ya no podré ganar ni canjear puntos de recompensa.',
  
          TERMS_4: 'Ya no recibiré alertas ni notificaciones en mi correo electrónico o teléfono.',
  
          TERMS_5:
            'Mis datos personales serán archivados por 10 años y durante este período solo se utilizarán con fines legales, de auditoría y cumplimiento. (Por ejemplo, podemos necesitarlos para responder a auditorías de autoridades regulatorias).',
  
          TERMS_6:
            'Mis datos no estarán disponibles para su descarga después de desactivar la cuenta. Recomendamos que descargues todos tus datos antes de desactivar tu cuenta.',
  
          TERMS_7:
            'Podré reactivar mi cuenta, pero tendré que pasar por pasos adicionales de verificación y pagar una tarifa para reactivarla.',
  
          TERM_CONSENT: 'He leído y entendido las implicaciones de desactivar la cuenta.',
        },
        AWARDPOINTS: {
          HEADING: 'Puntos de premio',
          TOTAL_AWARD_POINTS: 'Total de puntos de premio',
          TRANSACTION_HISTORY: 'Historial de transacciones',
          EARN_AWARD_POINTS_HEADING: '¿Quieres ganar más puntos de premio?',
          EARN_AWARD_POINTS_SUB_HEADING: 'Completa las tareas y gana puntos',
          EARN_AWARD_POINTS_BUTTON_TEXT: 'Ganar puntos de premio',
          CARD_DETAILS1: {
            HEADING: 'Canjear una tarjeta de regalo',
            TEXT: 'Canjea puntos por una tarjeta de regalo',
            COLOR: '#F0E4FC',
          },
          CARD_DETAILS2: {
            HEADING: 'Regalo a alguien',
            TEXT: 'Regala puntos a alguien que conozcas',
            COLOR: '#E6F5E9',
          },
          CARD_DETAILS3: {
            HEADING: 'Pagar facturas',
            TEXT: 'Paga las facturas con puntos',
            TEXT1: (numberOfDueBills) => `Número de facturas pendientes: ${numberOfDueBills}`,
            COLOR: '#E2F1FC',
          },
          AWARD_POINTS_SHARED_SUCCESSFULLY: 'Puntos de premio compartidos exitosamente',
          AWARD_POINTS_SHARED_FAILED: 'Error al compartir puntos de premio',
          INVALID_POINTS: 'Puntos no válidos',
          MIN_POINTS_TO_SHARE: 'La cantidad mínima de puntos para compartir es 100',
          POINTS_NOT_AVAILABLE: 'Puntos seleccionados no disponibles',
          SEND_AWARD_POINTS: 'Enviar puntos de premio',
        },
        EARNPOINTS: {
          TITLE : 'puedes ganar',
          HEADING: 'Gana más puntos',
          SUB_HEADING: 'Completa las actividades y gana puntos de premio',
          TOTAL_AWARD_POINTS: 'Total de puntos de premio',
          cards: [
            // La primera vez que el usuario inicia sesión, estamos obligando a actualizar el perfil. Así que esta tarjeta está oculta actualmente.
            // {
            //   heading: 'Completa tu perfil',
            //   subHeading: 'Completa tu perfil y gana puntos',
            //   points: 100,
            //   buttonText: 'Completar',
            //   icon: EarnPointCard1,
            //   link: '/utilities/accountDetails',
            // },
            {
              heading: 'Referir a un amigo',
              subHeading: 'Invita a tu amigo y gana puntos',
              points: 100,
              buttonText: 'Referir',
              // icon: EarnPointCard2,
              link: '/utilities/refer',
            },
            // {
            //   heading: 'Agregar una organización',
            //   subHeading: 'Agrega una organización a tu círculo y obtén recompensas',
            //   points: 100,
            //   buttonText: 'Agregar',
            //   link: '/circles',
            //   icon: EarnPointCard3,
            // },
            {
              heading: 'Comprar cobertura',
              subHeading: 'Compara y compra planes de salud, visión y dental',
              points: 2000,
              buttonText: 'Comprar ahora',
              link: '/records/coveragesummary/shopNow',
              // icon: EarnPointCard4,
            },
          ],
          REDEEM_GIFT_CARD_OBJ : {
            SERVER_ERROR: 'Error interno del servidor, inténtelo de nuevo más tarde',
            CHANGE_BUTTON_TEXT: 'Cambiar',
            REDEEM_AMOUNT_IN_DOLLAR_PREFIX_TEXT: 'Número de puntos de recompensa: ',
            EMAIL_ADDRESS_PREFIX_TEXT: '*Enviaremos la tarjeta de regalo a su correo electrónico: ',
            GIFT_CARD_SELECTION_SUB_HEADING: 'Seleccione una tarjeta de regalo que le gustaría canjear',
            INFO_CARD: (minAwardPoint) =>
              `Necesita un mínimo de ${minAwardPoint} puntos para canjear una tarjeta de regalo`,
            REDEEM_BUTTON_TEXT: 'Canjear puntos de recompensa',
            PLACEHOLDER_FOR_INPUT_REWARD_POINT_BOX: 'Ingrese un monto para la tarjeta de regalo',
            PLACEHOLDER_FOR_FIXED_TYPE_SELECTION_BOX: 'Seleccione una tarjeta de regalo',
            PAGE_HEADING: 'Canjear una tarjeta de regalo',
            SUCCESS_MODAL_VIEW_TRANSACTION_BUTTON_TEXT: 'Ver historial de transacciones',
            AWARD_POINT_NOT_ENOUGH_BUTTON_TEXT: 'Ganar puntos de recompensa',
            AWARD_POINT_NOT_ENOUGH_HEADING: 'No se puede canjear la tarjeta de regalo',
            SUCCESS_MODAL_SUB_HEADING: (point) => `¡Ha canjeado ${point} puntos de recompensa!`,
            SUCCESS_MODAL_DESCRIPTION: (email) =>
              `Su tarjeta de regalo ha sido enviada a su correo electrónico: ${email}`,
            LOADING_GIFT_CARD: 'Cargando tarjetas de regalo',
            PLEASE_WAIT: 'Por favor espere',
            BREADCRUMB_PROPS_FOR_REDEEM_GIFT_CARD: (breadCrumbTitle) => [
              { label: 'Utilidades', route: '/utilities' },
              { label: 'Puntos de recompensa', route: '/utilities/awardpoints' },
              { label: breadCrumbTitle },
            ],
          },
          BONUS_TYPE: {
            organizationAddedBonus: 'Bono de organización',
            coverageBonus: 'Bono de cobertura',
            giftedTo: (name) => `Regalado a ${name || ''}`,
            giftedBy: (name) => `Regalado por ${name || ' '}`,
            profileUpdateBonus: 'Bono por actualización de perfil',
            referralBonus: 'Bono por referencia',
            redeemAwardPoints: 'Puntos de recompensa canjeados',
            promoCodeBonus: 'Bono por código promocional',
          },
          AWARDPOINTS_OBJECT : {
            LOADER_TEXT_FOR_GIFT_AWARDPOINTS: 'Cargando usuarios...',
            SPINNER_TEXT_FOR_GIFT_AWARDPOINTS: 'Obteniendo usuario...',
            TITLE_FOR_GIFT_AWARDPOINTS: 'Otorgar puntos de recompensa',
            PLACEHOLDER_FOR_GIFT_AWARDPOINTS_SEARCH: 'Ingrese nombre o dirección de correo electrónico',
          },
          BILL_SUMMARY: {
            HEADING: 'Facturas',
            BILL_CARD_TITLE: ['Total de facturas pendientes', 'Tus gastos'],
            LOADING: 'Cargando facturas',
            LOADING_DETAILS: 'Cargando detalles de la factura',
            SUMMARY: 'Resumen de facturas',
            DUE: 'Total de facturas pendientes',
            EXPENDITURE: 'Tu gasto',
            EXPENDITURES: 'Gastos',
          },
        },
        FEED_BACK_OBJECT: {
          OPTIONS_LIST_FOR_FEEDBACK: [
            'Velocidad y eficiencia',
            'Soporte al cliente',
            'Amigable para el usuario',
            'Confianza y seguridad',
            'Otro',
          ],
          HEADING: 'Nos encantaría saber de ti',
          SUB_HEADING: '¿Estás satisfecho con el servicio?',
          TEXT_FIELD_PLACEHOLDER: 'Deja tu comentario aquí...',
          HINT_TEXT_FOR_LESSTHAN_FOUR_STAR: 'Cuéntanos qué se puede mejorar',
          HINT_TEXT_FOR_FIVE_STAR: '¿Qué fue lo que más te gustó?',
          PLACEHOLDER_FOR_LESSTHAN_FOUR_STAR: 'Dinos cómo podemos mejorar',
          PLACEHOLDER_FOR_FIVE_STAR: '¿Qué fue lo que más te gustó?',
          ERROR_MSG: 'Algo salió mal, por favor inténtalo de nuevo.',
          FEEDBACK_HEADING: 'Comentarios',
          SUBMIT_BUTTON_TEXT: 'Enviar comentarios',
          APP: '¿Qué te gustó de la aplicación?',
        },
        THANK_YOU_DEFAULT_OBJ: {
          HEADING: 'Gracias',
          SUB_HEADING: 'Tu retroalimentación se envió con éxito.',
          LINK_TEXT: 'Agregar otra retroalimentación',
        },
        REFER_AND_EARN: {
          HEADING: 'Referir y ganar',
          AWARDPOINT_BUTTON_HEADING: 'Ver puntos de premio',
          INSTRUCTION_HEADING: 'Cómo funciona',
          INVITE_HEADING: 'Invita a amigos y gana puntos de premio',
          REFERRAL_CODE_TEXT: 'Código de referencia',
          SHARE_INVITE_CODE: 'Compartir código de referencia',
        },
        REFERRAL_STEPS: [
          'Comparte tu código de referencia con tus amigos',
          'Cuando tu amigo utiliza la aplicación por primera vez, deben ingresar tu código de referencia durante el registro',
          'Una vez que se crea la cuenta de tu amigo, recibirás 100 puntos de premio',
        ],
          // HEADING: 'Manage payments',
          // SUBHEADING: 'Manage, add, delete your payment methods here',
          // EMPTY_PAYMENT_METHOD: 'You do not have any payment options set.',
          // MAKE_DEFAULT: 'Make primary',
          // DEFAULT_PAYMENT: 'Primary',
          // ADD_CARD: 'Add a credit / debit card',
          // ADD_BANK_ACCOUNT: 'Add a bank account',
          // EXISTING_CARDS: 'Cards',
          // EXISTING_BANK_ACCOUNTS: 'Bank accounts',
          // EDIT: 'Edit',
          // DELETE: 'Delete',
          // VERIFY: 'Verify',
          // MAKE_DEFAULT_CONFIRMATION_DIALOG: {
          //   HEADING: 'Default payment updated!',
          //   SUBHEADING: 'Your default payment has been updated.',
          // },
           MANAGE_PAYMENT_TEXTS: {
            HEADING: "Administrar pagos",
            SUBHEADING: "Administra, agrega y elimina tus métodos de pago aquí",
            EMPTY_PAYMENT_METHOD: "No tienes ninguna opción de pago configurada.",
            MAKE_DEFAULT: 'Hacer principal',
            DEFAULT_PAYMENT: 'Principal',
            ADD_CARD: 'Agregar una tarjeta de crédito / débito',
            ADD_BANK_ACCOUNT: 'Agregar una cuenta bancaria',
            EXISTING_CARDS: 'Tarjetas',
            EXISTING_BANK_ACCOUNTS: 'Cuentas bancarias',
            EDIT: 'Editar',
            DELETE: 'Eliminar',
            VERIFY: 'Verificar',
            MAKE_DEFAULT_CONFIRMATION_DIALOG: {
              HEADING: '¡Pago predeterminado actualizado!',
              SUBHEADING: 'Tu método de pago predeterminado ha sido actualizado.'
            },
            CARD_ACTION_TEXTS: {
              LOADER_TEXT: 'Guardando datos',
              ADD_CARD: {
                TITLE: 'Agregar tarjeta',
                CONFIRMATION_DIALOG: {
                  HEADING: '¡Detalles de la tarjeta guardados!',
                  SUBHEADING: 'Los detalles de tu tarjeta han sido añadidos.',
                },
              },
              EDIT_CARD: {
                TITLE: 'Editar tarjeta',
                CONFIRMATION_DIALOG: {
                  HEADING: '¡Detalles de la tarjeta guardados!',
                  SUBHEADING: 'Los detalles de tu tarjeta han sido actualizados.',
                },
              },
              CARD_SAVE_FAILED_MODAL: {
                HEADING: '¡Error al guardar los detalles de la tarjeta!',
                SUBHEADING: 'Algo salió mal al agregar los detalles de la tarjeta. Por favor, inténtalo de nuevo.',
              },
              BUTTON_TEXT: 'Guardar',
              CARD_FORM: {
                FIRST_NAME: {
                  label: 'Nombre en la tarjeta',
                  name: 'name',
                },
                ZIP_CODE: {
                  label: 'Código postal',
                  name: 'addressZip',
                },
                PHONE_NO: {
                  label: 'Número de teléfono',
                  name: 'phoneNo',
                },
                CARD_NO: {
                  label: 'Número de tarjeta',
                },
                EXPIRY_DATE: {
                  label: 'Fecha de vencimiento',
                },
                CVC: {
                  label: 'CVC',
                },
                ADDRESS_LINE_1: {
                  label: 'Dirección línea 1',
                  name: 'addressLine1',
                },
                ADDRESS_LINE_2: {
                  label: 'Dirección línea 2',
                  name: 'addressLine2',
                },
                COUNTRY: {
                  label: 'País',
                  name: 'addressCountry',
                },
                STATE: {
                  label: 'Estado',
                  name: 'addressState',
                },
                CITY: {
                  label: 'Ciudad',
                  name: 'addressCity',
                },
                SAVEAS: {
                  label: 'Guardar como',
                  name: 'saveAs',
                },
              },
            },
              BANK_ACCOUNT_ACTION_TEXTS: {
                LOADER_TEXT: 'Guardando datos',
                ADD_ACCOUNT: {
                  TITLE: 'Agregar cuenta bancaria',
                  CONFIRMATION_DIALOG: {
                    HEADING: '¡Detalles de la cuenta bancaria guardados!',
                    SUBHEADING: 'Los detalles de tu cuenta bancaria han sido añadidos.'
                  },
                  FAIL_DIALOG: {
                    HEADING: '¡Error al guardar los detalles de la cuenta bancaria!',
                    SUBHEADING: 'Los detalles de tu cuenta bancaria no han sido añadidos.'
                  }
                },
                EDIT_ACCOUNT: {
                  TITLE: 'Editar cuenta bancaria',
                  CONFIRMATION_DIALOG: {
                    HEADING: '¡Detalles de la cuenta bancaria guardados!',
                    SUBHEADING: 'Los detalles de tu cuenta bancaria han sido actualizados.'
                  },
                  FAIL_DIALOG: {
                    HEADING: '¡Error al guardar los detalles de la cuenta bancaria!',
                    SUBHEADING: 'Los detalles de tu cuenta bancaria no han sido actualizados.'
                  },
                  FAIL_FETCH_DETAILS: {
                    HEADING: '¡Error al obtener los detalles de la cuenta bancaria!',
                    SUBHEADING: 'La cuenta bancaria con el ID proporcionado no existe.'
                  }
                },
                BUTTON_TEXT: 'Guardar',
                BANK_ACCOUNT_FORM: {
                  NICK_NAME: {
                    label: 'Guardar como',
                    name: 'nickName'
                  },
                  ACCOUNT_HOLDER_NAME: {
                    label: 'Nombre del titular',
                    name: 'accountHolderName'
                  },
                  ROUTING_NUMBER: {
                    label: 'Número de ruta',
                    name: 'routingNumber'
                  },
                  ACCOUNT_HOLDER_TYPE: {
                    label: 'Tipo de titular',
                    name: 'accountHolderType'
                  },
                  ACCOUNT_NUMBER: {
                    label: 'Número de cuenta',
                    name: 'accountNumber'
                  },
                  ADDRESS_LINE_1: {
                    label: 'Dirección del titular línea 1',
                    name: 'addressLine1'
                  },
                  ADDRESS_LINE_2: {
                    label: 'Dirección del titular línea 2',
                    name: 'addressLine2'
                  },
                  STATE: {
                    label: 'Estado',
                    name: 'state'
                  },
                  CITY: {
                    label: 'Ciudad',
                    name: 'city'
                  },
                  ZIP_CODE: {
                    label: 'Código postal',
                    name: 'zip'
                  }
                },
                ACCOUNT_HOLDER_OPTIONS: [
                  {
                    value: '',
                    label: 'Seleccionar tipo de cuenta'
                  },
                  {
                    value: 'individual',
                    label: 'Individual'
                  },
                  {
                    value: 'company',
                    label: 'Empresa'
                  }
                ],
                AUTHORIZATION_TEXT: 'Autorizo a Mpowered Health a debitar electrónicamente mi cuenta y, si es necesario, acreditar electrónicamente mi cuenta para corregir débitos erróneos.',
              }, 
          BANK_MAKE_DEFAULT_CONFIRMATION_DIALOG: {
            HEADING: 'Default bank account updated!',
            SUBHEADING: 'Your default bank account has been updated.'
          },
          DELETE_CARD_CONFIRMATION_DIALOG: {
            HEADING: 'Delete card ending ',
            SUBHEADING: 'Are you sure you want to delete this payment method?',
            CONFIRM_BUTTON_TEXT: 'Yes',
            CANCEL_BUTTON_TEXT: 'No',
          },
          DELETE_ACCOUNT_CONFIRMATION_DIALOG: {
            HEADING: 'Delete bank account ending with ',
            SUBHEADING: 'Are you sure you want to delete this payment method?',
            CONFIRM_BUTTON_TEXT: 'Yes',
            CANCEL_BUTTON_TEXT: 'No',
          },
          DELETE_CARD_ACKNOWLEDGE_DIALOG: {
            HEADING: 'Card ending (last4) deleted!',
            SUBHEADING: 'Your card has been deleted.',
          },
          DELETE_ACCOUNT_ACKNOWLEDGE_DIALOG: {
            HEADING: 'Bank account ending (last4) deleted!',
            SUBHEADING: 'Your bank account has been deleted.',
          },
          VERIFY_ACCOUNT_DIALOG: {
            HEADING: 'Verify account',
            VERIFY_BUTTON_TEXT: 'Verify',
            CANCEL_BUTTON_TEXT: 'Cancel',
            TEXT_FIELD_AMOUNT_1: 'First amount (in cents)',
            TEXT_FIELD_AMOUNT_2: 'Second amount (in cents)',
          },
          ACCOUNT_VERIFIED_ACK_DIALOG: {
            HEADING: 'Account verified!',
            SUBHEADING: 'Your bank account was verified successfully.',
          },
          ACCOUNT_VERIFICATION_FAILED: {
            HEADING: 'Account verification failed. Please try again.',
            SUBHEADING:
              'The amounts provided do not match the amounts that were sent to the bank account.',
            RE_VERIFY: 'Verify Again',
            CANCEL: 'Cancel',
          },
          BANK_ACCOUNT_STATUS: {
            VERIFIED: 'verified',
            UNVERIFIED: 'unverified',
          },
        },
        LABEL_LIST: ['Date of birth', 'Gender', 'Marital status', 'Address'],
        NAVIGATION_CONTENT: {
          MAIN_BODY: [
            {
              name: 'Your profile',
              link: '/utilities/accountDetails',
            },
            {
              name: 'Your ratings',
              link: '/ratingsdashboard',
            },
            {
              name: 'Your alerts',
              link: '/alerts',
            },
            {
              name: 'Feedback',
              link: '/utilities/feedback',
            },
          ],
          SECONDARY_BODY: [
            {
              name: 'FAQ',
              link: '/help',
            },
            {
              name: 'Support',
              link: '/utilities/support',
            },
            {
              name: 'Privacy policy',
              consentType: 'PRIVACY_POLICY',
            },
            {
              name: 'Terms & conditions',
              consentType: 'TERMS_AND_CONDITIONS',
            },
          ],
          LOGOUT_TXT: 'Logout',
        },
      },
      COMMONLY_USED: {
        OK: 'OK',
        UPDATE_USERNAME_CONSTANTS: {
          TITLE: 'Please enter your name',
          SUB_TITLE: 'This will help us provide the best experience!',
          FIRST_NAME: 'First name',
          FIRST_NAME_PLACEHOLDER: 'John',
          LAST_NAME: 'Last name',
          LAST_NAME_PLACEHOLDER: 'doe',
          SAVE: 'Save',
          FIRSTNAME: 'firstName',
          LASTNAME: 'lastName',
        },
        UPDATE_EMAIL_CONSTANTS: {
          TITLE: 'Please enter your email',
          SUB_TITLE: 'This will help us provide the best experience!',
          EMAIL: 'Email',
          EMAIL_PLACEHOLDER: 'Enter email ID',
          SAVE: 'Save',
        },
        CONFIRMATION_MODAL: {
          BUTTON_NO: 'No',
          BUTTON_YES: 'Sí',
          HEADING: '¿Estás seguro de que quieres salir?',
          HEADING_REQUEST_CONSENT: '¿Estás seguro de que quieres solicitar el consentimiento?',
        },
        SNACKBAR_TYPE: {
          SUCCESS: 'success',
          ERROR: 'error',
          WARNING: 'warning',
          INFO: 'info',
        },
        STAY_TUNED_POPUP: {
          HEADING: 'Stay tuned!',
          SUBHEADING: 'More features coming soon.',
        },
        INITIALS_BG_COLOR: ['#00A0DF', '#08ebb3', '#eb8508', '#8c42e8', '#FBFFB1', '#BFACE2'],
        REFERRAL_PROMO_CODE_COMPONENT: {
          REFERRAL_CODE_APPLIED: 'Código de referencia aplicado con éxito',
          REFERRAL_TEXTFIELD_PLACEHOLDER: 'Ingrese el código de referencia/promoción',
          INVALID: 'Código no válido',
          INVALID_CODE: 'Código de referencia/promoción no válido',
          PROMO_CODE_APPLIED: 'Código de promoción aplicado con éxito',
          AWARD_POINTS: (awardPoints) => `${awardPoints} puntos de recompensa`,
          SUCCESSFULLY_APPLIED: 'Aplicado con éxito',
          HAS_REFERRAL_PROMO_CODE: '¿Tiene un código de referencia/promoción?',
          REFERRAL_LABEL: 'Código de referencia',
          PROMO_LABEL: 'Código de promoción',
          ENTER_CODE: 'Ingresar código',
          INVALID_REFFERAL_PROMO_CODE: 'Código de referencia o promoción inválido o incorrecto',
          ERROR: 'Se produjo un error al verificar el código de referencia o promoción',
          APPLY: 'Aplicar',
        },
        FIREBASE_EVENT_NAMES: {
          PROFILE_UPDATE: 'user_profile',
          ONE_TIME_LOGIN_PASSED: 'one_time_login',
          LOGIN_PASSED: 'login',
          LOGIN_FAILED: 'login_failed',
          USER_ADDRESS: 'user_address',
          CONN: 'circle_connections_orgs',
          CONN_ADD_APPLE_HEALTH_KIT: 'conn_apple_health_kit',
          INITITATE_SECOND_OPINION: 'second_opinion_initiated',
          VALIDATING_EMAIL: 'email_verified',
          VALIDATING_PHONE_NUM: 'phone_num_verified',
          REGISTRATION_ATTEMPT: 'registration_attempted',
          REGISTRATION: 'registration_completed',
          REGISTRATION_WITH_REFERRAL: 'registration_with_referral',
          REGISTRATION_WITH_PROMO: 'registration_with_promo_code',
          PHONE_VERIFIED: 'phone_verified',
          RATING: 'rating_completed',
          REFER_A_FRIEND: 'referred_a_friend',
          PAYING_A_BILL: 'paying_a_bill',
          VIEW_COMPLETED_SECOND_OP: 'second_opinion_viewed',
          SECOND_OPINION_CREATED: 'second_opinion_created',
          PROFILE_UPDATED: 'profile_updated',
        },
        OTP_SCREEN: {
        ENTER_OTP: 'Ingrese OTP',
        EDIT: 'Ingrese un código de 4 dígitos enviado a ',
        DID_NOT_RECEIVE: '¿No lo recibiste? ',
        RESEND_OTP: 'Reenviar OTP',
        CREATE_ACCOUNT: 'Crear cuenta',
        OTP_SENT: 'OTP enviado con éxito',
        OTP_RESENT: 'OTP reenviado con éxito',
        NEW_CODE: (time) => ` Solicitar nuevo código en 00:${time}`,
        OTP_VERIFIED: 'OTP verificado',
        ERROR_MESSAGE: 'OTP inválido o incorrecto',
        PHONE_VERIFICATION_STATUS: {
          APPROVED: 'aprobado',
          PENDING: 'pendiente',
          EXPIRED: 'expirado',
          DENIED: 'denegado',
        },
        SOME_ERROR: '¡Algo salió mal!',
        OTP_RESEND_MAX_TIMES: 3,
        OTP_RESEND_MAX_REACHED: 'Se alcanzó el número máximo de reenvíos de OTP.',
        ACCOUNT_CREATED: 'Cuenta creada',
        ERROR_MESSAGES: {
          NOT_FOUND: 'NO_ENCONTRADO',
          UNAUTHORIZED: 'NO_AUTORIZADO',
          CODE_500: 'Algo salió mal',
        },

        DEFAULT_NULL_REPLACEMENT: '-',
      },
    },
  },
  coverageVendorList: {
    betaUser: [ 
    {
      id: 1,
      image: MonteNidoLogo,
      value: 'Monte Nido plan',
      link: 'https://www.montenido.com/',
    },
  ],
  },
}
};
