import { withStyles } from "@mui/styles"
import React, { useEffect, useState } from "react"
import colors from "../../utils/colors"
import { Box, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { SNACKBAR_TYPE, TOAST_TEXT_MESSAGE } from "../../utils/constants"
import SnackbarToast from "../../components/organisms/SnackbarToast"
import UploadFile from "./UploadFile"
import DocumentService from "../../service/DocumentService"
import Button from "../../components/atoms/Button"
import clsx from "clsx"
import FormSelect from "../../components/atoms/SelfReporting/Dropdown"
import axios from "axios"

const styles = (theme) => ({
  parentContainer: {
    width: "100%",
    height: "100%",
  },
  container: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    position: "absolute",
    width: 700,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  heading: {
    color: colors.BLACK_B500,
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
  },
  subHeading: {
    color: colors.GRAY_SECONDARY,
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  close: {
    color: colors.BLACK,
    fontFamily: "Material Icons Round",
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "24px",
  },
  textFieldContainer: { width: "100%" },
  required: { color: "red", fontSize: 12, fontWeight: 500 },
  textFieldTitle: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Inter",
    fontStyle: "normal,",
    color: colors.PRIMARY_GRAY,
    letterSpacing: "0px",
    lineHeight: "16px",
    marginBottom: theme.spacing(0.5),
  },
  textFieldStyle: {
    width: "100%",
  },
  errMessage: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Inter",
    fontStyle: "normal,",
    color: "#FF0000",
  },
  spacing: {
    width: "100%",
    height: theme.spacing(2),
  },
  saveButton: {
    textTransform: "none",
    padding: "24px 24px",
    borderRadius: 40,
    width: "100%",
    color: colors.WHITE,
    backgroundColor: colors.BLUE,
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    marginTop: theme.spacing(3),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.BLUE_HOVER,
      color: colors.WHITE,
    },
  },
  dropdownContainer: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: colors.GRAY_SECONDARY,
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    marginBottom: theme.spacing(1),
  },
})

const TITLE = "Add Document "
const SUB_TITLE = ""

const FileUploadModal = ({ classes, ...props }) => {
  const { show, onClose, onSave, onError, options } = props
  const [snackbar, setSnackbar] = useState({ message: null, type: SNACKBAR_TYPE.INFO })
  const [files, setFiles] = useState([])
  const [docType, setDocType] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleOnClose = () => {
    onClose()
    setFiles([])
    setDocType(null)
  }

  useEffect(() => {
    if (!isLoading && show) {
      setSnackbar({ message: null, type: SNACKBAR_TYPE.INFO })
      setDocType(null)
      setFiles([])
    }
  }, [show])

  const saveDocuments = async () => {
    setIsLoading(true)
    setSnackbar({ message: "Uploading document...", type: SNACKBAR_TYPE.INFO })
    const baseURL = process.env.APOLLO_CLIENT

    try {
      const formData = new FormData()

      // Check if all files have names
      const missingNames = files.some((file) => !file.fileNameWithoutExtension)
      if (missingNames) {
        setSnackbar({ message: "Please provide a name for each document", type: SNACKBAR_TYPE.ERROR })
        setIsLoading(false)
        return
      }

      files.forEach((doc, index) => {
        formData.append("files", doc.selectedFile)
        formData.append("documentNames", doc.fileNameWithoutExtension || "")
      })

      formData.append("folder", "mpowered-documents")
      formData.append("resourceName", "Document")
      formData.append("category", docType)

      const uploadResponse = await axios.post(`${baseURL}/documentservice/upload/documents`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      if (uploadResponse && uploadResponse.data) {
        const fileDetails = uploadResponse.data.map((location, index) => {
          // Find the corresponding file by matching the file name in the location
          const matchedFile = files.find((f) => location.includes(f.selectedFile.name))

          return {
            fileName: matchedFile ? matchedFile.fileNameWithoutExtension : location.split("/").pop(),
            location: location,
          }
        })

        const final = await DocumentService.addDocumentInfo({
          documentId: "",
          documentName: "",
          documentUrl: "",
          documentType: docType,
          category: docType,
          updatedBy: "self",
          files: fileDetails,
        })

        setSnackbar({ message: "Document uploaded successfully", type: SNACKBAR_TYPE.SUCCESS })
        onSave()
      }
    } catch (err) {
      console.error("Error uploading documents:", err)
      setSnackbar({ message: TOAST_TEXT_MESSAGE.SOMETHING_ERROR, type: SNACKBAR_TYPE.ERROR })
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnSavePress = async () => {
    await saveDocuments()
  }

  return (
    <Modal open={show} disableAutoFocus={true}>
      <Box className={classes.parentContainer}>
        <Box className={classes.container}>
          <Box>
            <Box className={classes.headingContainer}>
              <Box>
                <Typography className={classes.heading}>{TITLE} </Typography>
                <Typography className={classes.subHeading}>{SUB_TITLE} </Typography>
              </Box>

              <CloseIcon className={classes.close} onClick={handleOnClose} />
            </Box>
          </Box>

          <UploadFile
            handleFileLoad={(files) => {
              setFiles(files)
            }}
          />

          <Box className={classes.dropdownContainer}>
            <FormSelect
              label={"Category"}
              options={options}
              value={docType}
              changeHandler={(val) => setDocType(val)}
              placeholder={"Select an option"}
              valueKey={"document type"}
              labelClass={classes.label}
              disabled={false}
              showSearch={false}
              required={true}
              error={false}
            />
          </Box>

          <Box>
            <Button
              disableFocusRipple
              inactive={!files.length || isLoading || !docType || files.some((file) => !file.fileNameWithoutExtension)}
              color={"primary"}
              title={"Upload"}
              variant="contained"
              className={clsx([classes.saveButton])}
              onClick={() =>
                files.length &&
                !isLoading &&
                docType &&
                !files.some((file) => !file.fileNameWithoutExtension) &&
                handleOnSavePress()
              }
            />
          </Box>
        </Box>
        <SnackbarToast
          open={!!snackbar.message}
          message={snackbar.message ?? ""}
          duration={isLoading ? 300000 : 4000}
          onClose={() => setSnackbar({ message: null, type: null })}
          type={snackbar.type}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        />
      </Box>
    </Modal>
  )
}

export default withStyles(styles, { withTheme: true })(FileUploadModal)

