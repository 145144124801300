import client from '../components/ApolloClient';
import gql from 'graphql-tag';
import { dummyData, UniversalSearchForUser } from '../pages/AwardPoints/GiftAwardPoints/dummyData';

const FETCH_RECENTLY_VISITED_PERSONS = gql`
  query {
    fetchRecentlyVisited {
      profileUrl
      email
      name
    }
  }
`;
const SEARCH_USER_BY_EMAIL = gql`
  mutation saveAsRecentlyVisited($data: RecentlyVisitedUserInput) {
    saveAsRecentlyVisited(data: $data) {
      email
      name
      profileUrl
    }
  }
`;

export const FETCH_RELATED_PERSONS_FRAGMENT = gql`
  fragment fetchRelatedPerson on RelatedPerson {
    name
    email
    avatarUrl
  }
`;
const FETCH_RELATED_PERSONS = gql`
  query {
    fetchRelatedPersons {
      ...fetchRelatedPerson
    }
  }
  ${FETCH_RELATED_PERSONS_FRAGMENT}
`;

const GET_AWARD_POINTS = gql`
  query {
    awardPoints {
      points
      remarks
      creationDate
      transactionType
      details {
        username
      }
      __typename
    }
  }
`;

export const GET_AWARD_POINTS_BASIC_DETAILS = gql`
  query {
    basicAwardPointDetails {
      totalPoints
      pointsToDollarFactor
      minPointsForGiftCard
    }
  }
`;

export const GET_AWARD_POINTS_EARNING_OPTIONS = gql`
  query {
    getAwardPointsEarningOptions {
      totalPoints
      earningOptionPoints
      awardPointearningList{
        heading
        link
        displayOrder
        points
        subHeading
        buttonText
      }
    }
  }

`;

const SHARE_AWARD_POINTS = gql`
  mutation shareAwardPoints($data: AwardPointsShareInput) {
    shareAwardPoints(data: $data) {
      status
      code
      reason
    }
  }
`;

export default {
  getAwardPointBasicDetails() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_AWARD_POINTS_BASIC_DETAILS,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.basicAwardPointDetails);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAwardPointsEarningOptions() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_AWARD_POINTS_EARNING_OPTIONS,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.getAwardPointsEarningOptions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getHistory() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_AWARD_POINTS,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.awardPoints);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveAsRecentlyVisited(args, mutation = SEARCH_USER_BY_EMAIL) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data: {
              ...args,
            },
          },
        })
        .then((res) => resolve(res.data.saveAsRecentlyVisited))
        .catch((error) => reject(error.graphQLErrors[0].extensions?.exception));
    });
  },
  fetchRecentlyVisited(query = FETCH_RECENTLY_VISITED_PERSONS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response?.data?.fetchRecentlyVisited);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchRelatedPersons(query = FETCH_RELATED_PERSONS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchRelatedPersons);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAuthorizePayment(billId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: gql`{
                authorizePayment( billPayRequest: {
                    invoiceId: "${'Invoice/' + billId}"
                }) 
                {
                    amount 
                    paymentMethods {
                        last4,
                        brand,
                        paymentSystemId,
                        nickName,
                        isDefault
                    }
                    bankAccounts{
                        last4,
                        paymentSystemId,
                        nickName,
                        isDefault
                    }
                    awardPoints 
                    captureType
                    awardMinThreshold
                    awardsToDollar
                    errorMessage
                }}
                `,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.authorizePayment);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  shareAwardPoints(data) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: SHARE_AWARD_POINTS,
          variables: { data },
        })
        .then((response) => {
          resolve(response.data.shareAwardPoints);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTransactionCodes(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.getTransactionCodes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
