import React from 'react';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import colors from '../../../../utils/colors';
import ProfileSection from '../ProfileSection';
import AssociatePlans from '../AssociatePlans';
import CareTeamTableGrid from '../TableGrid/CareTeamTableGrid';

const styles = (theme) => ({
  membersWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4.25),
  },
  associateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  memberCardWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  associateCardWrapper: {
    padding: theme.spacing(2),
    border: '1px solid #E4E7EC',
    backgroundColor: colors.WHITE,
    borderRadius: theme.spacing(1),
    width: '100%',
  },
  memberPictureWrapper: {
    fontFamily: 'Inter',
  },
  memberPicture: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
  },
  memberName: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(2),
    color: colors.TITLE_GRAY,
    lineHeight: theme.spacing(3),
    letterSpacing: theme.spacing(0),
  },
  specialty: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: theme.spacing(1.75),
    lineHeight: '20px',
    color: colors.GRAY_SECONDARY,
    letterSpacing: theme.spacing(0),
  },
  dot: {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    backgroundColor: colors.GRAY_SECONDARY,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(5),
  },
  partnerName: {},
  memberNameWrapper: {
    marginLeft: theme.spacing(1),
  },
  heading: {
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: theme.spacing(1.75),
    color: colors.GRAY_SECONDARY,
    marginBottom: theme.spacing(2),
  },

  associateCarePlan: {
    marginTop: theme.spacing(5),
  },
  headingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  readMoreAndLess: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    color: colors.BLUE,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    transition: 'all 0.8s ease-in-out',
  },
  listItems: {
    fontFamily: 'Inter',
    lineHeight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    color: colors.PRIMARY_GRAY,
    transition: 'all 0.8s ease-in-out',
  },
  associateLogo: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  contentSection: {
    marginTop: theme.spacing(2),
  },
  associateHeading: {
    marginLeft: theme.spacing(1.5),
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    color: colors.TITLE_GRAY,
    lineHeight: theme.spacing(3),
  },
  associateSubHeading: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    color: colors.GRAY_SECONDARY,
  },
  list: {
    padding: '0px 0px 0px 16px',
    marginTop: theme.spacing(1),
    transition: 'height 4s ease-in-out',
    marginBottom: theme.spacing(0),
  },
  assocImgWrapper: { display: 'flex', alignItems: 'center' },
  infoIcon: {
    width: theme.spacing(2.25),
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  infoIconWrapper: { display: 'flex', letterSpacing: '0.3px', alignItems: 'center' },
  initialName: {
    fontFamily: 'Inter',
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    color: colors.WHITE,
  },
  initialWrapper: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  careTeamDetailsContainer: { margin: '12px 0px 40px 0px' },
});

const CareTeamCard = (props) => {
  const { classes, membersDetails, assoc_care_plan_Details, screenMode } = props;
  return (
    <Box className={classes.careTeamDetailsContainer}>
      {membersDetails?.memberList?.length > 0 ? (
        <Box>
          <Typography className={classes.heading}>{membersDetails.heading}</Typography>
          <Box className={classes.membersWrapper}>
            {!window.location.hostname.startsWith('me.mpowered') ? (
              <CareTeamTableGrid data={membersDetails?.memberList} />
            ) : (
              membersDetails?.memberList?.map((item) => (
                <ProfileSection key={item.id} item={item} classes={classes} />
              ))
            )}
          </Box>
        </Box>
      ) : null}
      {assoc_care_plan_Details?.associatedPlansList?.length > 0 ? (
        <Box className={classes.associateCarePlan}>
          <Typography className={classes.heading}>{assoc_care_plan_Details.heading}</Typography>
          <Box className={classes.associateWrapper}>
            {assoc_care_plan_Details.associatedPlansList.map((item) => (
              <AssociatePlans key={item.id} item={item} classes={classes} screenMode={screenMode} />
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(CareTeamCard);
