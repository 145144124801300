import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ProgressBar from '../../components/molecules/ProgressBar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import {  HELP_PAGE } from '../../utils/constants';
import { fireHanselEvent } from '../../utils/helper';
import Box from '@mui/material/Box';
import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px 16px 0px 0px',
    width: '95',
    margin: '0 2.5% 0 2.5%',
  },
  rootContainer: {
    marginBottom: '16px',
    paddingLeft: '20px'
  },
  heading: {
    marginLeft: '29px',
    alignSelf: 'center',
    fontSize: '18px',
    fontWeight: '700',
  },
  headingContainer: {
    padding: '0 !important',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
  },
  expansionDetails: {
    flexDirection: 'column',
    borderTop: theme.customComponent.card.border,
    margin: '0 auto 16px auto',
    maxHeight: '40vh',
  },
  detailBody: {
    fontFamily: 'Inter',
    fontSize: '15px',
    marginTop: '16px',
    marginBottom: '16px',
    whiteSpace: 'pre-wrap',
    color: '#606060',
    fontWeight:'550',
    overflow: 'hidden'
  },
  panelRoot: {
    ...theme.customComponent.card,
    border: 'none',
    backgroundColor: '#f5f7f8 !important',
    borderRadius: '15px !important',
    marginBottom: '16px',
    padding: '0px 16px 0px 19px',
    boxShadow: '2px 5px 5px rgba(0,0,0,0.08)',
    width: '95%',
    overflow: 'hidden',
  },
  questions: {
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#000000',
    fontWeight:'700'
  },
  card:{
    width:'99%',
    margin: '0 auto 0 auto',
    border: 'solid',
    borderColor: '#e9ecef',
    boxShadow: '3px 5px 10px 10px rgba(0,0,0,0.08)',
    borderRadius: '25px',
    padding: '15px',
    backgroundColor: 'white'
  },
  progress: {
    paddingTop: '10px',
  },
  headingMain: {
    fontSize: '18px',
    paddingLeft: '20px',
    paddingBottom: '15px'
  },
  container:{
    width: '100%',
  }
}));

const Help = (props) => {
  const classes = useStyles();

     const { templates } = useSelector((state) => state.templates);
      const { selectedLanguage } = useSelector((state) => state.app);
        
        const HELP_PAGE_ITEMS =
          templates?.texts?.[selectedLanguage]?. UTITLITIES_SCREEN?.HELP_PAGE_ITEMS||
          mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.HELP_PAGE_ITEMS;

          const HELP_PAGE =
       templates?.texts?.[selectedLanguage]?. UTITLITIES_SCREEN?.HELP_PAGE||
       mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.HELP_PAGE;

  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    fireHanselEvent('MPH_HELP', {});
  }, []);

  useEffect(() => {
    const { onFooterItemClick } = props;
    if (onFooterItemClick) {
      onFooterItemClick(4);
    }
  }, []);
  const handleChange = (panelIdx) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIdx : false);
    if (isExpanded) {
      switch (event.target.innerText) {
        case 'Second Opinions':
          fireHanselEvent('MPH_HELP_SECONDOPINION', {});
          break;
        case 'Referral':
          fireHanselEvent('MPH_HELP_REFER', {});
          break;
        case 'Add coverage':
          fireHanselEvent('MPH_HELP_COVERAGE', {});
          break;
      }
    }
  };

  return (
    <div className={classes.root}>
    <div className={classes.container}>
      <Box className={classes.card}>
      {/* <ProgressBar heading={HELP_PAGE.HEADING} backButtonOnFull /> */}
      <Typography className={classes.headingMain}>{HELP_PAGE.HEADING}</Typography>
      {HELP_PAGE_ITEMS.map((helperItem, idx) => {
        const { BODY, ICON_SOURCE, TITLE } = helperItem;

        return (
          <div className={classes.rootContainer}>
            <Accordion
              className={classes.panelRoot}
              key={idx}
              expanded={expanded === idx}
              onChange={handleChange(idx)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon htmlColor="#adacb4" />}
                aria-controls={`panel${idx}a-content`}
                id={`panel${idx}a-header`}
                className={classes.headingContainer}
              >
                {/* {ICON_SOURCE && (  //scan FAQ's 
                  <img id={`${TITLE}-help-icon`} src={ICON_SOURCE} height={32} width={32} />
                )} */}
                {ICON_SOURCE && (
                  <img
                    id={`${TITLE}-help-icon`}
                    src={require(`../../assets/images/${ICON_SOURCE}`)}
                    height={32}
                    width={32}
                  />
                )}
                <Typography className={classes.heading}>{TITLE}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionDetails}>
                {BODY &&
                  BODY.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <Typography className={classes.questions}>{item.QUERY}</Typography>
                      <Typography className={classes.detailBody}>{item.SUGGESTION}</Typography>
                    </React.Fragment>
                  ))}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      </Box>
      </div>
    </div>
  );
};

export default Help;
