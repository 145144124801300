import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { withStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { convertToSentenceCase } from '../../utils/helper';

const styles = (theme) => ({
  textFieldLabel: {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    marginBottom: '4px',
    whiteSpace: 'nowrap',
  },
  required: {
    color: 'red',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '2px',
  },
  input: {
    '& .MuiInputBase-input': {
      color: '#344054',
      height: '40px',
      opacity: 0.87,
      padding: '0px 12px',
      fontSize: '16px',
      boxSizing: 'border-box',
      fontFamily: 'Inter',
      fontWeight: 500,
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': { 
      padding: '0px',
    },
    '& .MuiButtonBase-root.MuiIconButton-root': { 
      marginRight: '0px',
    },
    
    '& .MuiInputLabel-root': {
      fontFamily: 'Inter',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#A0A4A6',
      },
      '&:hover fieldset': {
        borderColor: '#036FCB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#036FCB',
      },
    },
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(5),
    height: theme.spacing(5),
    lineHeight: '40px',
    padding: '6px 24px',
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#036FCB',
      borderRadius: theme.spacing(5),
    },
    marginRight: '40px',
  },
  continueButton: {
    color: '#FFFFFF',
    height: '40px',
    padding: '6px 24px',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 500,
    borderRadius: '40px',
    letterSpacing: '0',
    backgroundColor: '#036FCB',
  },
});

const AddActivityForm = ({ classes, onClose, carePlanTitle, onAddActivity }) => {
  const [activityName, setActivityName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSubmit = () => {
    const newActivity = {
      id: uuidv4(), // Generate a unique ID
      activityName: convertToSentenceCase(activityName),
      description: convertToSentenceCase(description),
      status: 'Active',
      assignedTo: 'Self',
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      carePlanTitle, // Store the care plan title
    };
  
    onAddActivity(newActivity);
    onClose();
  };

  return (
    <div style={{ padding: '0px 20px' }}>
      <div>
        <p className={classes.textFieldLabel}>
          Activity name <span className={classes.required}>*</span>
        </p>
        <TextField
          value={activityName}
          onChange={(e) => setActivityName(e.target.value)}
          fullWidth
          required
          placeholder='Enter activity name'
          className={classes.input}
        />
      </div>
      <div>
        <p className={classes.textFieldLabel}>
          Description
        </p>
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          placeholder='Enter description'
          className={classes.input}
          multiline
          rows={3}
          inputProps={{ maxLength: 500, style: { padding: '12px 12px' } }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }}>
          <p className={classes.textFieldLabel}>
            Start date <span className={classes.required}>*</span>
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  className={classes.input}
                />
              )}
              minDate={dayjs()}
              inputFormat="YYYY/MM/DD"
              PopperProps={{
                disablePortal: true,
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom-start'],
                    },
                  },
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4], // Adjust spacing
                    },
                  },
                ],
                sx: {
                  '& .MuiCalendarPicker-root': {
                    width: '280px !important',
                    marginLeft: '50px !important',
                    marginRight : '0px',
                  },
                  '& .MuiPickersCalendarHeader-root': {
                    padding: '0px 0px',
                  },
                },
              }}
            />
          </LocalizationProvider>
        </div>
        <div style={{ width: '48%' }}>
          <p className={classes.textFieldLabel}>
            End date <span className={classes.required}>*</span>
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  className={classes.input}
                />
              )}
              minDate={dayjs()}
              inputFormat="YYYY/MM/DD"
              PopperProps={{
                disablePortal: true,
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom-start'],
                    },
                  },
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4], // Adjust spacing
                    },
                  },
                ],
                sx: {
                  '& .MuiCalendarPicker-root': {
                    width: '280px !important',
                    marginLeft: '45px !important',
                    marginRight: '0px !important',
                  },
                  '& .MuiPickersCalendarHeader-root': {
                    padding: '0px 0px',
                  },
                },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
        <Button onClick={onClose} className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.continueButton}
          onClick={handleSubmit}
          disabled={!activityName || !startDate || !endDate}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(AddActivityForm);