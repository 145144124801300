import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EventAvailable from '@mui/icons-material/EventAvailable';
import RocketChatWidget from '../../../Chatbot/RocketChatWidget';
import colors from '../../../../utils/colors';
import { withStyles } from '@mui/styles';

const styles = {
  heading: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    color: colors.WHITE,
  },
  rows: { fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, color: colors.BLACK },
};

const TABLE_HEADING = ['Name', 'Role', 'Type', 'Action'];

const CareTeamTableGrid = ({ data, classes }) => {
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link); 
  };

  const handleMessageIconClick = (member_name) => {
    window.dispatchEvent(
      new CustomEvent('openChat', { detail: { member_name } })
    );
  };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 640 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {TABLE_HEADING.map((heading) => (
                <TableCell
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: colors.BLACK,
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" className={classes.rows}>
                  {row.member_name}
                </TableCell>
                <TableCell align="left" className={classes.rows}>
                  {row.specialty}
                </TableCell>
                <TableCell align="left" className={classes.rows}>
                  {row.partnerName}
                </TableCell>
                <TableCell align="left">
                  <CallIcon color="success" sx={{ marginRight: '16px' }} />
                  <MessageIcon
                    color="primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleMessageIconClick(row.member_name)}                  />
                  <EventAvailable
                    color="secondary"
                    sx={{ marginLeft: '16px', cursor: 'pointer' }}
                    onClick={() => handleNavigate('/records/new/appointments')}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default withStyles(styles, { withTheme: true })(CareTeamTableGrid);
