import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CarePlanActivities from './CarePlanActivities';
// import LinearProgress from '@mui/material/LinearProgress';
import MarkRestrictedCard from '../../components/molecules/SelfReporting/MarkRestrictedCard';
import GenericServices from '../../features/dynamicRecords/Services/GenericServices';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddActivityForm from './AddActivityForm';
import { v4 as uuidv4 } from 'uuid';
import { convertToSentenceCase } from '../../utils/helper';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontFamily: 'Inter',
  },
  leftColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '50%',
    padding: '10px 5px 10px 20px',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '10px 20px 10px 5px',
  },
  column: {
    paddingRight: '10px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    maxWidth: 'calc(100% - 20px)',
  },
  columnTitle: {
    color: '#828282',
    width: '100%',
    height: '20px',
    margin: '4px 0',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  columnName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(31, 31, 31)',
    margin: '4px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  categoryName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(31, 31, 31)',
    margin: '4px 0px',
    overflowWrap: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  specialText: {
    fontSize: '11px',
    background: 'rgb(228, 228, 228)',
    border: '1px solid rgb(192, 192, 192)',
    borderRadius: '24px',
    textAlign: 'center',
    padding: '0px 8px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    maxWidth: 'calc(100% - 40px)',
    margin: '4px 0px',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '4px',
  },
  goalsTitle: {
    fontSize: '16px',
    fontWeight: 600,
  },
  goalCard: {
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    boxShadow: '0px 0.4px 1.2px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
    background: '#fff',
    cursor: 'pointer',
    maxWidth: '100%',
    marginRight: '24px',
  },
  goalName: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: '5px',
  },
  progressBar: {
    marginTop: '10px',
    height: '8px',
    borderRadius: '12px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    background: '#f0f0f0', 
    transition: 'width 0.3s ease',
  },
    descriptionText: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    maxWidth: '100%',
  },
  list: {
    margin: '0px',
    padding: '0px',
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
  listItem: {
    lineHeight: '24px',
    maxWidth: '100%',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  addDataBtn: {
    backgroundColor: '#FFFFFF',
    borderRadius: theme.spacing(5),
    height: theme.spacing(5),
    lineHeight: '40px',
    padding: '6px 0px',
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    flexShrink: 0,
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#036FCB',
      borderRadius: theme.spacing(5),
    },
    marginRight: 12,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  drawerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerPaper: {
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    right: '0',
    maxWidth: '620px',
    width: '100%',
    padding: theme.spacing(2),
    height: '-webkit-fill-available',
  },
  // addActivityDrawer: {
  //   borderRadius: '24px',
  //   marginTop: '24px',
  //   marginBottom: '24px',
  //   margin: '0 auto',
  //   left: '50%', 
  //   transform: 'translateX(-50%)',
  //   maxWidth: '620px',
  //   width: '100%',
  //   padding: theme.spacing(2),
  //   position: 'fixed',
  //   height: '-webkit-fill-available',
  // },
  heading: {
    fontSize: '24px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '350px',
  },
});

const mockGoals = [
  {
    id: 1,
    name: 'Maintain consistent medication routine',
    activities: [
      {
        activityName: 'Daily morning medication intake',
        status: 'Active',
        assignedTo: 'Nursing team',
        startDate: '2025-01-01',
        endDate: '2025-03-31',
      },
      {
        activityName: 'Evening medication adherence review',
        status: 'Completed',
        assignedTo: 'Pharmacy team',
        startDate: '2024-10-01',
        endDate: '2024-12-31',
      },
      {
        activityName: 'Weekly medication supply check',
        status: 'Pending',
        assignedTo: 'Pharmacy team',
        startDate: '2025-01-07',
        endDate: '2025-03-31',
      },
    ],
  },
  {
    id: 2,
    name: 'Daily exercise routine',
    activities: [
      {
        activityName: 'Daily morning walk monitoring',
        status: 'Active',
        assignedTo: 'Physiotherapy team',
        startDate: '2025-01-01',
        endDate: '2025-03-31',
      },
      {
        activityName: 'Weekly progress analysis for stretching',
        status: 'Completed',
        assignedTo: 'Physiotherapy team',
        startDate: '2024-10-01',
        endDate: '2024-12-31',
      },
      {
        activityName: 'Bi-weekly fitness plan adjustment',
        status: 'Pending',
        assignedTo: 'Fitness coaching team',
        startDate: '2025-01-15',
        endDate: '2025-03-31',
      },
    ],
  },
];

const CarePlanGoals = ({ classes, data, customBodyComponent, markAsRestricted, restrictedDescription, currentObj, clinicalRecord }) => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddActivityDrawerOpen, setIsAddActivityDrawerOpen] = useState(false);
  const [restricted, setRestricted] = useState(markAsRestricted);
  const [selfReportingActivities, setSelfReportingActivities] = useState([]);
  const title = customBodyComponent?.props?.responseObj?.title || '';

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleAddActivityClick = () => {
    setIsAddActivityDrawerOpen(true);
  };

  const handleCloseAddActivityDrawer = () => {
    setIsAddActivityDrawerOpen(false);
  };

  const handleAddActivity = (newActivity) => {
    const updatedActivities = [...selfReportingActivities, newActivity];
    setSelfReportingActivities(updatedActivities);
    localStorage.setItem('selfReportingActivities', JSON.stringify(updatedActivities));
  };

  useEffect(() => {
    const storedActivities = JSON.parse(localStorage.getItem('selfReportingActivities')) || [];
    const activitiesWithIds = storedActivities.map(activity => ({
      ...activity,
      id: activity.id || uuidv4(),
    }));
    setSelfReportingActivities(activitiesWithIds);
    localStorage.setItem('selfReportingActivities', JSON.stringify(activitiesWithIds));
  }, []);

  // const calculateProgress = (activities) => {
  //   const total = activities.length;
  //   const completed = activities.filter((a) => a.status === 'Completed').length;
  //   const active = activities.filter((a) => a.status === 'Active').length;
  //   return {
  //     completed: (completed / total) * 100,
  //     active: (active / total) * 100,
  //   };
  // };

  const calculateProgress = (goal) => {
    let completed = 50; // Default value
    let active = 30; // Default value

    if (title === "Blood Pressure Monitoring Plan") {
      completed = 40;
      active = 40;
    } else if (title === "Medication Adherence Care Plan") {
      completed = 60;
      active = 20;
    }

    return {
      completed,
      active,
    };
  };

  const markAsRestrictedCall = async (currentState) => {
    try {
      await GenericServices.updateMarkAsRestrictedRecord({
        resourceId: currentObj?.id,
        markAsRestricted: currentState === true ? 'restricted' : 'unrestricted',
        resourceType: clinicalRecord,
      });
      setRestricted(currentState);
    } catch (err) {
      console.log(err);
    }
  };

  // Extract values from data props
  const dataSource = convertToSentenceCase(data?.find(item => item?.column_name === 'Data source')?.value || 'N/A');
  const recordedOn = data?.find(item => item?.column_name === 'Recorded on')?.value || '-';
  const recordedBy = data?.find(item => item?.column_name === 'Recorded by')?.value || 'N/A';
  const facility = convertToSentenceCase(data?.find(item => item?.column_name === 'Facility')?.value || 'N/A');
  const category = convertToSentenceCase(data?.find(item => item?.column_name === 'Category')?.value || 'N/A');
  const intent = convertToSentenceCase(data?.find(item => item?.column_name === 'Intent')?.value || 'N/A');
  const description = convertToSentenceCase(data?.find(item => item?.column_name === 'Description')?.value || 'N/A');
  const notes = data?.find(item => item?.column_name === 'Note')?.value?.map(note => convertToSentenceCase(note)) || [];

  // Extract goals, activities, and addresses from customBodyComponent props
  const goals = customBodyComponent?.props?.responseObj?.goal?.map(singleGoal => ({
    ...singleGoal,
    display: convertToSentenceCase(singleGoal.display)
  })) || [];
  const backendActivities = customBodyComponent?.props?.responseObj?.activity || [];
  const addresses = customBodyComponent?.props?.responseObj?.addresses?.map(address => ({
    ...address,
    display: convertToSentenceCase(address.display)
  })) || [];

  // Create dynamic activities array with default values
  const activities = backendActivities?.map(activity => ({
    activityName: convertToSentenceCase(activity.detail.code.text),
    status: 'Active', // Default value
    assignedTo: 'Nursing team', // Default value
    startDate: '2025-01-01', // Default value
    endDate: '2025-03-31', // Default value
  }));

  return (
    <div className={classes.container}>
      {/* Left Column: View Details */}
      <div className={classes.leftColumn}>
        <div className={`${classes.column} ${classes.columnFirst}`} style={{ gridColumn: '1 / 2' }}>
          <p className={classes.columnTitle}>Facility</p>
          <p className={classes.columnName}>{facility}</p>
        </div>
        <div className={`${classes.column} ${classes.columnSecond}`} style={{ gridColumn: '2 / 3' }}>
          <p className={classes.columnTitle}>Recorded on</p>
          <p className={classes.columnName}>{recordedOn}</p>
        </div>
        <div className={`${classes.column} ${classes.columnThird}`} style={{ gridColumn: '3 / 4' }}>
          <p className={classes.columnTitle}>Intent</p>
          <p className={classes.columnName}>{intent}</p>
        </div>
        <div className={`${classes.column} ${classes.columnFirst}`} style={{ gridColumn: '1 / 2' }}>
          <p className={classes.columnTitle}>Recorded by</p>
          <p className={classes.columnName}>{recordedBy}</p>
        </div>
        <div className={`${classes.column} ${classes.columnSecond}`} style={{ gridColumn: '2 / 4' }}>
          <p className={classes.columnTitle}>Category</p>
          <p className={classes.categoryName}>{category}</p>
        </div>
  
        <div className={classes.column} style={{ gridColumn: '1 / 4' }}>
          <p className={classes.columnTitle}>Description</p>
          <p className={`${classes.columnName} ${classes.descriptionText}`}>{description}</p>
        </div>
        <div className={classes.column} style={{ gridColumn: '1 / 4', marginBottom: '0px'}}>
          <p className={classes.columnTitle}>Note</p>
          <div className={classes.columnName}>
            <ul className={classes.list}>
              {notes.map((note, index) => (
                <li key={index} className={classes.listItem}>{note}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classes.column} style={{ gridColumn: '1 / 4', marginBottom: '0px'}}>
          <p className={classes.columnTitle}>Problems</p>
          <div className={classes.columnName}>
            <ul className={classes.list}>
              {addresses.map((address, idx) => (
                <li key={idx} className={classes.listItem}>{address.display}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  
      {/* Right Column: Goals and Cards */}
      <div className={classes.rightColumn}>
        <div className={classes.header}>
          <div className={classes.goalsTitle}>Goals</div>
        </div>
        {goals.map((goal, idx) => {
          const progress = calculateProgress(activities);
          const completedWidth = progress.completed; // Completed percentage
          const activeWidth = progress.active; // Active percentage
  
          return (
            <div key={idx} className={classes.goalCard} onClick={() => handleGoalClick(goal)}>
              <div className={classes.goalName}>{goal.display}</div>
              <div className={classes.progressBarContainer} style={{ position: 'relative', width: '100%', height: '8px', borderRadius: '12px', backgroundColor: '#f0f0f0' }}>
                {/* Completed Portion */}
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: `${completedWidth}%`,
                    backgroundColor: '#007d32', // Completed color
                    borderRadius: '12px 0 0 12px', // Rounded corners on left
                  }}
                />
                {/* Active Portion */}
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: `${activeWidth}%`,
                    left: `${completedWidth}%`, // Start the active portion after the completed one
                    backgroundColor: '#036fcb', // Active color
                    borderRadius: '0 12px 12px 0', // Rounded corners on right
                  }}
                />
              </div>
              <div>
                <span style={{ color: '#007d32' }}>{progress.completed.toFixed(0)}% Completed</span>
                <span style={{ color: '#036fcb', marginLeft: '10px' }}>{progress.active.toFixed(0)}% Active</span>
              </div>
            </div>
          );
        })}
  
        <div>
          <MarkRestrictedCard
            description={restrictedDescription}
            enabled={restricted}
            onChange={(val) => markAsRestrictedCall(val)}
          />
        </div>
        <div className={`${classes.column} ${classes.columnFirst}`} style={{ marginTop: '10px' }}>
            <p className={classes.columnTitle}>Data source</p>
            <p className={classes.specialText}>{dataSource}</p>
          </div>
      </div>
  
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" className={classes.heading}>
            {selectedGoal ? selectedGoal.display : ''}
          </Typography>
          <div className={classes.drawerActions}>
            <Button className={classes.addDataBtn} onClick={handleAddActivityClick}>
              Add activity
            </Button>
            <IconButton onClick={handleCloseDrawer} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <CarePlanActivities
          goalName={selectedGoal ? selectedGoal.display : ''}
          activities={activities}
          title={title}
          selfReportingActivities={selfReportingActivities}
          setSelfReportingActivities={setSelfReportingActivities}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={isAddActivityDrawerOpen}
        onClose={handleCloseAddActivityDrawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" className={classes.heading}>
            Add activity
          </Typography>
          <IconButton onClick={handleCloseAddActivityDrawer} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <AddActivityForm onClose={handleCloseAddActivityDrawer} carePlanTitle={title} onAddActivity={handleAddActivity} />
      </Drawer>
    </div>
  );
};

export default withStyles(styles)(CarePlanGoals);