import React from 'react';
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

const HighchartComponent = ({ chartData }) => {
  const options = {
    chart: {
      height: 300,
      backgroundColor: "#ffffff",
      borderRadius: 8,
      borderWidth: 0,
      style: {
        fontFamily: "Inter, sans-serif",
      },
      type: chartData.type, 
    },
    title: {
      text: chartData.title,
      style: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#333333",
      },
      y: 24,
    },
    series: [
      {
        name: "Amount",
        data: chartData.data.map((item) => ({
          name: item.name,
          y: item.y,
          amount: item.amount,
        })),
        colors: ["#0091FF", "#FF6B00", "#a7bdd1", "#ff6f61"],
        states: {
          hover: {
            brightness: -0.1,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        showInLegend: true,
        cursor: "pointer",
        center: ["50%", "50%"],
        dataLabels: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          format: "${point.amount}",
        },
      },
    },
    xAxis: {
      categories: chartData.type === "line" ? chartData.data.map((item) => item.name) : undefined,
      title: {
        text: chartData.type === "line" ? "Time Period" : undefined,
      },
    },
    yAxis: {
      title: {
        text: chartData.type === "line" ? "Amount ($)" : undefined,
      },
    },
    tooltip: {
      backgroundColor: "#ffffff",
      borderWidth: 0,
      borderRadius: 8,
      shadow: true,
      style: {
        fontSize: "14px",
        color: "#333333",
      },
      pointFormat: chartData.type === "pie" ? "${point.amount} ({point.percentage:.1f}%)" : "Amount: ${point.amount}",
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#333333",
      },
    },
    credits: {
      enabled: false,
    },
  }

  if (chartData.type === "pie") {
    options.subtitle = {
      align: "center",
      verticalAlign: "middle",
      y: 10,
      style: {
        fontSize: "24px",
        fontWeight: "600",
        color: "#333333",
      },
    }
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default HighchartComponent


