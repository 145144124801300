import React from 'react';
import { Card, CardContent, CardHeader, Grid, IconButton, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import withRouter from '../../components/withRoute';
import HighchartComponent from '../HighchartComponent';

const financialData = [
  { date: "2024-01-10", provider: "Provider A", serviceType: "Consultation" },
  { date: "2024-02-15", provider: "Provider B", serviceType: "Surgery" },
  { date: "2024-03-20", provider: "Provider C", serviceType: "Follow-up" },
  { date: "2025-01-3", provider: "Provider D", serviceType: "consultation" },
];

const chartData = [
{
        title: "Deductible",
        data: [
          { name: "Used", y: 40, amount: 2000 },
          { name: "Remaining", y: 60, amount: 3000 },
        ],
        type : 'pie',
      },
      {
        title: "Out-of-Pocket Max",
        data: [
          { name: "Spent", y: 70, amount: 3500 },
          { name: "Remaining", y: 30, amount: 1500 },
        ],
        type : 'pie',
      },
      {
        title: "Total Cost",
        data: [
          { name: "Covered", y: 80, amount: 8000 },
          { name: "Not Covered", y: 20, amount: 2000 },
        ],
        type : 'pie',
      },
];

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "auto",
};

const contentStyle = {
  backgroundColor: "#FFFFFF",
  width: "95%",
  height: "95%",
  padding: "24px",
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  position: "relative",
  overflowY: "auto",
};

const cardStyle = {
  transition: "transform 0.3s, box-shadow 0.3s",
  '&:hover': {
    transform: "scale(1.02)",
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
  },
  backgroundColor: "#F9FAFB",
  borderRadius: "12px",
  marginTop: "16px",
};

const closeIconStyle = {
  position: "absolute",
  top: "16px",
  right: "16px",
  zIndex: 1001,
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
};

const CostSummary = ({ open, close }) => (
  open && (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <IconButton style={closeIconStyle} onClick={close}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" align="center" gutterBottom>
          Cost Summary
        </Typography>
        <Grid container spacing={3}>
        {chartData.map((chart, index) => (
            <Grid item xs={12} md={6} lg={6} key={index}>
              <Card sx={cardStyle}>
                <CardContent>
                  <HighchartComponent chartData={chart} />
                </CardContent>
              </Card>
            </Grid>
          ))}
          <Grid item xs={3} lg={6}>
            <Card sx={cardStyle}>
              <CardContent>
                <TableContainer component={Paper} sx={{ borderRadius: '12px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: '#F1F5F9' }}>
                      <TableRow>
                        <TableCell align="center"><strong>Date</strong></TableCell>
                        <TableCell align="center"><strong>Provider</strong></TableCell>
                        <TableCell align="center"><strong>Service type</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {financialData.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{item.date}</TableCell>
                          <TableCell align="center">{item.provider}</TableCell>
                          <TableCell align="center">{item.serviceType}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  )
);

export default withRouter(CostSummary);