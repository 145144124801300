import React from 'react';
import { Card, CardContent, CardHeader, Grid, IconButton, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import withRouter from '../../components/withRoute';
import HighchartComponent from '../HighchartComponent';
import Button from '../../components/atoms/Button';

const financialData = [
  { name: "Total amount billed", amount: 25000 },
  { name: "Total amount paid", amount: 18000 },
  { name: "Pending amount", amount: 7000 },
  { name: "Out of pocket costs", amount: 2500 },
  { name: "Deductible remaining", amount: 1000 },
  { name: "Maximum out of pocket", amount: 5000 },
  { name: "In network spend", amount: 15000 },
];
  
const chartData = [
  {
    title: "Claim Summary",
    data: [
      { name: "Approved",y:40, amount: 40 },
      { name: "Rejected",y:35, amount: 35 },
      { name: "Pending",y:25, amount: 25 },
    ],
    type: "pie",
  },
  {
    title: "Claim Type Breakdown",
    data: [
      { name: "Dental",y:40, amount: 40 },
      { name: "Pharmacy",y:20, amount: 20 },
      { name: "Vision",y:30, amount: 30 },
      { name: "Other Medical",y:10, amount: 10 },
    ],
    type: "pie",
  },
  {
    title: "Time-Based Insights",
    data: [
      { name: "Last 30 Days",y:200, amount: 200 },
      { name: "Last 6 Months",y:600, amount: 540 },
      { name: "Last 1 Year",y:900, amount: 830 },
    ],
    type: "line"
  },
];

const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  };
  
  const contentStyle = {
    backgroundColor: "#FFFFFF",
    width: "95%",
    height: "95%",
    padding: "24px",
    borderRadius: "12px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    position: "relative",
    overflowY: "auto",
    marginTop: '200px'
  };
  
  const cardStyle = {
    transition: "transform 0.3s, box-shadow 0.3s",
    '&:hover': {
      transform: "scale(1.02)",
      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
    },
    backgroundColor: "#F9FAFB",
    borderRadius: "12px",
    marginTop: "16px",
  };
  
  const closeIconStyle = {
    position: "absolute",
    top: "16px",
    right: "16px",
    zIndex: 1001,
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  };
  const TotalCostSummary = ({ open, close }) => (
    open && (
      <div style={modalStyle}>
        <div style={contentStyle}>
          <IconButton style={closeIconStyle} onClick={close}>
            <CloseIcon />
          </IconButton>
  
          <Typography variant="h5" align="center"  gutterBottom>
            Total Cost summary Overview
          </Typography>
  
          <Grid container spacing={3}>
            {chartData.map((chart, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <HighchartComponent chartData={chart} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Card sx={cardStyle}>
                <div style={{display: 'flex',flex : 'row',justifyContent:'space-between'}}>
              <CardHeader title={<Typography variant="h6">Financial Overview</Typography>} />
            <Button  color="secondary"
              style = {{marginTop : "20px",marginRight : "30px"}}
              variant="contained"
              title={'Download'} >Download</Button>
              </div>
              <CardContent>
                <TableContainer component={Paper} sx={{ borderRadius: '12px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: '#F1F5F9' }}>
                      <TableRow>
                        {financialData.map((item, index) => (
                          <TableCell key={index} align="center"><strong>{item.name}</strong></TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {financialData.map((item, index) => (
                          <TableCell key={index} align="center">${item.amount.toLocaleString()}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </div>
      </div>
    )
  );

export default withRouter(TotalCostSummary);
